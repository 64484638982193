<template>
    <div>
        <slot name="title">
            <h3>UPDATE PAYMENT METHOD</h3>
        </slot>
        <div>
            <!-- credit card -->
            <div class="form-fields">
                <div class="row field-section">
                    <div class="col">
                        <div class="d-inline-flex w-100 justify-content-between">
                            <h4>CARD NUMBER</h4>
                            <div>
                                <span class="lock-text d-inline-block mb-0 mt-1">Secure Form</span>
                                <img  class="img-fluid lock mb-2" src="../assets/images/locked-padlock-dark.svg">
                            </div>
                        </div>
                        <div id="card-number" class="card-holder element-holder field-outline mb-1"/>
                        <div class="text-right">
                            <img class="ccard" src="../assets/images/payment_cards/031-visa.svg">
                            <img class="ccard" src="../assets/images/payment_cards/031-american-express.svg">
                            <img class="ccard" src="../assets/images/payment_cards/031-discover.svg">
                            <img class="ccard" src="../assets/images/payment_cards/031-master-card.svg">
                        </div>
                    </div>
                </div>


                <slot name="extra-elements">

                </slot>

                <div class="row field-section">
                    <div class="col">
                        <div class="d-block d-md-inline-block">
                            <h4>EXPIRY DATE</h4>
                            <div id="card-expiry" class="card-expiry element-holder field-outline"/>
                            <span>&nbsp;</span>
                        </div>
                        <div class="d-block d-md-inline-block ml-md-5 mt-4 mt-md-0">
                            <h4 style="margin-bottom: 7px">CVC <img data-toggle="tooltip" data-placement="right" title="A 3 or 4 digit number on the back of your credit card used to verify ownership." src="../assets/images/information.svg" class="ml-1 mb-1" style="max-width: 1.1rem;"></h4>
                            <div id="card-cvc" class="card-cvc element-holder field-outline"/>
                            <span class="lock-text">3 digits on back</span>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 text-right" hidden>
                                <img src="../assets/images/verisign.png" style="max-width: 150px;"/>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-md-6 d-inline-block my-auto">
                        <slot name="server-errors">
                        </slot>
                    </div>

                    <div class="col-sm-12 col-md-6 text-right">
                        <slot name="trust-signals">
                            <img src="../assets/images/verisign.png" style="max-width: 150px;"/>
                        </slot>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {getStripeToken} from '../assets/js/globals'

    export default {
        data() {
            return {
                stripe: null,
                card: null,
                expiry: null,
                cvc: null,
                card_id: 'card-number',
                card_ready: false,
                expiry_id: 'card-expiry',
                expiry_ready: false,
                cvc_id: 'card-cvc',
                cvc_ready: false,
                in_error: false,
            }
        },
        methods: {
            clearForm() {
                this.card.clear();
                this.cvc.clear();
                this.expiry.clear()
            },
            setUpStripe() {
                let style = {
                    base: {
                        fontFamily: '"Heebo", sans-serif',
                        fontWeight: 500,
                        fontSize: '17px',
                        color: '#333',
                        fontStyle: 'bold',
                        fontSmoothing: 'antialiased',
                        '::placeholder': {
                            color: '#CFD7E0',
                        },
                    }
                };

                this.stripe = Stripe(getStripeToken());
                let elements = this.stripe.elements();
                this.card = elements.create('cardNumber', {style: style});
                this.card.mount("#" + this.card_id);
                this.card.on('ready', () => {this.card_ready = true; this.sendReadyEvent();})


                this.expiry = elements.create('cardExpiry', {style: style});
                this.expiry.mount('#' + this.expiry_id)
                this.expiry.on('ready', () => {this.expiry_ready = true; this.sendReadyEvent();})

                this.cvc = elements.create('cardCvc', {style: style, placeholder: '000'});
                this.cvc.mount('#' + this.cvc_id);
                this.cvc.on('ready', () => {this.cvc_ready = true; this.sendReadyEvent();})
            },
            sendReadyEvent() {
                if (this.cvc_ready && this.expiry_ready && this.card_ready) {
                    this.$emit("stripeComponentsReady");
                }
            },
            sendStripeTokenEvent(token, error) {
                this.$emit("stripeTokenEvent", {'token' : token, 'error' : error});
            },
            retrieveToken() {
                this.server_errors = "";
                this.stripe.createToken(this.card).then((result) => {
                    if (result.error) {
                        // Inform the user if there was an error
                        this.sendStripeTokenEvent(null, result.error.message)
                    } else {
                        this.sendStripeTokenEvent(result.token, null)
                    }
                });
            }
        },
        mounted() {
            this.setUpStripe();
        }
    }
</script>

<style scoped>




    .lock {
        max-height: 1.1rem;
    }

    .lock-text {
        font-size: 1.1rem;
        font-weight: 400;
        color: #ccc;
    }


    .payment-card .form-fields .field-outline {
        font-size: 1rem;
    }

    .card-cvc {
        background-image: url('../assets/images/lock-light.svg') !important;
        background-size: 1.6rem;
        background-repeat: no-repeat;
        width: 100px;
        padding-right: 2rem;
        -webkit-appearance: none;
        background-position-x: 95%;
        background-position-y: 50%;
    }

    .StripeElement--focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: none;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
    }

    .ccard {
        margin-right: 2px;
        max-width: 2rem
    }

    .card-holder {
        background-image: url('../assets/images/credit-card.svg') !important;
        background-size: 2.2rem;
        background-repeat: no-repeat;
        min-width: 230px;
        padding-right: 3rem;
        background-position-x: 99%;
        background-position-y: 50%;
    }



</style>