<template>
    <component-form :error_message=null :success_message=null>
        <div slot="form">

            <div class="text-center">
                <img  class="flag img-fluid d-inline-flex" :src="'/src/assets/images/round_flags/pakistan.svg'">
            </div>


            <component-card class="wide-space extra-padding">
                <div slot="content">

                    <h3>Hi {{ name? name + ',': 'there!'}}</h3>

                    <p>We noticed that you’re signing up from Pakistan - amazing, welcome to the family! Your payment has been processed, soon you’ll be good to go.</p>

                    <p>Before you continue to the next page, we’d like to ask what is the name of the bank that you used to pay for this service?</p>

                    <Component-Field :error_message="this.computed_error_text" class="mt-5 mb-5">
                        <div slot="field">
                            <select v-model="selected_bank" class="form-control placeholder field-outline">
                                <option class="dropdown-style" value="">Select your bank</option>
                                <template v-for="bank in banks">
                                    <option  class="dropdown-style" :value="bank">{{ bank }}</option>
                                </template>
                            </select>
                        </div>
                    </Component-Field>

                    <Component-Accordian :items="this.accordian_items"></Component-Accordian>

                    <div slot="button">
                        <submission-button @submissionWasClicked="submitBankData()"
                                           :is_submitting="this.is_submitting"
                                           buttonText="Continue to account details"
                                           classes="btn-sm btn-primary w-100 mt-5"
                                           spinner_classes=""
                                           :section="this.section"></submission-button>
                    </div>






                </div>
            </component-card>

        </div>
    </component-form>

</template>

<script>
    import ComponentCard from '../components/Card.vue'
    import ComponentInlineField from '../forms/InlineField.vue'
    import SubmissionButton from '../forms/SubmissionButton.vue'
    import ComponentForm from '../forms/Form.vue'
    import ComponentAccordian from '../components/Accordian.vue'
    import ComponentField from '../components/Field.vue'
    import {banks} from '../assets/js/data'
    import {urlParam} from '../assets/js/utilities'
    import {getEndpoint, getPageLocation, Fingerprint} from '../assets/js/globals'

    export default {
        data() {
          return {
              token: '',
              error_message: 'test',
              success_message: '',
              is_submitting: false,
              section: 'Banks',
              name: '',
              selected_bank: '',
              error_text: "* Please select your bank, or 'Other' if your bank is not listed.",
              show_error_text: false,
              banks: banks,
              accordian_items: [
                  {header: 'Why do you need the name of my bank?\n', text: 'Sometimes customers can have issues paying online with their banks, especially from Pakistan. We ask for the name of your bank as it helps to prevent any issues with your account and renewing the subscription in the future.'},
                  {header: 'Do all banks have this same issue?\n', text: 'Each bank is different. Most won’t have a problem when you pay the first time as you did this manually. The issue can come when our system tries to bill you for your second month of Spotify, some banks may choose to block this.'},
                  {header: 'What can I do to prevent a future payment failure?', text: 'The first step is to call your bank. Ask them what their policy is for online payments, and check if automatic payments are activated for Music for World. Alternatively, some prepaid cards like UBL Wiz and MCB Lite are great for online payments. \n' +
                  '\n' +
                  'In the event that your payment fails we’ll simply notify you with the steps you can take for your specific bank!  '}
              ]
          }
        },
        computed: {
          computed_error_text() {
              if (this.selected_bank === '' && this.show_error_text === true) {
                  return this.error_text;
              }
              return '';
          }
        },
        components: {
            ComponentCard,
            ComponentInlineField,
            SubmissionButton,
            ComponentForm,
            ComponentAccordian,
            ComponentField
        },
        methods: {
            submitBankData() {

                this.show_error_text = true;

                if (this.selected_bank === '') {
                    return
                }

                this.is_submitting = true;

                $.ajax({
                    context: this,
                    url: getEndpoint('banks'),
                    type: "POST",
                    contentType: "application/json",
                    data: JSON.stringify({
                        'bank': this.selected_bank
                    }),
                    headers: {
                        'Authorization': 'Token ' + this.token
                    },
                }).
                always((data) => {
                    // we redirect to the thank you page regardless of success or failure
                    this.is_submitting = false;
                    window.location.href = getPageLocation('thankyou');
                });
            }

        },
        created() {
            try {
                let param = urlParam(null);
                console.log(param);
                let fingerprint = new Fingerprint();
                let success = fingerprint.loadFromUrlParam(param);
                console.log(fingerprint);
                if (fingerprint.isExpired() || !success || !fingerprint.isValidParamFingerprint()) {
                    let fingerprint = new Fingerprint();
                    fingerprint.saveFingerprint();
                    console.log("Server Error 500")
                    return;
                }

                this.token = fingerprint.auth_token;
                this.name = fingerprint.name;
                fingerprint.saveFingerprint();

            } catch(err) {
                // TODO: Redirect to error page
                console.log(err)
                let fingerprint = new Fingerprint();
                fingerprint.saveFingerprint();
                console.log("Server Error 500")
            }
        },
        mounted() {

        },
    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors';

    .flag {
        border: 1px solid $light-outline;
        border-radius: 100px;
        width: 4rem;
        height: 4rem;
        margin-top: -2rem;
        margin-left: -1rem;
        position:absolute;
        z-index: 1;
    }

</style>
