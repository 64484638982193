<template>
    <nav class="navbar" :class="[navbarClasses]">

        <div class="row w-100">
        <div class="col">
            <a @click.prevent="toHome()" href="#">
                <img  class="img-fluid" style="max-width: 260px; min-width:100px;"  :src="logoImage">
            </a>
        </div>

        <div class="col-1 col-lg-8 text-right">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarToggler" style="min-width: 100px; margin-left: -50px;">
            <ul class="navbar-nav ml-md-auto">
                <li class="nav-item">
                    <a class="nav-link" href="#" @click.prevent="toAboutUs()">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#" @click.prevent="toContactUs()">Contact us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#" @click="toHiring()">We're Hiring!</a>
                </li>
            </ul>
        </div>
        </div>
        </div>
    </nav>
</template>

<script>
    import {getPageLocation} from './assets/js/globals'
    export default {
        data() {
            return {
                sectionName : "Navbar",
            }
        },
        props : {
            "navbarClasses" : {default: "navbar-dark pb-5"},
            "logoImage" : {default: "./src/assets/images/logos/logo-white.svg"},
            "isLanding" : {default: true},

        },
        methods: {
            toFeatures() {
                if (this.isLanding == true) {
                    location.hash = "#" + 'features';
                    let event = new this.$clickEvent("Features (Link)", '#features', this.sectionName);
                    event.sendEvent();
                }
                else {
                    this.$goToPage("Features (Link)", getPageLocation('indexFeatures'), this.sectionName);
                }

            },
            toAboutUs() {
                this.$goToPage("About Us (Link)", getPageLocation('about'), this.sectionName);
            },
            toHome() {
                this.$goToPage("Logo (Image)", getPageLocation('index'), this.sectionName);
            },
            toHowItWorks() {
                if (this.isLanding == true) {
                    location.hash = "#" + 'how-it-works';
                    let event = new this.$clickEvent("How It Works (Link)", '#how-it-works', this.sectionName);
                    event.sendEvent();
                }
                else {
                    this.$goToPage("How It Works (Link)", getPageLocation('indexHowItWorks'), this.sectionName);
                }
            },
            toContactUs() {
                this.$goToPage("Contact Us (Link)", getPageLocation('contact'), this.sectionName);
            },
            toHiring() {
                this.$goToPage("We're Hiring (Link)", getPageLocation('hiring'), this.sectionName);
            },
        }


    }
</script>

<style scoped lang="scss">
    @import 'assets/css/colors.scss';

    .navbar-mobile-special {
        background: $topbar;
    }

    .navbar {
        padding: 0;
    }

    .navbar-dark .nav-item a.nav-link {
        color: white;
    }

    .navbar-dark .nav-item a.nav-link:hover {
        color: rgba(255,255,255,.5);
    }

    // this is a white icon with 50% opacity
    .navbar-dark .navbar-toggler-icon {
        background-image: url('./assets/images/menu_hamburger.svg');
    }

    .navbar-dark .navbar-toggler {
        border: none;
    }

    .navbar-dark a {
        font-size: 1.1rem;
    }

    .navbar-mobile-special img {
        height: 2.5rem;
    }

    #dropdown04 {
        cursor: pointer !important;
    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 768px) {
        .img-fluid {
            width: 120px
        }

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

        .navbar-mobile-special .navbar-dark {
            padding-left: 6rem !important;
            padding-right: 6rem !important;
        }
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        .navbar-mobile-special .navbar-dark {
            padding-left: 6rem !important;
            padding-right: 6rem !important;
        }
    }
</style>
