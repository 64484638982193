<template>
    <div>
        <slot name="field">

        </slot>
        <slot name="errors">
            <span v-if="this.error_message" class="error-text">
            {{ error_message }}
            </span>
        </slot>
    </div>
</template>

<script>
    export default {
        props: {
            error_message: {
                required: false,
                default: null,
            },
            success_message: {
                required: false,
                default: null,
            }
        }
    }
</script>

<style>

</style>