<template>
    <div>
        <component-card>
            <div slot="content">
                <h2>Whoops! Something went wrong.</h2>
                <p>Email us at <a href="mailto:support@chillabitio.com">support@chillabitio.com</a> or message us through our live chat. We'll get this sorted out right away!</p>
                <div class="text-center">
                    <a href="mailto:support@chillabitio.com"><button class="btn btn-primary pt-2 mr-1 mt-3">&nbsp;Email Us&nbsp;</button></a>
                    <button @click="openLiveChat()" class="slaask-open-widget btn btn-primary pt-2 ml-1 mt-3">Live Chat</button>
                </div>
            </div>
        </component-card>
    </div>
</template>

<script>

    import FaqCard from './FAQCard.vue'
    import MessageCard from './MessageCard.vue'
    import ComponentForm from '../forms/Form.vue'
    import ComponentCard from '../components/Card.vue'
    import SubmissionButton from '../forms/SubmissionButton.vue'


    import {getEndpoint, getPageLocation, Fingerprint} from '../assets/js/globals'



    export default {
        data() {
            return {}
        },
        computed: {},
        components: {
            ComponentCard,
            SubmissionButton
        },
        methods: {
            openLiveChat() {
                Beacon("open");
            },
        },
        created() {},
        mounted() {},
    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors';

</style>
