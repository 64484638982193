<template>
    <div class="row">
        <div class="col-md pr-2 my-auto">
            <slot name="field" >
                <input placeholder="you@example.com" class="form-control placeholder field-outline"/>
            </slot>
        </div>

        <div class="d-inline-flex">
            <div class="col mt-3 mt-md-0">
                <slot name="button">
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() { return {}; },
    }
</script>
