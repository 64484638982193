<template>
    <card-component v-show="this.show_message" class="message-card" >
        <div slot="content">
            <span @click="show_message=false" class="oi oi-circle-x"></span>
            <div>
                <span class="message-header">
                    <slot name="message-header">
                        Payment Successful!
                    </slot>
                </span>
                    <span class="message-body">
                        <slot name="message-body">
                            We’ve received your payment. Before you go, please enter your Spotify login details below so we can upgrade your account to premium.
                        </slot>
                    </span>
            </div>
        </div>
    </card-component>
</template>

<script>
    import CardComponent from '../components/Card.vue'

    export default {
        data() {
            return {
                show_message: true
            }
        },
        props: {

        },
        components: {
            CardComponent
        },
        methods: {
        },
        mounted() {
        }
    }
</script>


<style scoped lang="scss">
    @import '../assets/css/colors';

    .oi-circle-x {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.9rem;
        cursor: pointer;
        padding-left: 2rem;
        padding-top: 10px;
        padding-right: 10px;
    }

    .message-card {
        color: white;
    }

    .message-header {
        display: block;
        font-weight: 400;
        font-size: 1.1rem;
    }



    .message-body {
        font-size: 1rem;
        font-weight: 300;
        margin-top: 0.5rem;
        display: block;
    }

    .primary  {
        background-color: $primary-blue;
    }

</style>
