<template>
    <div>
        <div class="receipt position-relative">

            <div v-if="is_loading" class="position-absolute" style="top: 25%; left: 20%;">
                <loading-spinner class="text-left"></loading-spinner>
            </div>

            <div :class="is_loading ? 'invisible' : ''">
                <div class="d-flex justify-content-between pb-3">
                    <div><b>Account:</b></div>
                    <div>{{ account }}</div>
                </div>

                <div class="d-flex justify-content-between pb-3">
                    <div><b>Next bill:</b></div>
                    <div class="text-right">
                        <div v-if="next_bill_amount !== ''">
                            ${{ next_bill_amount }} USD
                        </div>
                        <div v-if="next_bill_amount !== ''">
                            {{ next_bill_date }}
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div><b>Current card:</b></div>
                    <div class="text-right">
                        <div v-if="last4 !== ''">**** **** **** {{ last4 }}</div>
                        <div v-if="exp_month !== ''">expires: {{ exp_month }}/{{ exp_year }}</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {getEndpoint} from '../assets/js/globals'
    import LoadingSpinner from '../components/Spinner.vue'

    export default {
        data() {
            return {
                token : null,
                account: '',
                next_bill_date: '',
                next_bill_amount: '',
                exp_year: '',
                exp_month: '',
                last4: '',
                card_type: '',
                is_loading: true,
            }
        },
        components: {
          LoadingSpinner
        },
        methods: {
            pullSubscriptionDetails(token) {
                this.is_loading = true;
                this.token = token;

                $.ajax({
                    context: this,
                    url: getEndpoint('subscription'),
                    type: "GET",
                    dataType: "json",
                    contentType: "application/json",
                    headers: {
                        'Authorization': 'Token ' + token
                    },
                    success: function (data, status, xhr) {
                        console.log(data);
                        this.account = data['account'];
                        this.last4 = data['last4'];
                        this.exp_year = data['exp_year'];
                        this.exp_month = data['exp_month'];
                        this.next_bill_date = new Date(data['next_bill_date'] * 1000).toLocaleDateString("en-US");
                        this.next_bill_amount = data['next_bill_amount'];
                        this.card_type = data['brand'];
                        this.$emit('pulledSubscriptionDetails', {'status': xhr.status, 'active_subscription' : data['next_bill_date']})
                    },
                    error: function (data) {
                        console.log(data);
                        this.$emit('pulledSubscriptionDetails', {'status': data['status']});
                    },
                    complete: function (data) {
                        console.log(data);
                        this.is_loading = false;
                    }
                });
            }
        },
        created() {
            if (this.token !== null) {
                this.pullSubscriptionDetails(this.token);
            }
        }
    }
</script>

<style scoped>
    .receipt {
        min-width: 50%;
        border-radius: 10px;
        display: inline-block;
        padding: 1rem;
    }

    .receipt {
        font-size: 1rem;
        color: #000;
        font-weight: 400;
    }

    .receipt b {
        font-weight: 500;
        margin-right: 10px;
    }
</style>