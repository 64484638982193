<template>
    <div class="accordian">
        <div class="accordian-item" v-for="item, i in items">
            <div class="accordian-header d-flex justify-content-between" data-toggle="collapse" :data-target="'#collapseExample' + i" aria-expanded="false" :aria-controls="'#collapseExample' + i">
                {{ item.header }}
                <div class="arrows">
                    <span class="oi oi-caret-bottom" style="font-size: 0.9rem;"></span>
                    <span class="oi oi-caret-top" style="font-size: 0.9rem;"></span>
                </div>
            </div>

            <div class="collapse accordian-body" :id="'collapseExample' + i">
                <p>{{ item.text }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            items : {required: true}
        }
    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors';

    .accordian-header {
        color: $light-grey;
        font-size: 1rem;
        border-bottom: 1px solid #ededed;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
    }

    .accordian-header[aria-expanded="true"] .arrows .oi-caret-bottom {
        display: none;
    }

    .accordian-header[aria-expanded="false"] .arrows .oi-caret-top {
        display: none;
        background-color: red;
    }

    .accordian-body {
        font-size: 0.9rem;
    }

    .accordian-body p {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
    }

</style>