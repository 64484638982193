<template>
    <div>
        <div :style="{
            width:this.size,
            height:this.size,
            borderBottom: this.thickness + ' solid ' + this.ringColor,
            borderLeft: this.thickness + ' solid ' + this.ringColor,
            borderRight: this.thickness + ' solid ' + this.ringColor,
            borderTop: this.thickness + ' solid ' + this.spinnerColor
        }" class="loader d-inline-block"></div>
    </div>
</template>

<script>
    export default {
        props: {
            size: {
                required: false,
                default: "60px"
            },
            thickness: {
                required: false,
                default: "6px"
            },
            ringColor: {
                required: false,
                default: "#dcdce8"
            },
            spinnerColor: {
                required: false,
                default: "#8f949e"
            }
        }
    }
</script>

<style scoped>
    .loader {
        opacity: 0.6;
        border-radius: 50%;
        animation: spin 1.5s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>