<template>
    <div class="cursor-pointer" @click.prevent="click()">
        <slot></slot>
    </div>
</template>

<script>
    import {getPageLocation} from '../assets/js/globals'

    export default {
        props : {
            text: {
                type : String,
                required : true,
            },
            section: {
                type : String,
                required : false,
                default : null
            },
            destination: {
                type : String,
                required : false,
                default : null
            },
            newtab: {
                type : Boolean,
                required : false,
                default : false
            }
        },
        methods : {
            click() {
                if (this.destination === null) {
                    console.log("sending click event");
                    this.sendClickEvent(this.text, this.section, null);
                }
                else {
                    console.log("sending link event");
                    let url = getPageLocation(this.destination);
                    this.sendClickEvent(this.text, this.section, url);
                    this.clickLink(url);
                }
            },
            clickLink(url) {
                if (this.newtab === true) {
                    var win = window.open(url, '_blank');
                    win.focus();
                }
                else {
                    setTimeout(() => window.location.href = url, 500);
                }

            },
            sendClickEvent(text, section, destination) {
                let event = new this.$clickEvent(text, destination, section);
                event.sendEvent();
            },
        }

    }
</script>

<style scoped>
    .cursor-pointer {
        cursor: pointer;
    }

    .cursor-normal {
        cursor: default !important;
    }
</style>
