<template>
    <div class="card">
        <slot name="no-padding-content">

        </slot>
        <div v-if="hasSlotData" class="card-block">
            <slot name="content"></slot>
        </div>

    </div>
</template>

<script>
    export default {
        data() { return {}; },
        computed: {
            hasSlotData() {
                return this.$slots.content;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors.scss';

    .card {
        box-shadow: 0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.15);
    }

    .card-block h3 {
        color: $footer;
        font-weight: 500;
        padding-bottom: 1rem;
        font-size: 1.2rem;
    }

    .card-block h4 {
        font-size: 1.1rem;
        font-weight: 400;
        color: #646464;
    }

    .inactive-card h3 {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        color: #ccc;
    }

    .inactive-card {
        border: #ddd solid 1px;
        background-color: #f2f2f2;
    }

    .active-card {
        background-color: #fff;
    }


    /* CARD BLOCK */

    .card-block {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .no-padding > .card-block {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    @media (min-width: 768px) {

        .card-block {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        .less-padding > .card-block {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .extra-padding > .card-block {
            padding-left: 4.5rem;
            padding-right: 4.5rem;
            padding-top: 3.5rem;
            padding-bottom: 3.5rem;
        }

    }

    @media (min-width: 1000px) {
        .card-block {
            padding-left: 4rem;
            padding-right: 4rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        .less-padding > .card-block {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .extra-padding > .card-block {
            padding-left: 8rem;
            padding-right: 8rem;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }

        .wide-space {
            margin-left: 4rem;
            margin-right: 4rem;
        }
    }

</style>
