<template>
    <div>
        <div class="row" v-if="status === 0">
            <div class="col">
                <component-card class="extra-padding wide-space" v-if="this.status === 0">
                    <div slot="content">
                        <div id="loading" class="col-xs-12 center-text" style="display: inherit;">
                            <h2 style="text-align: center">Setting up your account...</h2>
                            <div class="sliding-loading-indicator-holder centered-properly mt-4">
                                <div class="sliding-loading-indicator"></div>
                            </div>
                        </div>
                    </div>
                </component-card>
            </div>
        </div>

        <div class="row" v-else>
            <div class="col-lg-7 md-lg-3 mr-lg-5">
                <!-- PERSONAL ACCOUNT -->
                <template v-if="this.is_personal_account">

                    <message-card v-if="!form_submitted" class="primary mb-4"></message-card>

                    <component-form :error_message="email_errors">
                        <div slot="form">
                    <component-card>
                        <div slot="content">

                            <h3><img class="spotify" src="/src/assets/images/spotify-green.svg"/> Enter Your Spotify Account Details</h3>

                            <!-- FORM -->
                            <component-form v-if="!form_submitted" error_message="" success_message="">
                                <div slot="form">
                                    <input placeholder="Username / Email Address" autocomplete="off" autocorrect="off" spellcheck="false" autocapitalize="off" v-model="username" class="form-control placeholder field-outline"/>
                                    <span class="error-text" v-if="this.show_form_errors && this.username_errors !== ''">{{ username_errors }}</span>
                                    <input placeholder="Password" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" v-model="password" class="form-control placeholder field-outline mt-3"/>
                                    <span class="error-text" v-if="this.show_form_errors && this.password_errors !== ''">{{ password_errors }}</span>
                                    <span class="d-block mt-3">Please double check your login details before you submit as we may need to reach out to you if they’re incorrect! </span>
                                    <div slot="button" class="pt-1 mt-3">
                                        <submission-button @submissionWasClicked="submitAccountDetails()" :buttonText="'SUBMIT'" section="Thank-You" :is_submitting="is_submitting" :classes="'btn-primary w-100'"></submission-button>
                                    </div>
                                    <div class="error-text pt-3" v-if="this.form_errors !== ''">{{ form_errors }}</div>
                                </div>
                            </component-form>

                            <!-- POST FORM -->
                            <div v-else>
                                <p>Success! You’ll receive an email from us within 24 hours as soon as your account has been upgraded to Spotify Premium. <img class="party" src="/src/assets/images/party-emoji.png"/></p>

                                <p>The email we have on file is: <email-form :token=this.fingerprint.auth_token :email="this.email" @updatedEmail="emailWasUpdated($event)"></email-form></p>


                                <div class="grey-outline mt-4">WE'LL BE IN TOUCH WITHIN 24 HOURS...</div>


                            </div>
                        </div>
                    </component-card>
                        </div>
                    </component-form>
                </template>

                <template v-else>

                    <message-card v-if="!form_submitted" class="primary mb-4">
                        <div slot="message-header">
                            Payment Successful! <img class="party" src="/src/assets/images/party-emoji.png"/>
                        </div>
                        <div slot="message-body">
                            We’ve received your payment. Your next bill is in <b>30 days</b>
                        </div>
                    </message-card>

                    <!-- EXISTING ACCOUNT -->
                    <component-form  :error_message="email_errors">
                        <component-card slot="form">
                            <div slot="content">

                            <!-- Email Form -->
                            <p class="text-center">An email with your login information was sent to:
                                <email-form :token=this.fingerprint.auth_token :email="this.email" @updatedEmail="emailWasUpdated($event)"></email-form></p>

                            <hr>

                            <template v-if="this.is_account_available">
                                <div class="pl-md-4 pr-md-4 pt-1 pb-1">
                                    <div>
                                        <p style="font-weight:bold; margin-bottom: 0">Username:</p>
                                        <div class="row copyable-input-container">
                                            <div class="col pr-2 my-auto">
                                                <input readonly="readonly" class="copyable-input" v-model="username" id="username" type="text"/>
                                            </div>

                                            <div class="d-inline-flex">
                                                <div class="col" style="padding-right: 5px">
                                                    <button  @click="copyText('username', username)" class="btn btn-primary ml-3 copy-button"><img class="copy-icon" src="../assets/images/copy-content.svg"/></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <p class="mt-3" style="font-weight:bold; margin-bottom: 0">Password:</p>
                                        <div class="row copyable-input-container">
                                            <div class="col pr-2 my-auto">
                                                <input readonly="readonly" class="copyable-input" v-model="password" id="password" type="text"/>
                                            </div>

                                            <div class="d-inline-flex">
                                                <div class="col" style="padding-right: 5px">
                                                    <button  @click="copyText('password', password)" class="btn btn-primary ml-3 copy-button"><img class="copy-icon" src="../assets/images/copy-content.svg"/></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <span class="tiny">* Please do not change the email or password or we will not be able to recover your account in the unlikely event that it is lost.</span>

                                    <div class="custom-tooltip text-center">
                                        <span class="tooltiptext" id="myTooltip">{{ this.tooltip }}</span>
                                    </div>
                                </div>
                            </template>

                            <!-- NO ACCOUNTS AVAILABLE -->
                            <template v-else>
                                <div class="col my-auto">
                                    <h3 class="pt-3">Thanks for signing up!</h3>
                                    <span>Great news, your payment has been processed and your Spotify account is being setup. You should receive an email with your login details <b>within 24 hours</b>.</span>
                                    <h3 class="mt-4">While you are waiting...</h3>
                                </div>
                            </template>
                            <guide-card-large class="mt-2 mb-3"></guide-card-large>
                        </div>
                    </component-card>
                    </component-form>
                </template>

                <!-- FAQ SMALL -->
                <div class="d-block d-lg-none mt-4">
                    <faq-card>
                        <div slot="faq-items">
                            <!-- PERSONAL ACCOUNT -->
                            <template v-if="this.is_personal_account">
                                <h5>What details do I send if I sign in using Facebook?</h5>
                                <span>
                                We still need your username and password to complete the process. With Facebook login there are a couple of steps you need to take to retrieve the info.
                                <ol>
                                    <li>Open your browser and head to www.spotify.com</li>
                                    <li>Sign in and head to your Account information</li>
                                    <li>Your email/username should be located under the <b>Profile</b> section.</li>
                                    <li>Scroll down and click <b>SET PASSWORD</b> now set your password (don’t worry, you can change this after)</li>
                                    <li>Now submit your info to us, and we’ll work on getting you hooked up!</li>
                                </ol>
                            </span>
                                <h5>How will I be notified when my account is good to go? </h5>
                                <span>We’ll send a confirmation to the email you provided on the checkout. Upgrading your Spotify account can take up to <b>24 hours.</b> </span>
                            </template>

                            <!-- OTHER -->
                            <template v-else-if="this.is_account_available">
                                <h5>Can I change my account details after I sign in?</h5>
                                <span>Absolutely! This is your account forever. Feel free to change your credentials to whatever you want.</span>
                                <h5>What should I do next?</h5>
                                <span>Now you have your new Spotify credentials, follow our instructions and get set up with the official Spotify app. We’ve made sure that our guides will work in any country. After you can just sit back, relax and listen to great music!</span>
                                <h5>I wanted to use my existing Spotify account, not buy a new one. What should I do?</h5>
                                <span>No problem, email us at <a href="mailto:support@chillabitio.com">support@chillabitio.com</a> or send us a message in our <a @click="openLiveChat()">live chat</a> below and we’ll help you switch plans.</span>
                            </template>

                            <template v-else>
                                <h5>Why do I have to wait 24 hours?</h5>
                                <span>We are experiencing a high volume of signups right now and have run out of Spotify Premium accounts. Don't worry! We make sure that everyone receives their account within 24 hours.</span>
                                <h5>How will I be notified when my account is good to go? </h5>
                                <span>We’ll send a confirmation to the email you provided on the checkout{{ this.email ? ': ' + this.email : '.' }}</span>
                                <h5>What should I do if I have questions?</h5>
                                <span>Email us at <a href="mailto:support@chillabitio.com">support@chillabitio.com</a> or send us a message in our <a @click="openLiveChat()">live chat</a> below.</span>
                            </template>
                        </div>

                    </faq-card>
                </div>

                <!-- INSTALLATION GUIDE SMALL-->
                <div class="d-block d-lg-none">
                    <component-card :class="this.show_installation_guide ? 'mt-4 less-padding' : 'inactive-card mt-4'">
                        <div slot="content">
                            <template v-if="this.show_installation_guide">
                                <div>
                                    <h5 style="font-size: 0.99rem">Any Other Questions?</h5>
                                    <span style="font-size: .82rem"> If you have any questions or feedback, we'd love to chat! Send us an email at <a href="mailto:support@chillabitio.com">support@chillabitio.com</a> or message us through our <a @click="openLiveChat()">live chat</a>.</span>
                                </div>
                                <div class="d-flex justify-content-center mt-4">
                                    <a class="ml-2 mr-2" href="mailto:support@chillabitio.com"><button type="button" class="btn btn-primary btn-sm mb-4" @click.prevent>EMAIL US</button></a>
                                    <button @click="openLiveChat()" type="button" class="btn btn-primary mb-4 btn-sm ml-2 mr-2">LIVE CHAT</button>
                                </div>
                            </template>
                            <template v-else>
                                <h5 style="color: #ccc" class="inactive mb-0">Installation Guide</h5>
                            </template>

                        </div>
                    </component-card>
                </div>

                <!-- INSTALLATION GUIDE LARGE-->
                <div class="d-none d-lg-block">
                    <component-card :class="this.show_installation_guide ? 'mt-4' : 'inactive-card mt-4'">
                        <div slot="content">
                            <template v-if="this.show_installation_guide">
                                <div>
                                    <h5>Any Other Questions?</h5>
                                    <span> If you have any questions or feedback, we'd love to chat! Send us an email at <a href="mailto:support@chillabitio.com">support@chillabitio.com</a> or message us through our <a @click="openLiveChat()" class="d-inline-block">live chat</a>.</span>
                                </div>
                                <div class="d-flex justify-content-center mt-4">
                                    <a class="ml-2 mr-2" href="mailto:support@chillabitio.com"><button type="button" class="btn btn-primary btn-sm" @click.prevent>EMAIL US</button></a>
                                    <button @click="openLiveChat()" type="button" class="btn btn-primary btn-sm ml-2 mr-2">LIVE CHAT</button>
                                </div>
                            </template>
                            <template v-else>
                                <h5 style="color: #ccc" class="inactive mb-0">Installation Guide</h5>
                            </template>

                        </div>
                    </component-card>
                </div>

            </div>


            <!-- FAQ LARGE -->
            <div class="d-none d-lg-block col-lg-4 mt-lg-0 mt-md-4 pr-0 mr-lg-0 mr-md-3">
                <faq-card>
                    <div slot="faq-items">
                        <!-- PERSONAL ACCOUNT -->
                        <template v-if="this.is_personal_account">
                            <h5>What details do I send if I sign in using Facebook?</h5>
                            <span>
                                We still need your username and password to complete the process. With Facebook login there are a couple of steps you need to take to retrieve the info.
                                <ol>
                                    <li>Open your browser and head to www.spotify.com</li>
                                    <li>Sign in and head to your Account information</li>
                                    <li>Your email/username should be located under the <b>Profile</b> section.</li>
                                    <li>Scroll down and click <b>SET PASSWORD</b> now set your password (don’t worry, you can change this after)</li>
                                    <li>Now submit your info to us, and we’ll work on getting you hooked up!</li>
                                </ol>
                            </span>
                            <h5>How will I be notified when my account is good to go? </h5>
                            <span>We’ll send a confirmation to the email you provided on the checkout. Upgrading your Spotify account can take up to <b>24 hours.</b> </span>
                        </template>

                        <!-- OTHER -->
                        <template v-else-if="this.is_account_available">
                            <h5>Can I change my account details after I sign in?</h5>
                            <span>Absolutely! This is your account forever. Feel free to change your credentials to whatever you want.</span>
                            <h5>What should I do next?</h5>
                            <span>Now you have your new Spotify credentials, follow our instructions and get set up with the official Spotify app. We’ve made sure that our guides will work in any country. After you can just sit back, relax and listen to great music!</span>
                            <h5>I wanted to use my existing Spotify account, not buy a new one. What should I do?</h5>
                            <span>No problem, email us at <a href="mailto:support@chillabitio.com">support@chillabitio.com</a> or send us a message in our <a @click="openLiveChat()">live chat</a> below and we’ll help you switch plans.</span>
                        </template>

                        <template v-else>
                            <h5>Why do I have to wait 24 hours?</h5>
                            <span>We are experiencing a high volume of signups right now and have run out of Spotify Premium accounts. Don't worry! We make sure that everyone receives their account within 24 hours.</span>
                            <h5>How will I be notified when my account is good to go? </h5>
                            <span>We’ll send a confirmation to the email you provided on the checkout{{ this.email ? ': ' + this.email : '.' }}</span>
                            <h5>What should I do if I have questions?</h5>
                            <span>Email us at <a href="mailto:support@chillabitio.com">support@chillabitio.com</a> or send us a message in our <a @click="openLiveChat()">live chat</a> below.</span>
                        </template>
                   </div>

                </faq-card>
            </div>

        </div>
    </div>

</template>

<script>

    import FaqCard from './FAQCard.vue'
    import MessageCard from './MessageCard.vue'
    import ComponentForm from '../forms/Form.vue'
    import ComponentCard from '../components/Card.vue'
    import EmailForm from './EmailForm.vue'
    import SubmissionButton from '../forms/SubmissionButton.vue'
    import GuideCardLarge from './GuideCard2.vue'
    import {fbtag, send_conversion_event} from '../assets/js/trackers'
    import {urlParam} from '../assets/js/utilities'
    import {isDev, getEndpoint, getPageLocation, Fingerprint} from '../assets/js/globals'
    import Cookies from '../assets/js/cookie'


    export default {
        data() {
            return {
                fingerprint: null,
                username: "test",
                password: "test",
                is_submitting: false,
                form_submitted: false,
                show_form_errors: false,
                form_errors: "",
                tooltip : 'copy to clipboard',
                status: 0,
                plan: "",
                name: "",
                account_type: 0,
                email: "",
                email_errors: ""
            }
        },
        computed: {
            is_personal_account() {
                if (this.account_type === 1)
                    return true;
                return false;
            },
            is_account_available() {
                if (!this.is_personal_account && this.status !== 202)
                    return true;
                return false;
            },
            show_installation_guide() {
                if (this.is_personal_account && !this.form_submitted)
                    return false;
                return true;
            },
            get_installation_guide_text() {
                if (this.is_personal_account)
                    return "While you are waiting here are a few simple instructions to get you the official Spotify App anywhere in the world."
                else if (this.is_account_available)
                    return "Here are a few simple instructions to get you the official Spotify App anywhere in the world.."
                return "While you are waiting here are a few simple instructions to get you the official Spotify App anywhere in the world."
            },
            username_errors() {
                if (this.username === "") {
                    return "* Enter the username of the Spotify account you would like upgraded"
                }
                return "";
            },
            password_errors() {
                if (this.password === "") {
                    return "* Enter the password of the Spotify account you would like upgraded"
                }
                return "";
            },



        },
        components: {
            FaqCard,
            MessageCard,
            ComponentForm,
            ComponentCard,
            SubmissionButton,
            GuideCardLarge,
            EmailForm
        },
        methods: {
            validateFields() {
                if (this.username === "" || this.password === "") {
                    return false;
                }
                return true;
            },
            emailWasUpdated(data) {
                if (data['status'] !== 200) {
                    this.email_errors = "We were not able to update your email at this time. Please contact support for assistance!";
                }
                else {
                    this.email = data['email'];
                    this.email_errors = "";
                }
                console.log("email was updated");
            },
            submitAccountDetails() {

                this.show_form_errors = true;

                if (!this.validateFields())
                    return;

                this.is_submitting = true;
                let url = getEndpoint('submit_details');
                let data = {
                    'spotify_username' : this.username,
                    'spotify_password' : this.password,
                };

                $.ajax({
                    context: this,
                    url: url,
                    headers : {'Authorization': 'Token ' + this.fingerprint.auth_token},
                    data: JSON.stringify(data),
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json"
                }).done(() => {
                    // TODO: FIND OUT WHY THIS ISNT FIRING
                    this.form_submitted = true;
                }).fail(function (data) {
                    console.log(data['status']);
                    if (data['status'] === 401) {
                        window.location.href = getPageLocation('checkout401');
                    }
                    else if (data['status'] === 400) {
                        this.form_errors = data.responseText.replace (/(^")|("$)/g, '');
                    }
                    else if (data['status'] === 200) {
                        this.form_submitted = true;
                    }
                    else {
                        console.log(data)
                        window.location.href = getPageLocation('checkout500');
                    }

                }).always(data => {
                    this.is_submitting = false;
                });

            },
            getCredentials() {
                let url = getEndpoint('account');
                let token = this.fingerprint.auth_token;

                $.ajax({
                    context: this,
                    url: url,
                    headers: {'Authorization': 'Token ' + token},
                    type: "GET",
                    dataType: "json",
                    contentType: "application/json"

                }).done(function (data, status, xhr) {
                    this.status = xhr.status;

                    console.log(data);

                    this.username = data['username'];
                    this.password = data['password'];
                    this.account_type = data['type'];
                    this.plan = data['plan'];
                    this.email = data['email'];
                    this.name = data['name'];

                    if (this.password !== undefined && this.password !== "" && this.account_type === 1) {
                        this.form_submitted = true;
                    }
                }).fail(function(data, status, error) {
                    console.log(data)
                    console.log(status)
                    console.log(error)
                    console.log(data.status)
                    if (data.status === 401) {
                        window.location.href = getPageLocation('checkout401');
                        return;
                    }
                    window.location.href = getPageLocation('checkout500');
                });
            },
            openLiveChat() {
                Beacon("open");
            },
            copyText(inputId, text) {
                let copyText = document.getElementById(inputId);
                copyText.select();
                document.execCommand("Copy");
                this.tooltip = "copied: " + text;

                $('#myTooltip').css('opacity', '1');

                setTimeout(
                    () => {
                        $('#myTooltip').css('opacity', '0');
                    }, 1500);
            },

        },

        created() {
            this.fingerprint = new Fingerprint();
            this.fingerprint.loadFingerprint();

            // load from url param if it exists
            let token = urlParam('token');
            if (token !== null) {
                console.log("Found login token");
                this.fingerprint.setAuthToken(token);

                // make sure to remove the url param
                let url = window.location.href;
                // find location of params
                let index = url.indexOf('?');
                if (index === -1) {
                    index = url.indexOf('#');
                }
                //remove params and redirect
                if (index !== -1) {
                    this.is_redirecting = true;
                    console.log("redirecting");
                    window.location.href = url.substring(0, index);
                }

                this.getCredentials();
                return;
            }

            // otherwise load from cross site fingerprint
            let param = urlParam(null);
            if (param !== null) {
                try {
                    console.log("Loading from url param")

                    let success = this.fingerprint.loadFromUrlParam(param);
                    if (this.fingerprint.isExpired() || !success || !this.fingerprint.isValidParamFingerprint()) {
                        window.location.href = getPageLocation('checkout500');
                        return;
                    }

                    let conversion = Cookies.get('conversion');
                    if (conversion === undefined) {
                        Cookies.set('conversion', true, {expires: 30});
                        send_conversion_event();
                    }

                } catch(err) {
                    window.location.href = getPageLocation('checkout500');
                    return;
                }
                this.getCredentials();
                return;
            }


            // otherwise load from fingerprint if exists
            token = this.fingerprint.auth_token;
            if (token !== null) {
                console.log("Found saved cookie")
                this.getCredentials();
                return;
            }
        },
    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors';

    .spotify {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
    }

    .party {
        height: 1.1rem;
        margin-bottom: 5px;
        margin-left: 2px;
    }

    h3 > .party {
        height: 1.5rem;
    }

    .grey-outline {
        border-radius: 30px;
        background-color: #ffffff;
        border: solid 2px #f2f2f2;
        font-weight: 500;
        text-align: center;
        color: #c1c1c1;
        padding: 0.7rem 0.5rem;
    }

    .copyable-input {
        border: none !important;
        padding: 2px;
        display: block;
        font-size: 0.8rem;
        min-width: 13rem;
        background-color: #f8f8f8;
    }

    .copyable-input-container {
        background-color: #f8f8f8;
        border-radius: 3px;
        padding: 2px;
        margin-left: 0;
        margin-right: 0;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-top: 0.5rem;
    }

    .custom-tooltip {
        position: relative;
        display: block;
    }

    .login-details {
        border: solid 3px $color-pink;
    }

    .copy-icon {
        height: 1.1rem;
    }

    .copy-button {
        border-radius: 5px;
        padding-left: .5rem;
        padding-right: .5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        font-size: 0.9rem;
    }

    .custom-tooltip .tooltiptext {
        width: 140px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: opacity 0.3s;
        opacity: 0;
    }


    .tiny {
        display: block;
        margin-top: 0.5rem;
        font-size: 0.75rem;
    }

    a {
        color: #007bff !important;
        cursor: pointer;
    }

    .sliding-loading-indicator {
        width: 25%;
        position: relative;
        animation: sliding-indicator 1s infinite;
        background-color: #9f6bec;
        border: solid 1px #b891f2;
        height: 20px;
        box-shadow: 0 0 5px #AAAAAA;
    }



    @keyframes sliding-indicator {
        0% {
            transform: translateX(0%);
            background-color: #513d78;
        }
        50% {
            transform: translateX(300%);
            background-color: #b22643;
        }
        100% {
            transform: translateX(0%);
            background-color: #513d78;
        }
    }


    @media (min-width: 1000px) {


    }



</style>
