<template>
    <div class="card">
        <slot>

        </slot>
    </div>
</template>

<script>
    export default {
        data() { return {}; }
    }
</script>

<style scoped lang="scss">

    .card {
        box-shadow: 0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.15);
        background-color: #fff;
        margin-bottom: 10px;
    }

    .inactive-card h6 {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        color: #ccc;
    }

    .inactive-card {
        border: #ddd solid 1px;
        background-color: #f2f2f2;
        margin-bottom: 10px;
    }



</style>
