export const countries = {
    'russia': {
        'name': 'Russia',
        'flag': '/src/assets/images/flags/russia.svg',
        'rounded_flag': '/src/assets/images/round_flags/russia.svg',
        'extension': '+7',
        'code': 'RU'
    },
    'egypt': {
        'name': 'Egypt',
        'flag': '/src/assets/images/flags/egypt.svg',
        'rounded_flag': '/src/assets/images/round_flags/egypt.svg',
        'extension': '+20',
        'code': 'EG'
    },
    'nepal': {
        'name': 'Nepal',
        'flag': '/src/assets/images/flags/nepal.svg',
        'rounded_flag': '/src/assets/images/round_flags/nepal.svg',
        'extension': '+977',
        'code': 'NP'
    },
    'bahamas': {
        'name': 'Bahamas',
        'flag': '/src/assets/images/flags/bahamas.svg',
        'rounded_flag': '/src/assets/images/round_flags/bahamas.svg',
        'extension': '+1',
        'code': 'BS'
    },
    'afghanistan': {
        'name': 'Afghanistan',
        'flag': '/src/assets/images/flags/afghanistan.svg',
        'rounded_flag': '/src/assets/images/round_flags/afghanistan.svg',
        'extension': '+93',
        'code': 'AF'
    },
    'albania': {
        'name': 'Albania',
        'flag': '/src/assets/images/flags/albania.svg',
        'rounded_flag': '/src/assets/images/round_flags/albania.svg',
        'extension': '+355',
        'code': 'AL'
    },
    'algeria': {
        'name': 'Algeria',
        'flag': '/src/assets/images/flags/algeria.svg',
        'rounded_flag': '/src/assets/images/round_flags/algeria.svg',
        'extension': '+213',
        'code': 'DZ'
    },
    'american samoa': {
        'name': 'American Samoa',
        'flag': '/src/assets/images/flags/american-samoa.svg',
        'rounded_flag': '/src/assets/images/round_flags/american-samoa.svg',
        'extension': '+1',
        'code': 'AS'
    },
    'andorra': {
        'name': 'Andorra',
        'flag': '/src/assets/images/flags/andorra.svg',
        'rounded_flag': '/src/assets/images/round_flags/andorra.svg',
        'extension': '+376',
        'code': 'AD'
    },
    'angola': {
        'name': 'Angola',
        'flag': '/src/assets/images/flags/angola.svg',
        'rounded_flag': '/src/assets/images/round_flags/angola.svg',
        'extension': '+244',
        'code': 'AO'
    },
    'anguilla': {
        'name': 'Anguilla',
        'flag': '/src/assets/images/flags/anguilla.svg',
        'rounded_flag': '/src/assets/images/round_flags/anguilla.svg',
        'extension': '+1',
        'code': 'AI'
    },
    'antarctica': {
        'name': 'Antarctica',
        'flag': '/src/assets/images/flags/antarctica.svg',
        'rounded_flag': '/src/assets/images/round_flags/antarctica.svg',
        'extension': '+672',
        'code': 'AQ'
    },
    'antigua and barbuda': {
        'name': 'Antigua and Barbuda',
        'flag': '/src/assets/images/flags/antigua-and-barbuda.svg',
        'rounded_flag': '/src/assets/images/round_flags/antigua-and-barbuda.svg',
        'extension': '+1',
        'code': 'AG'
    },
    'argentina': {
        'name': 'Argentina',
        'flag': '/src/assets/images/flags/argentina.svg',
        'rounded_flag': '/src/assets/images/round_flags/argentina.svg',
        'extension': '+54',
        'code': 'AR'
    },
    'armenia': {
        'name': 'Armenia',
        'flag': '/src/assets/images/flags/armenia.svg',
        'rounded_flag': '/src/assets/images/round_flags/armenia.svg',
        'extension': '+374',
        'code': 'AM'
    },
    'aruba': {
        'name': 'Aruba',
        'flag': '/src/assets/images/flags/aruba.svg',
        'rounded_flag': '/src/assets/images/round_flags/aruba.svg',
        'extension': '+297',
        'code': 'AW'
    },
    'australia': {
        'name': 'Australia',
        'flag': '/src/assets/images/flags/australia.svg',
        'rounded_flag': '/src/assets/images/round_flags/australia.svg',
        'extension': '+61',
        'code': 'AU'
    },
    'austria': {
        'name': 'Austria',
        'flag': '/src/assets/images/flags/austria.svg',
        'rounded_flag': '/src/assets/images/round_flags/austria.svg',
        'extension': '+43',
        'code': 'AT'
    },
    'azerbaijan': {
        'name': 'Azerbaijan',
        'flag': '/src/assets/images/flags/azerbaijan.svg',
        'rounded_flag': '/src/assets/images/round_flags/azerbaijan.svg',
        'extension': '+994',
        'code': 'AZ'
    },
    'bahrain': {
        'name': 'Bahrain',
        'flag': '/src/assets/images/flags/bahrain.svg',
        'rounded_flag': '/src/assets/images/round_flags/bahrain.svg',
        'extension': '+973',
        'code': 'BH'
    },
    'bangladesh': {
        'name': 'Bangladesh',
        'flag': '/src/assets/images/flags/bangladesh.svg',
        'rounded_flag': '/src/assets/images/round_flags/bangladesh.svg',
        'extension': '+880',
        'code': 'BD'
    },
    'barbados': {
        'name': 'Barbados',
        'flag': '/src/assets/images/flags/barbados.svg',
        'rounded_flag': '/src/assets/images/round_flags/barbados.svg',
        'extension': '+1',
        'code': 'BB'
    },
    'belarus': {
        'name': 'Belarus',
        'flag': '/src/assets/images/flags/belarus.svg',
        'rounded_flag': '/src/assets/images/round_flags/belarus.svg',
        'extension': '+375',
        'code': 'BY'
    },
    'belgium': {
        'name': 'Belgium',
        'flag': '/src/assets/images/flags/belgium.svg',
        'rounded_flag': '/src/assets/images/round_flags/belgium.svg',
        'extension': '+32',
        'code': 'BE'
    },
    'belize': {
        'name': 'Belize',
        'flag': '/src/assets/images/flags/belize.svg',
        'rounded_flag': '/src/assets/images/round_flags/belize.svg',
        'extension': '+501',
        'code': 'BZ'
    },
    'benin': {
        'name': 'Benin',
        'flag': '/src/assets/images/flags/benin.svg',
        'rounded_flag': '/src/assets/images/round_flags/benin.svg',
        'extension': '+229',
        'code': 'BJ'
    },
    'bermuda': {
        'name': 'Bermuda',
        'flag': '/src/assets/images/flags/bermuda.svg',
        'rounded_flag': '/src/assets/images/round_flags/bermuda.svg',
        'extension': '+1',
        'code': 'BM'
    },
    'bhutan': {
        'name': 'Bhutan',
        'flag': '/src/assets/images/flags/bhutan.svg',
        'rounded_flag': '/src/assets/images/round_flags/bhutan.svg',
        'extension': '+975',
        'code': 'BT'
    },
    'bolivia': {
        'name': 'Bolivia',
        'flag': '/src/assets/images/flags/bolivia.svg',
        'rounded_flag': '/src/assets/images/round_flags/bolivia.svg',
        'extension': '+591',
        'code': 'BO'
    },
    'bosnia and herzegovina': {
        'name': 'Bosnia and Herzegovina',
        'flag': '/src/assets/images/flags/bosnia-and-herzegovina.svg',
        'rounded_flag': '/src/assets/images/round_flags/bosnia-and-herzegovina.svg',
        'extension': '+387',
        'code': 'BA'
    },
    'botswana': {
        'name': 'Botswana',
        'flag': '/src/assets/images/flags/botswana.svg',
        'rounded_flag': '/src/assets/images/round_flags/botswana.svg',
        'extension': '+267',
        'code': 'BW'
    },
    'brazil': {
        'name': 'Brazil',
        'flag': '/src/assets/images/flags/brazil.svg',
        'rounded_flag': '/src/assets/images/round_flags/brazil.svg',
        'extension': '+55',
        'code': 'BR'
    },
    'british indian ocean territory': {
        'name': 'British Indian Ocean Territory',
        'flag': '/src/assets/images/flags/british-indian-ocean-territory.svg',
        'rounded_flag': '/src/assets/images/round_flags/british-indian-ocean-territory.svg',
        'extension': '+246',
        'code': 'IO'
    },
    'british virgin islands': {
        'name': 'British Virgin Islands',
        'flag': '/src/assets/images/flags/british-virgin-islands.svg',
        'rounded_flag': '/src/assets/images/round_flags/british-virgin-islands.svg',
        'extension': '+1',
        'code': 'VG'
    },
    'brunei': {
        'name': 'Brunei',
        'flag': '/src/assets/images/flags/brunei.svg',
        'rounded_flag': '/src/assets/images/round_flags/brunei.svg',
        'extension': '+673',
        'code': 'BN'
    },
    'bulgaria': {
        'name': 'Bulgaria',
        'flag': '/src/assets/images/flags/bulgaria.svg',
        'rounded_flag': '/src/assets/images/round_flags/bulgaria.svg',
        'extension': '+359',
        'code': 'BG'
    },
    'burkina faso': {
        'name': 'Burkina Faso',
        'flag': '/src/assets/images/flags/burkina-faso.svg',
        'rounded_flag': '/src/assets/images/round_flags/burkina-faso.svg',
        'extension': '+226',
        'code': 'BF'
    },
    'burundi': {
        'name': 'Burundi',
        'flag': '/src/assets/images/flags/burundi.svg',
        'rounded_flag': '/src/assets/images/round_flags/burundi.svg',
        'extension': '+257',
        'code': 'BI'
    },
    'cambodia': {
        'name': 'Cambodia',
        'flag': '/src/assets/images/flags/cambodia.svg',
        'rounded_flag': '/src/assets/images/round_flags/cambodia.svg',
        'extension': '+855',
        'code': 'KH'
    },
    'cameroon': {
        'name': 'Cameroon',
        'flag': '/src/assets/images/flags/cameroon.svg',
        'rounded_flag': '/src/assets/images/round_flags/cameroon.svg',
        'extension': '+237',
        'code': 'CM'
    },
    'canada': {
        'name': 'Canada',
        'flag': '/src/assets/images/flags/canada.svg',
        'rounded_flag': '/src/assets/images/round_flags/canada.svg',
        'extension': '+1',
        'code': 'CA'
    },
    'cape verde': {
        'name': 'Cape Verde',
        'flag': '/src/assets/images/flags/cape-verde.svg',
        'rounded_flag': '/src/assets/images/round_flags/cape-verde.svg',
        'extension': '+238',
        'code': 'CV'
    },
    'cayman islands': {
        'name': 'Cayman Islands',
        'flag': '/src/assets/images/flags/cayman-islands.svg',
        'rounded_flag': '/src/assets/images/round_flags/cayman-islands.svg',
        'extension': '+1',
        'code': 'KY'
    },
    'central african republic': {
        'name': 'Central African Republic',
        'flag': '/src/assets/images/flags/central-african-republic.svg',
        'rounded_flag': '/src/assets/images/round_flags/central-african-republic.svg',
        'extension': '+236',
        'code': 'CF'
    },
    'chad': {
        'name': 'Chad',
        'flag': '/src/assets/images/flags/chad.svg',
        'rounded_flag': '/src/assets/images/round_flags/chad.svg',
        'extension': '+235',
        'code': 'TD'
    },
    'chile': {
        'name': 'Chile',
        'flag': '/src/assets/images/flags/chile.svg',
        'rounded_flag': '/src/assets/images/round_flags/chile.svg',
        'extension': '+56',
        'code': 'CL'
    },
    'china': {
        'name': 'China',
        'flag': '/src/assets/images/flags/china.svg',
        'rounded_flag': '/src/assets/images/round_flags/china.svg',
        'extension': '+86',
        'code': 'CN'
    },
    'christmas island': {
        'name': 'Christmas Island',
        'flag': '/src/assets/images/flags/christmas-island.svg',
        'rounded_flag': '/src/assets/images/round_flags/christmas-island.svg',
        'extension': '+61',
        'code': 'CX'
    },
    'cocos islands': {
        'name': 'Cocos Islands',
        'flag': '/src/assets/images/flags/cocos-island.svg',
        'rounded_flag': '/src/assets/images/round_flags/cocos-island.svg',
        'extension': '+61',
        'code': 'CC'
    },
    'colombia': {
        'name': 'Colombia',
        'flag': '/src/assets/images/flags/colombia.svg',
        'rounded_flag': '/src/assets/images/round_flags/colombia.svg',
        'extension': '+57',
        'code': 'CO'
    },
    'comoros': {
        'name': 'Comoros',
        'flag': '/src/assets/images/flags/comoros.svg',
        'rounded_flag': '/src/assets/images/round_flags/comoros.svg',
        'extension': '+269',
        'code': 'KM'
    },
    'cook islands': {
        'name': 'Cook Islands',
        'flag': '/src/assets/images/flags/cook-islands.svg',
        'rounded_flag': '/src/assets/images/round_flags/cook-islands.svg',
        'extension': '+682',
        'code': 'CK'
    },
    'costa rica': {
        'name': 'Costa Rica',
        'flag': '/src/assets/images/flags/costa-rica.svg',
        'rounded_flag': '/src/assets/images/round_flags/costa-rica.svg',
        'extension': '+506',
        'code': 'CR'
    },
    'croatia': {
        'name': 'Croatia',
        'flag': '/src/assets/images/flags/croatia.svg',
        'rounded_flag': '/src/assets/images/round_flags/croatia.svg',
        'extension': '+385',
        'code': 'HR'
    },
    'cuba': {
        'name': 'Cuba',
        'flag': '/src/assets/images/flags/cuba.svg',
        'rounded_flag': '/src/assets/images/round_flags/cuba.svg',
        'extension': '+53',
        'code': 'CU'
    },
    'curacao': {
        'name': 'Curacao',
        'flag': '/src/assets/images/flags/curacao.svg',
        'rounded_flag': '/src/assets/images/round_flags/curacao.svg',
        'extension': '+599',
        'code': 'CW'
    },
    'cyprus': {
        'name': 'Cyprus',
        'flag': '/src/assets/images/flags/cyprus.svg',
        'rounded_flag': '/src/assets/images/round_flags/cyprus.svg',
        'extension': '+357',
        'code': 'CY'
    },
    'czech republic': {
        'name': 'Czech Republic',
        'flag': '/src/assets/images/flags/czech-republic.svg',
        'rounded_flag': '/src/assets/images/round_flags/czech-republic.svg',
        'extension': '+420',
        'code': 'CZ'
    },
    'democratic republic of the congo': {
        'name': 'Democratic Republic of the Congo',
        'flag': '/src/assets/images/flags/republic-of-the-congo.svg',
        'rounded_flag': '/src/assets/images/round_flags/republic-of-the-congo.svg',
        'extension': '+243',
        'code': 'CD'
    },
    'denmark': {
        'name': 'Denmark',
        'flag': '/src/assets/images/flags/denmark.svg',
        'rounded_flag': '/src/assets/images/round_flags/denmark.svg',
        'extension': '+45',
        'code': 'DK'
    },
    'djibouti': {
        'name': 'Djibouti',
        'flag': '/src/assets/images/flags/djibouti.svg',
        'rounded_flag': '/src/assets/images/round_flags/djibouti.svg',
        'extension': '+253',
        'code': 'DJ'
    },
    'dominica': {
        'name': 'Dominica',
        'flag': '/src/assets/images/flags/dominica.svg',
        'rounded_flag': '/src/assets/images/round_flags/dominica.svg',
        'extension': '+1',
        'code': 'DM'
    },
    'dominican republic': {
        'name': 'Dominican Republic',
        'flag': '/src/assets/images/flags/dominican-republic.svg',
        'rounded_flag': '/src/assets/images/round_flags/dominican-republic.svg',
        'extension': '+1',
        'code': 'DO'
    },
    'east timor': {
        'name': 'East Timor',
        'flag': '/src/assets/images/flags/east-timor.svg',
        'rounded_flag': '/src/assets/images/round_flags/east-timor.svg',
        'extension': '+670',
        'code': 'TL'
    },
    'ecuador': {
        'name': 'Ecuador',
        'flag': '/src/assets/images/flags/ecuador.svg',
        'rounded_flag': '/src/assets/images/round_flags/ecuador.svg',
        'extension': '+593',
        'code': 'EC'
    },
    'el salvador': {
        'name': 'El Salvador',
        'flag': '/src/assets/images/flags/el-salvador.svg',
        'rounded_flag': '/src/assets/images/round_flags/salvador.svg',
        'extension': '+503',
        'code': 'SV'
    },
    'equatorial guinea': {
        'name': 'Equatorial Guinea',
        'flag': '/src/assets/images/flags/equatorial-guinea.svg',
        'rounded_flag': '/src/assets/images/round_flags/equatorial-guinea.svg',
        'extension': '+240',
        'code': 'GQ'
    },
    'eritrea': {
        'name': 'Eritrea',
        'flag': '/src/assets/images/flags/eritrea.svg',
        'rounded_flag': '/src/assets/images/round_flags/eritrea.svg',
        'extension': '+291',
        'code': 'ER'
    },
    'estonia': {
        'name': 'Estonia',
        'flag': '/src/assets/images/flags/estonia.svg',
        'rounded_flag': '/src/assets/images/round_flags/estonia.svg',
        'extension': '+372',
        'code': 'EE'
    },
    'ethiopia': {
        'name': 'Ethiopia',
        'flag': '/src/assets/images/flags/ethiopia.svg',
        'rounded_flag': '/src/assets/images/round_flags/ethiopia.svg',
        'extension': '+251',
        'code': 'ET'
    },
    'falkland islands': {
        'name': 'Falkland Islands',
        'flag': '/src/assets/images/flags/falkland-islands.svg',
        'rounded_flag': '/src/assets/images/round_flags/falkland-islands.svg',
        'extension': '+500',
        'code': 'FK'
    },
    'faroe islands': {
        'name': 'Faroe Islands',
        'flag': '/src/assets/images/flags/faroe-islands.svg',
        'rounded_flag': '/src/assets/images/round_flags/faroe-islands.svg',
        'extension': '+298',
        'code': 'FO'
    },
    'fiji': {
        'name': 'Fiji',
        'flag': '/src/assets/images/flags/fiji.svg',
        'rounded_flag': '/src/assets/images/round_flags/fiji.svg',
        'extension': '+679',
        'code': 'FJ'
    },
    'finland': {
        'name': 'Finland',
        'flag': '/src/assets/images/flags/finland.svg',
        'rounded_flag': '/src/assets/images/round_flags/finland.svg',
        'extension': '+358',
        'code': 'FI'
    },
    'france': {
        'name': 'France',
        'flag': '/src/assets/images/flags/france.svg',
        'rounded_flag': '/src/assets/images/round_flags/france.svg',
        'extension': '+33',
        'code': 'FR'
    },
    'french polynesia': {
        'name': 'French Polynesia',
        'flag': '/src/assets/images/flags/french-polynesia.svg',
        'rounded_flag': '/src/assets/images/round_flags/french-polynesia.svg',
        'extension': '+689',
        'code': 'PF'
    },
    'gabon': {
        'name': 'Gabon',
        'flag': '/src/assets/images/flags/gabon.svg',
        'rounded_flag': '/src/assets/images/round_flags/gabon.svg',
        'extension': '+241',
        'code': 'GA'
    },
    'gambia': {
        'name': 'Gambia',
        'flag': '/src/assets/images/flags/gambia.svg',
        'rounded_flag': '/src/assets/images/round_flags/gambia.svg',
        'extension': '+220',
        'code': 'GM'
    },
    'georgia': {
        'name': 'Georgia',
        'flag': '/src/assets/images/flags/georgia.svg',
        'rounded_flag': '/src/assets/images/round_flags/georgia.svg',
        'extension': '+995',
        'code': 'GE'
    },
    'germany': {
        'name': 'Germany',
        'flag': '/src/assets/images/flags/germany.svg',
        'rounded_flag': '/src/assets/images/round_flags/germany.svg',
        'extension': '+49',
        'code': 'DE'
    },
    'ghana': {
        'name': 'Ghana',
        'flag': '/src/assets/images/flags/ghana.svg',
        'rounded_flag': '/src/assets/images/round_flags/ghana.svg',
        'extension': '+233',
        'code': 'GH'
    },
    'gibraltar': {
        'name': 'Gibraltar',
        'flag': '/src/assets/images/flags/gibraltar.svg',
        'rounded_flag': '/src/assets/images/round_flags/gibraltar.svg',
        'extension': '+350',
        'code': 'GI'
    },
    'greece': {
        'name': 'Greece',
        'flag': '/src/assets/images/flags/greece.svg',
        'rounded_flag': '/src/assets/images/round_flags/greece.svg',
        'extension': '+30',
        'code': 'GR'
    },
    'greenland': {
        'name': 'Greenland',
        'flag': '/src/assets/images/flags/greenland.svg',
        'rounded_flag': '/src/assets/images/round_flags/greenland.svg',
        'extension': '+299',
        'code': 'GL'
    },
    'grenada': {
        'name': 'Grenada',
        'flag': '/src/assets/images/flags/grenada.svg',
        'rounded_flag': '/src/assets/images/round_flags/grenada.svg',
        'extension': '+1',
        'code': 'GD'
    },
    'guam': {
        'name': 'Guam',
        'flag': '/src/assets/images/flags/guam.svg',
        'rounded_flag': '/src/assets/images/round_flags/guam.svg',
        'extension': '+1',
        'code': 'GU'
    },
    'guatemala': {
        'name': 'Guatemala',
        'flag': '/src/assets/images/flags/guatemala.svg',
        'rounded_flag': '/src/assets/images/round_flags/guatemala.svg',
        'extension': '+502',
        'code': 'GT'
    },
    'guernsey': {
        'name': 'Guernsey',
        'flag': '/src/assets/images/flags/guernsey.svg',
        'rounded_flag': '/src/assets/images/round_flags/guernsey.svg',
        'extension': '+44',
        'code': 'GG'
    },
    'guinea': {
        'name': 'Guinea',
        'flag': '/src/assets/images/flags/guinea.svg',
        'rounded_flag': '/src/assets/images/round_flags/guinea.svg',
        'extension': '+224',
        'code': 'GN'
    },
    'guinea-bissau': {
        'name': 'Guinea-Bissau',
        'flag': '/src/assets/images/flags/guinea-bissau.svg',
        'rounded_flag': '/src/assets/images/round_flags/guinea-bissau.svg',
        'extension': '+245',
        'code': 'GW'
    },
    'guyana': {
        'name': 'Guyana',
        'flag': '/src/assets/images/flags/guyana.svg',
        'rounded_flag': '/src/assets/images/round_flags/guyana.svg',
        'extension': '+592',
        'code': 'GY'
    },
    'haiti': {
        'name': 'Haiti',
        'flag': '/src/assets/images/flags/haiti.svg',
        'rounded_flag': '/src/assets/images/round_flags/haiti.svg',
        'extension': '+509',
        'code': 'HT'
    },
    'honduras': {
        'name': 'Honduras',
        'flag': '/src/assets/images/flags/honduras.svg',
        'rounded_flag': '/src/assets/images/round_flags/honduras.svg',
        'extension': '+504',
        'code': 'HN'
    },
    'hong kong': {
        'name': 'Hong Kong',
        'flag': '/src/assets/images/flags/hong-kong.svg',
        'rounded_flag': '/src/assets/images/round_flags/hong-kong.svg',
        'extension': '+852',
        'code': 'HK'
    },
    'hungary': {
        'name': 'Hungary',
        'flag': '/src/assets/images/flags/hungary.svg',
        'rounded_flag': '/src/assets/images/round_flags/hungary.svg',
        'extension': '+36',
        'code': 'HU'
    },
    'iceland': {
        'name': 'Iceland',
        'flag': '/src/assets/images/flags/iceland.svg',
        'rounded_flag': '/src/assets/images/round_flags/iceland.svg',
        'extension': '+354',
        'code': 'IS'
    },
    'india': {
        'name': 'India',
        'flag': '/src/assets/images/flags/india.svg',
        'rounded_flag': '/src/assets/images/round_flags/india.svg',
        'extension': '+91',
        'code': 'IN'
    },
    'indonesia': {
        'name': 'Indonesia',
        'flag': '/src/assets/images/flags/indonesia.svg',
        'rounded_flag': '/src/assets/images/round_flags/indonesia.svg',
        'extension': '+62',
        'code': 'ID'
    },
    'iran': {
        'name': 'Iran',
        'flag': '/src/assets/images/flags/iran.svg',
        'rounded_flag': '/src/assets/images/round_flags/iran.svg',
        'extension': '+98',
        'code': 'IR'
    },
    'iraq': {
        'name': 'Iraq',
        'flag': '/src/assets/images/flags/iraq.svg',
        'rounded_flag': '/src/assets/images/round_flags/iraq.svg',
        'extension': '+964',
        'code': 'IQ'
    },
    'ireland': {
        'name': 'Ireland',
        'flag': '/src/assets/images/flags/ireland.svg',
        'rounded_flag': '/src/assets/images/round_flags/ireland.svg',
        'extension': '+353',
        'code': 'IE'
    },
    'isle of man': {
        'name': 'Isle of Man',
        'flag': '/src/assets/images/flags/isle-of-man.svg',
        'rounded_flag': '/src/assets/images/round_flags/isle-of-man.svg',
        'extension': '+44',
        'code': 'IM'
    },
    'israel': {
        'name': 'Israel',
        'flag': '/src/assets/images/flags/israel.svg',
        'rounded_flag': '/src/assets/images/round_flags/israel.svg',
        'extension': '+972',
        'code': 'IL'
    },
    'italy': {
        'name': 'Italy',
        'flag': '/src/assets/images/flags/italy.svg',
        'rounded_flag': '/src/assets/images/round_flags/italy.svg',
        'extension': '+39',
        'code': 'IT'
    },
    'ivory coast': {
        'name': 'Ivory Coast',
        'flag': '/src/assets/images/flags/ivory-coast.svg',
        'rounded_flag': '/src/assets/images/round_flags/ivory-coast.svg',
        'extension': '+225',
        'code': 'CI'
    },
    'jamaica': {
        'name': 'Jamaica',
        'flag': '/src/assets/images/flags/jamaica.svg',
        'rounded_flag': '/src/assets/images/round_flags/jamaica.svg',
        'extension': '+1',
        'code': 'JM'
    },
    'japan': {
        'name': 'Japan',
        'flag': '/src/assets/images/flags/japan.svg',
        'rounded_flag': '/src/assets/images/round_flags/japan.svg',
        'extension': '+81',
        'code': 'JP'
    },
    'jersey': {
        'name': 'Jersey',
        'flag': '/src/assets/images/flags/jersey.svg',
        'rounded_flag': '/src/assets/images/round_flags/jersey.svg',
        'extension': '+44',
        'code': 'JE'
    },
    'jordan': {
        'name': 'Jordan',
        'flag': '/src/assets/images/flags/jordan.svg',
        'rounded_flag': '/src/assets/images/round_flags/jordan.svg',
        'extension': '+962',
        'code': 'JO'
    },
    'kazakhstan': {
        'name': 'Kazakhstan',
        'flag': '/src/assets/images/flags/kazakhstan.svg',
        'rounded_flag': '/src/assets/images/round_flags/kazakhstan.svg',
        'extension': '+7',
        'code': 'KZ'
    },
    'kenya': {
        'name': 'Kenya',
        'flag': '/src/assets/images/flags/kenya.svg',
        'rounded_flag': '/src/assets/images/round_flags/kenya.svg',
        'extension': '+254',
        'code': 'KE'
    },
    'kiribati': {
        'name': 'Kiribati',
        'flag': '/src/assets/images/flags/kiribati.svg',
        'rounded_flag': '/src/assets/images/round_flags/kiribati.svg',
        'extension': '+686',
        'code': 'KI'
    },
    'kosovo': {
        'name': 'Kosovo',
        'flag': '/src/assets/images/flags/kosovo.svg',
        'rounded_flag': '/src/assets/images/round_flags/kosovo.svg',
        'extension': '+383',
        'code': 'XK'
    },
    'kuwait': {
        'name': 'Kuwait',
        'flag': '/src/assets/images/flags/kuwait.svg',
        'rounded_flag': '/src/assets/images/round_flags/kuwait.svg',
        'extension': '+965',
        'code': 'KW'
    },
    'kyrgyzstan': {
        'name': 'Kyrgyzstan',
        'flag': '/src/assets/images/flags/kyrgyzstan.svg',
        'rounded_flag': '/src/assets/images/round_flags/kyrgyzstan.svg',
        'extension': '+996',
        'code': 'KG'
    },
    'laos': {
        'name': 'Laos',
        'flag': '/src/assets/images/flags/laos.svg',
        'rounded_flag': '/src/assets/images/round_flags/laos.svg',
        'extension': '+856',
        'code': 'LA'
    },
    'latvia': {
        'name': 'Latvia',
        'flag': '/src/assets/images/flags/latvia.svg',
        'rounded_flag': '/src/assets/images/round_flags/latvia.svg',
        'extension': '+371',
        'code': 'LV'
    },
    'lebanon': {
        'name': 'Lebanon',
        'flag': '/src/assets/images/flags/lebanon.svg',
        'rounded_flag': '/src/assets/images/round_flags/lebanon.svg',
        'extension': '+961',
        'code': 'LB'
    },
    'lesotho': {
        'name': 'Lesotho',
        'flag': '/src/assets/images/flags/lesotho.svg',
        'rounded_flag': '/src/assets/images/round_flags/lesotho.svg',
        'extension': '+266',
        'code': 'LS'
    },
    'liberia': {
        'name': 'Liberia',
        'flag': '/src/assets/images/flags/liberia.svg',
        'rounded_flag': '/src/assets/images/round_flags/liberia.svg',
        'extension': '+231',
        'code': 'LR'
    },
    'libya': {
        'name': 'Libya',
        'flag': '/src/assets/images/flags/libya.svg',
        'rounded_flag': '/src/assets/images/round_flags/libya.svg',
        'extension': '+218',
        'code': 'LY'
    },
    'liechtenstein': {
        'name': 'Liechtenstein',
        'flag': '/src/assets/images/flags/liechtenstein.svg',
        'rounded_flag': '/src/assets/images/round_flags/liechtenstein.svg',
        'extension': '+423',
        'code': 'LI'
    },
    'lithuania': {
        'name': 'Lithuania',
        'flag': '/src/assets/images/flags/lithuania.svg',
        'rounded_flag': '/src/assets/images/round_flags/lithuania.svg',
        'extension': '+370',
        'code': 'LT'
    },
    'luxembourg': {
        'name': 'Luxembourg',
        'flag': '/src/assets/images/flags/luxembourg.svg',
        'rounded_flag': '/src/assets/images/round_flags/luxembourg.svg',
        'extension': '+352',
        'code': 'LU'
    },
    'macau': {
        'name': 'Macau',
        'flag': '/src/assets/images/flags/macao.svg',
        'rounded_flag': '/src/assets/images/round_flags/macao.svg',
        'extension': '+853',
        'code': 'MO'
    },
    'macedonia': {
        'name': 'Macedonia',
        'flag': '/src/assets/images/flags/republic-of-macedonia.svg',
        'rounded_flag': '/src/assets/images/round_flags/republic-of-macedonia.svg',
        'extension': '+389',
        'code': 'MK'
    },
    'madagascar': {
        'name': 'Madagascar',
        'flag': '/src/assets/images/flags/madagascar.svg',
        'rounded_flag': '/src/assets/images/round_flags/madagascar.svg',
        'extension': '+261',
        'code': 'MG'
    },
    'malawi': {
        'name': 'Malawi',
        'flag': '/src/assets/images/flags/malawi.svg',
        'rounded_flag': '/src/assets/images/round_flags/malawi.svg',
        'extension': '+265',
        'code': 'MW'
    },
    'malaysia': {
        'name': 'Malaysia',
        'flag': '/src/assets/images/flags/malaysia.svg',
        'rounded_flag': '/src/assets/images/round_flags/malaysia.svg',
        'extension': '+60',
        'code': 'MY'
    },
    'maldives': {
        'name': 'Maldives',
        'flag': '/src/assets/images/flags/maldives.svg',
        'rounded_flag': '/src/assets/images/round_flags/maldives.svg',
        'extension': '+960',
        'code': 'MV'
    },
    'mali': {
        'name': 'Mali',
        'flag': '/src/assets/images/flags/mali.svg',
        'rounded_flag': '/src/assets/images/round_flags/mali.svg',
        'extension': '+223',
        'code': 'ML'
    },
    'malta': {
        'name': 'Malta',
        'flag': '/src/assets/images/flags/malta.svg',
        'rounded_flag': '/src/assets/images/round_flags/malta.svg',
        'extension': '+356',
        'code': 'MT'
    },
    'marshall islands': {
        'name': 'Marshall Islands',
        'flag': '/src/assets/images/flags/marshall-island.svg',
        'rounded_flag': '/src/assets/images/round_flags/marshall-island.svg',
        'extension': '+692',
        'code': 'MH'
    },
    'mauritania': {
        'name': 'Mauritania',
        'flag': '/src/assets/images/flags/mauritania.svg',
        'rounded_flag': '/src/assets/images/round_flags/mauritania.svg',
        'extension': '+222',
        'code': 'MR'
    },
    'mauritius': {
        'name': 'Mauritius',
        'flag': '/src/assets/images/flags/mauritius.svg',
        'rounded_flag': '/src/assets/images/round_flags/mauritius.svg',
        'extension': '+230',
        'code': 'MU'
    },
    'mayotte': {
        'name': 'Mayotte',
        'flag': '/src/assets/images/flags/mayotte.svg',
        'rounded_flag': '/src/assets/images/round_flags/mayotte.svg',
        'extension': '+262',
        'code': 'YT'
    },
    'mexico': {
        'name': 'Mexico',
        'flag': '/src/assets/images/flags/mexico.svg',
        'rounded_flag': '/src/assets/images/round_flags/mexico.svg',
        'extension': '+52',
        'code': 'MX'
    },
    'micronesia': {
        'name': 'Micronesia',
        'flag': '/src/assets/images/flags/micronesia.svg',
        'rounded_flag': '/src/assets/images/round_flags/micronesia.svg',
        'extension': '+691',
        'code': 'FM'
    },
    'moldova': {
        'name': 'Moldova',
        'flag': '/src/assets/images/flags/moldova.svg',
        'rounded_flag': '/src/assets/images/round_flags/moldova.svg',
        'extension': '+373',
        'code': 'MD'
    },
    'monaco': {
        'name': 'Monaco',
        'flag': '/src/assets/images/flags/monaco.svg',
        'rounded_flag': '/src/assets/images/round_flags/monaco.svg',
        'extension': '+377',
        'code': 'MC'
    },
    'mongolia': {
        'name': 'Mongolia',
        'flag': '/src/assets/images/flags/mongolia.svg',
        'rounded_flag': '/src/assets/images/round_flags/mongolia.svg',
        'extension': '+976',
        'code': 'MN'
    },
    'montenegro': {
        'name': 'Montenegro',
        'flag': '/src/assets/images/flags/montenegro.svg',
        'rounded_flag': '/src/assets/images/round_flags/montenegro.svg',
        'extension': '+382',
        'code': 'ME'
    },
    'montserrat': {
        'name': 'Montserrat',
        'flag': '/src/assets/images/flags/montserrat.svg',
        'rounded_flag': '/src/assets/images/round_flags/montserrat.svg',
        'extension': '+1',
        'code': 'MS'
    },
    'morocco': {
        'name': 'Morocco',
        'flag': '/src/assets/images/flags/morocco.svg',
        'rounded_flag': '/src/assets/images/round_flags/morocco.svg',
        'extension': '+212',
        'code': 'MA'
    },
    'mozambique': {
        'name': 'Mozambique',
        'flag': '/src/assets/images/flags/mozambique.svg',
        'rounded_flag': '/src/assets/images/round_flags/mozambique.svg',
        'extension': '+258',
        'code': 'MZ'
    },
    'myanmar': {
        'name': 'Myanmar',
        'flag': '/src/assets/images/flags/myanmar.svg',
        'rounded_flag': '/src/assets/images/round_flags/myanmar.svg',
        'extension': '+95',
        'code': 'MM'
    },
    'namibia': {
        'name': 'Namibia',
        'flag': '/src/assets/images/flags/namibia.svg',
        'rounded_flag': '/src/assets/images/round_flags/namibia.svg',
        'extension': '+264',
        'code': 'NA'
    },
    'nauru': {
        'name': 'Nauru',
        'flag': '/src/assets/images/flags/nauru.svg',
        'rounded_flag': '/src/assets/images/round_flags/nauru.svg',
        'extension': '+674',
        'code': 'NR'
    },
    'netherlands': {
        'name': 'Netherlands',
        'flag': '/src/assets/images/flags/netherlands.svg',
        'rounded_flag': '/src/assets/images/round_flags/netherlands.svg',
        'extension': '+31',
        'code': 'NL'
    },
    'netherlands antilles': {
        'name': 'Netherlands Antilles',
        'flag': '/src/assets/images/flags/netherlands-antilles.svg',
        'rounded_flag': '/src/assets/images/round_flags/netherlands-antilles.svg',
        'extension': '+599',
        'code': 'AN'
    },
    'new caledonia': {
        'name': 'New Caledonia',
        'flag': '/src/assets/images/flags/new-caledonia.svg',
        'rounded_flag': '/src/assets/images/round_flags/new-caledonia.svg',
        'extension': '+687',
        'code': 'NC'
    },
    'new zealand': {
        'name': 'New Zealand',
        'flag': '/src/assets/images/flags/new-zealand.svg',
        'rounded_flag': '/src/assets/images/round_flags/new-zealand.svg',
        'extension': '+64',
        'code': 'NZ'
    },
    'nicaragua': {
        'name': 'Nicaragua',
        'flag': '/src/assets/images/flags/nicaragua.svg',
        'rounded_flag': '/src/assets/images/round_flags/nicaragua.svg',
        'extension': '+505',
        'code': 'NI'
    },
    'niger': {
        'name': 'Niger',
        'flag': '/src/assets/images/flags/niger.svg',
        'rounded_flag': '/src/assets/images/round_flags/niger.svg',
        'extension': '+227',
        'code': 'NE'
    },
    'nigeria': {
        'name': 'Nigeria',
        'flag': '/src/assets/images/flags/nigeria.svg',
        'rounded_flag': '/src/assets/images/round_flags/nigeria.svg',
        'extension': '+234',
        'code': 'NG'
    },
    'niue': {
        'name': 'Niue',
        'flag': '/src/assets/images/flags/niue.svg',
        'rounded_flag': '/src/assets/images/round_flags/niue.svg',
        'extension': '+683',
        'code': 'NU'
    },
    'north korea': {
        'name': 'North Korea',
        'flag': '/src/assets/images/flags/north-korea.svg',
        'rounded_flag': '/src/assets/images/round_flags/north-korea.svg',
        'extension': '+850',
        'code': 'KP'
    },
    'northern mariana islands': {
        'name': 'Northern Mariana Islands',
        'flag': '/src/assets/images/flags/northern-marianas-islands.svg',
        'rounded_flag': '/src/assets/images/round_flags/northern-marianas-islands.svg',
        'extension': '+1',
        'code': 'MP'
    },
    'norway': {
        'name': 'Norway',
        'flag': '/src/assets/images/flags/norway.svg',
        'rounded_flag': '/src/assets/images/round_flags/norway.svg',
        'extension': '+47',
        'code': 'NO'
    },
    'oman': {
        'name': 'Oman',
        'flag': '/src/assets/images/flags/oman.svg',
        'rounded_flag': '/src/assets/images/round_flags/oman.svg',
        'extension': '+968',
        'code': 'OM'
    },
    'pakistan': {
        'name': 'Pakistan',
        'flag': '/src/assets/images/flags/pakistan.svg',
        'rounded_flag': '/src/assets/images/round_flags/pakistan.svg',
        'extension': '+92',
        'code': 'PK'
    },
    'palau': {
        'name': 'Palau',
        'flag': '/src/assets/images/flags/palau.svg',
        'rounded_flag': '/src/assets/images/round_flags/palau.svg',
        'extension': '+680',
        'code': 'PW'
    },
    'palestine': {
        'name': 'Palestine',
        'flag': '/src/assets/images/flags/palestine.svg',
        'rounded_flag': '/src/assets/images/round_flags/palestine.svg',
        'extension': '+970',
        'code': 'PS'
    },
    'panama': {
        'name': 'Panama',
        'flag': '/src/assets/images/flags/panama.svg',
        'rounded_flag': '/src/assets/images/round_flags/panama.svg',
        'extension': '+507',
        'code': 'PA'
    },
    'papua new guinea': {
        'name': 'Papua New Guinea',
        'flag': '/src/assets/images/flags/papua-new-guinea.svg',
        'rounded_flag': '/src/assets/images/round_flags/papua-new-guinea.svg',
        'extension': '+675',
        'code': 'PG'
    },
    'paraguay': {
        'name': 'Paraguay',
        'flag': '/src/assets/images/flags/paraguay.svg',
        'rounded_flag': '/src/assets/images/round_flags/paraguay.svg',
        'extension': '+595',
        'code': 'PY'
    },
    'peru': {
        'name': 'Peru',
        'flag': '/src/assets/images/flags/peru.svg',
        'rounded_flag': '/src/assets/images/round_flags/peru.svg',
        'extension': '+51',
        'code': 'PE'
    },
    'philippines': {
        'name': 'Philippines',
        'flag': '/src/assets/images/flags/philippines.svg',
        'rounded_flag': '/src/assets/images/round_flags/philippines.svg',
        'extension': '+63',
        'code': 'PH'
    },
    'pitcairn': {
        'name': 'Pitcairn',
        'flag': '/src/assets/images/flags/pitcairn.svg',
        'rounded_flag': '/src/assets/images/round_flags/pitcairn-islands.svg',
        'extension': '+64',
        'code': 'PN'
    },
    'poland': {
        'name': 'Poland',
        'flag': '/src/assets/images/flags/republic-of-poland.svg',
        'rounded_flag': '/src/assets/images/round_flags/republic-of-poland.svg',
        'extension': '+48',
        'code': 'PL'
    },
    'portugal': {
        'name': 'Portugal',
        'flag': '/src/assets/images/flags/portugal.svg',
        'rounded_flag': '/src/assets/images/round_flags/portugal.svg',
        'extension': '+351',
        'code': 'PT'
    },
    'puerto rico': {
        'name': 'Puerto Rico',
        'flag': '/src/assets/images/flags/puerto-rico.svg',
        'rounded_flag': '/src/assets/images/round_flags/puerto-rico.svg',
        'extension': '+1',
        'code': 'PR'
    },
    'qatar': {
        'name': 'Qatar',
        'flag': '/src/assets/images/flags/qatar.svg',
        'rounded_flag': '/src/assets/images/round_flags/qatar.svg',
        'extension': '+974',
        'code': 'QA'
    },
    'republic of the congo': {
        'name': 'Republic of the Congo',
        'flag': '/src/assets/images/flags/republic-of-the-congo.svg',
        'rounded_flag': '/src/assets/images/round_flags/republic-of-the-congo.svg',
        'extension': '+242',
        'code': 'CG'
    },
    'reunion': {
        'name': 'Reunion',
        'flag': '/src/assets/images/flags/reunion.svg',
        'rounded_flag': '/src/assets/images/round_flags/reunion.svg',
        'extension': '+262',
        'code': 'RE'
    },
    'romania': {
        'name': 'Romania',
        'flag': '/src/assets/images/flags/romania.svg',
        'rounded_flag': '/src/assets/images/round_flags/romania.svg',
        'extension': '+40',
        'code': 'RO'
    },
    'rwanda': {
        'name': 'Rwanda',
        'flag': '/src/assets/images/flags/rwanda.svg',
        'rounded_flag': '/src/assets/images/round_flags/rwanda.svg',
        'extension': '+250',
        'code': 'RW'
    },
    'saint barthelemy': {
        'name': 'Saint Barthelemy',
        'flag': '/src/assets/images/flags/st-barts.svg',
        'rounded_flag': '/src/assets/images/round_flags/st-barts.svg',
        'extension': '+590',
        'code': 'BL'
    },
    'saint helena': {
        'name': 'Saint Helena',
        'flag': '/src/assets/images/flags/saint-helena.svg',
        'rounded_flag': '/src/assets/images/round_flags/saint-helena.svg',
        'extension': '+290',
        'code': 'SH'
    },
    'saint kitts and nevis': {
        'name': 'Saint Kitts and Nevis',
        'flag': '/src/assets/images/flags/saint-kitts-and-nevis.svg',
        'rounded_flag': '/src/assets/images/round_flags/saint-kitts-and-nevis.svg',
        'extension': '+1',
        'code': 'KN'
    },
    'saint lucia': {
        'name': 'Saint Lucia',
        'flag': '/src/assets/images/flags/st-lucia.svg',
        'rounded_flag': '/src/assets/images/round_flags/st-lucia.svg',
        'extension': '+1',
        'code': 'LC'
    },
    'saint martin': {
        'name': 'Saint Martin',
        'flag': '/src/assets/images/flags/sint-maarten.svg',
        'rounded_flag': '/src/assets/images/round_flags/sint-maarten.svg',
        'extension': '+590',
        'code': 'MF'
    },
    'saint pierre and miquelon': {
        'name': 'Saint Pierre and Miquelon',
        'flag': '/src/assets/images/flags/saint-pierre-and-miquelon.svg',
        'rounded_flag': '/src/assets/images/round_flags/saint-pierre-and-miquelon.svg',
        'extension': '+508',
        'code': 'PM'
    },
    'saint vincent and the grenadines': {
        'name': 'Saint Vincent and the Grenadines',
        'flag': '/src/assets/images/flags/st-vincent-and-the-grenadines.svg',
        'rounded_flag': '/src/assets/images/round_flags/st-vincent-and-the-grenadines.svg',
        'extension': '+1',
        'code': 'VC'
    },
    'samoa': {
        'name': 'Samoa',
        'flag': '/src/assets/images/flags/samoa.svg',
        'rounded_flag': '/src/assets/images/round_flags/samoa.svg',
        'extension': '+685',
        'code': 'WS'
    },
    'san marino': {
        'name': 'San Marino',
        'flag': '/src/assets/images/flags/san-marino.svg',
        'rounded_flag': '/src/assets/images/round_flags/san-marino.svg',
        'extension': '+378',
        'code': 'SM'
    },
    'sao tome and principe': {
        'name': 'Sao Tome and Principe',
        'flag': '/src/assets/images/flags/sao-tome-and-principe.svg',
        'rounded_flag': '/src/assets/images/round_flags/sao-tome-and-principe.svg',
        'extension': '+239',
        'code': 'ST'
    },
    'saudi arabia': {
        'name': 'Saudi Arabia',
        'flag': '/src/assets/images/flags/saudi-arabia.svg',
        'rounded_flag': '/src/assets/images/round_flags/saudi-arabia.svg',
        'extension': '+966',
        'code': 'SA'
    },
    'senegal': {
        'name': 'Senegal',
        'flag': '/src/assets/images/flags/senegal.svg',
        'rounded_flag': '/src/assets/images/round_flags/senegal.svg',
        'extension': '+221',
        'code': 'SN'
    },
    'serbia': {
        'name': 'Serbia',
        'flag': '/src/assets/images/flags/serbia.svg',
        'rounded_flag': '/src/assets/images/round_flags/serbia.svg',
        'extension': '+381',
        'code': 'RS'
    },
    'seychelles': {
        'name': 'Seychelles',
        'flag': '/src/assets/images/flags/seychelles.svg',
        'rounded_flag': '/src/assets/images/round_flags/seychelles.svg',
        'extension': '+248',
        'code': 'SC'
    },
    'sierra leone': {
        'name': 'Sierra Leone',
        'flag': '/src/assets/images/flags/sierra-leone.svg',
        'rounded_flag': '/src/assets/images/round_flags/sierra-leone.svg',
        'extension': '+232',
        'code': 'SL'
    },
    'singapore': {
        'name': 'Singapore',
        'flag': '/src/assets/images/flags/singapore.svg',
        'rounded_flag': '/src/assets/images/round_flags/singapore.svg',
        'extension': '+65',
        'code': 'SG'
    },
    'sint maarten': {
        'name': 'Sint Maarten',
        'flag': '/src/assets/images/flags/sint-maarten.svg',
        'rounded_flag': '/src/assets/images/round_flags/sint-maarten.svg',
        'extension': '+1',
        'code': 'SX'
    },
    'slovakia': {
        'name': 'Slovakia',
        'flag': '/src/assets/images/flags/slovakia.svg',
        'rounded_flag': '/src/assets/images/round_flags/slovakia.svg',
        'extension': '+421',
        'code': 'SK'
    },
    'slovenia': {
        'name': 'Slovenia',
        'flag': '/src/assets/images/flags/slovenia.svg',
        'rounded_flag': '/src/assets/images/round_flags/slovenia.svg',
        'extension': '+386',
        'code': 'SI'
    },
    'solomon islands': {
        'name': 'Solomon Islands',
        'flag': '/src/assets/images/flags/solomon-islands.svg',
        'rounded_flag': '/src/assets/images/round_flags/solomon-islands.svg',
        'extension': '+677',
        'code': 'SB'
    },
    'somalia': {
        'name': 'Somalia',
        'flag': '/src/assets/images/flags/somalia.svg',
        'rounded_flag': '/src/assets/images/round_flags/somalia.svg',
        'extension': '+252',
        'code': 'SO'
    },
    'south africa': {
        'name': 'South Africa',
        'flag': '/src/assets/images/flags/south-africa.svg',
        'rounded_flag': '/src/assets/images/round_flags/south-africa.svg',
        'extension': '+27',
        'code': 'ZA'
    },
    'south korea': {
        'name': 'South Korea',
        'flag': '/src/assets/images/flags/south-korea.svg',
        'rounded_flag': '/src/assets/images/round_flags/south-korea.svg',
        'extension': '+82',
        'code': 'KR'
    },
    'south sudan': {
        'name': 'South Sudan',
        'flag': '/src/assets/images/flags/south-sudan.svg',
        'rounded_flag': '/src/assets/images/round_flags/south-sudan.svg',
        'extension': '+211',
        'code': 'SS'
    },
    'spain': {
        'name': 'Spain',
        'flag': '/src/assets/images/flags/spain.svg',
        'rounded_flag': '/src/assets/images/round_flags/spain.svg',
        'extension': '+34',
        'code': 'ES'
    },
    'sri lanka': {
        'name': 'Sri Lanka',
        'flag': '/src/assets/images/flags/sri-lanka.svg',
        'rounded_flag': '/src/assets/images/round_flags/sri-lanka.svg',
        'extension': '+94',
        'code': 'LK'
    },
    'sudan': {
        'name': 'Sudan',
        'flag': '/src/assets/images/flags/sudan.svg',
        'rounded_flag': '/src/assets/images/round_flags/sudan.svg',
        'extension': '+249',
        'code': 'SD'
    },
    'suriname': {
        'name': 'Suriname',
        'flag': '/src/assets/images/flags/suriname.svg',
        'rounded_flag': '/src/assets/images/round_flags/suriname.svg',
        'extension': '+597',
        'code': 'SR'
    },
    'svalbard and jan mayen': {
        'name': 'Svalbard and Jan Mayen',
        'flag': '/src/assets/images/flags/svalbard-and-jan-mayen.svg',
        'rounded_flag': '/src/assets/images/round_flags/svalbard-and-jan-mayen.svg',
        'extension': '+47',
        'code': 'SJ'
    },
    'swaziland': {
        'name': 'Swaziland',
        'flag': '/src/assets/images/flags/swaziland.svg',
        'rounded_flag': '/src/assets/images/round_flags/swaziland.svg',
        'extension': '+268',
        'code': 'SZ'
    },
    'sweden': {
        'name': 'Sweden',
        'flag': '/src/assets/images/flags/sweden.svg',
        'rounded_flag': '/src/assets/images/round_flags/sweden.svg',
        'extension': '+46',
        'code': 'SE'
    },
    'switzerland': {
        'name': 'Switzerland',
        'flag': '/src/assets/images/flags/switzerland.svg',
        'rounded_flag': '/src/assets/images/round_flags/switzerland.svg',
        'extension': '+41',
        'code': 'CH'
    },
    'syria': {
        'name': 'Syria',
        'flag': '/src/assets/images/flags/syria.svg',
        'rounded_flag': '/src/assets/images/round_flags/syria.svg',
        'extension': '+963',
        'code': 'SY'
    },
    'taiwan': {
        'name': 'Taiwan',
        'flag': '/src/assets/images/flags/taiwan.svg',
        'rounded_flag': '/src/assets/images/round_flags/taiwan.svg',
        'extension': '+886',
        'code': 'TW'
    },
    'tajikistan': {
        'name': 'Tajikistan',
        'flag': '/src/assets/images/flags/tajikistan.svg',
        'rounded_flag': '/src/assets/images/round_flags/tajikistan.svg',
        'extension': '+992',
        'code': 'TJ'
    },
    'tanzania': {
        'name': 'Tanzania',
        'flag': '/src/assets/images/flags/tanzania.svg',
        'rounded_flag': '/src/assets/images/round_flags/tanzania.svg',
        'extension': '+255',
        'code': 'TZ'
    },
    'thailand': {
        'name': 'Thailand',
        'flag': '/src/assets/images/flags/thailand.svg',
        'rounded_flag': '/src/assets/images/round_flags/thailand.svg',
        'extension': '+66',
        'code': 'TH'
    },
    'togo': {
        'name': 'Togo',
        'flag': '/src/assets/images/flags/togo.svg',
        'rounded_flag': '/src/assets/images/round_flags/togo.svg',
        'extension': '+228',
        'code': 'TG'
    },
    'tokelau': {
        'name': 'Tokelau',
        'flag': '/src/assets/images/flags/tokelau.svg',
        'rounded_flag': '/src/assets/images/round_flags/tokelau.svg',
        'extension': '+690',
        'code': 'TK'
    },
    'tonga': {
        'name': 'Tonga',
        'flag': '/src/assets/images/flags/tonga.svg',
        'rounded_flag': '/src/assets/images/round_flags/tonga.svg',
        'extension': '+676',
        'code': 'TO'
    },
    'trinidad and tobago': {
        'name': 'Trinidad and Tobago',
        'flag': '/src/assets/images/flags/trinidad-and-tobago.svg',
        'rounded_flag': '/src/assets/images/round_flags/trinidad-and-tobago.svg',
        'extension': '+1',
        'code': 'TT'
    },
    'tunisia': {
        'name': 'Tunisia',
        'flag': '/src/assets/images/flags/tunisia.svg',
        'rounded_flag': '/src/assets/images/round_flags/tunisia.svg',
        'extension': '+216',
        'code': 'TN'
    },
    'turkey': {
        'name': 'Turkey',
        'flag': '/src/assets/images/flags/turkey.svg',
        'rounded_flag': '/src/assets/images/round_flags/turkey.svg',
        'extension': '+90',
        'code': 'TR'
    },
    'turkmenistan': {
        'name': 'Turkmenistan',
        'flag': '/src/assets/images/flags/turkmenistan.svg',
        'rounded_flag': '/src/assets/images/round_flags/turkmenistan.svg',
        'extension': '+993',
        'code': 'TM'
    },
    'turks and caicos islands': {
        'name': 'Turks and Caicos Islands',
        'flag': '/src/assets/images/flags/turks-and-caicos.svg',
        'rounded_flag': '/src/assets/images/round_flags/turks-and-caicos.svg',
        'extension': '+1',
        'code': 'TC'
    },
    'tuvalu': {
        'name': 'Tuvalu',
        'flag': '/src/assets/images/flags/tuvalu.svg',
        'rounded_flag': '/src/assets/images/round_flags/tuvalu.svg',
        'extension': '+688',
        'code': 'TV'
    },
    'u.s. virgin islands': {
        'name': 'U.S. Virgin Islands',
        'flag': '/src/assets/images/flags/virgin-islands.svg',
        'rounded_flag': '/src/assets/images/round_flags/virgin-islands.svg',
        'extension': '+1',
        'code': 'VI'
    },
    'uganda': {
        'name': 'Uganda',
        'flag': '/src/assets/images/flags/uganda.svg',
        'rounded_flag': '/src/assets/images/round_flags/uganda.svg',
        'extension': '+256',
        'code': 'UG'
    },
    'ukraine': {
        'name': 'Ukraine',
        'flag': '/src/assets/images/flags/ukraine.svg',
        'rounded_flag': '/src/assets/images/round_flags/ukraine.svg',
        'extension': '+380',
        'code': 'UA'
    },
    'united arab emirates': {
        'name': 'United Arab Emirates',
        'flag': '/src/assets/images/flags/united-arab-emirates.svg',
        'rounded_flag': '/src/assets/images/round_flags/united-arab-emirates.svg',
        'extension': '+971',
        'code': 'AE'
    },
    'united kingdom': {
        'name': 'United Kingdom',
        'flag': '/src/assets/images/flags/united-kingdom.svg',
        'rounded_flag': '/src/assets/images/round_flags/united-kingdom.svg',
        'extension': '+44',
        'code': 'GB'
    },
    'united states': {
        'name': 'United States',
        'flag': '/src/assets/images/flags/united-states-of-america.svg',
        'rounded_flag': '/src/assets/images/round_flags/united-states-of-america.svg',
        'extension': '+1',
        'code': 'US'
    },
    'uruguay': {
        'name': 'Uruguay',
        'flag': '/src/assets/images/flags/uruguay.svg',
        'rounded_flag': '/src/assets/images/round_flags/uruguay.svg',
        'extension': '+598',
        'code': 'UY'
    },
    'uzbekistan': {
        'name': 'Uzbekistan',
        'flag': '/src/assets/images/flags/uzbekistn.svg',
        'rounded_flag': '/src/assets/images/round_flags/uzbekistn.svg',
        'extension': '+998',
        'code': 'UZ'
    },
    'vanuatu': {
        'name': 'Vanuatu',
        'flag': '/src/assets/images/flags/vanuatu.svg',
        'rounded_flag': '/src/assets/images/round_flags/vanuatu.svg',
        'extension': '+678',
        'code': 'VU'
    },
    'vatican': {
        'name': 'Vatican',
        'flag': '/src/assets/images/flags/vatican-city.svg',
        'rounded_flag': '/src/assets/images/round_flags/vatican-city.svg',
        'extension': '+379',
        'code': 'VA'
    },
    'venezuela': {
        'name': 'Venezuela',
        'flag': '/src/assets/images/flags/venezuela.svg',
        'rounded_flag': '/src/assets/images/round_flags/venezuela.svg',
        'extension': '+58',
        'code': 'VE'
    },
    'vietnam': {
        'name': 'Vietnam',
        'flag': '/src/assets/images/flags/vietnam.svg',
        'rounded_flag': '/src/assets/images/round_flags/vietnam.svg',
        'extension': '+84',
        'code': 'VN'
    },
    'wallis and futuna': {
        'name': 'Wallis and Futuna',
        'flag': '/src/assets/images/flags/wallis-and-futuna.svg',
        'rounded_flag': '/src/assets/images/round_flags/wallis-and-futuna.svg',
        'extension': '+681',
        'code': 'WF'
    },
    'western sahara': {
        'name': 'Western Sahara',
        'flag': '/src/assets/images/flags/western-sahara.svg',
        'rounded_flag': '/src/assets/images/round_flags/western-sahara.svg',
        'extension': '+212',
        'code': 'EH'
    },
    'yemen': {
        'name': 'Yemen',
        'flag': '/src/assets/images/flags/yemen.svg',
        'rounded_flag': '/src/assets/images/round_flags/yemen.svg',
        'extension': '+967',
        'code': 'YE'
    },
    'zambia': {
        'name': 'Zambia',
        'flag': '/src/assets/images/flags/zambia.svg',
        'rounded_flag': '/src/assets/images/round_flags/zambia.svg',
        'extension': '+260',
        'code': 'ZM'
    },
    'zimbabwe': {
        'name': 'Zimbabwe',
        'flag': '/src/assets/images/flags/zimbabwe.svg',
        'rounded_flag': '/src/assets/images/round_flags/zimbabwe.svg',
        'extension': '+263',
        'code': 'ZW'
    }
};

export const banks = [
    'Al Baraka Bank',
    'Allied Bank',
    'Askari Bank',
    'Bank Al-Habib',
    'Bank Alfalah',
    'Bank of Khyber',
    'Bank of Punjab',
    'Bank of Tokyo-Mitsubishi',
    'BankIslami Pakistan',
    'Citi Bank',
    'Deutsche Bank',
    'Dubai Islamic Bank Pakistan',
    'Faysal Bank',
    'First Women Bank',
    'Habib Bank Limited.',
    'Habib Metropolitan Bank',
    'Industrial Development Bank of Pakistan',
    'Industrial and Commercial Bank of China',
    'JS Bank',
    'MCB Bank',
    'MCB Islamic Bank',
    'Meezan Bank',
    'NIB Bank Limited',
    'National Bank of Pakistan.',
    'Punjab Provincial Cooperative Bank',
    'S.M.E. Bank',
    'Samba Bank',
    'Silk Bank',
    'Sindh Bank',
    'Soneri Bank',
    'Standard Chartered Bank',
    'Summit Bank',
    'United Bank',
    'Zarai Taraqiati Bank',
    'Other'
];