// libraries
import './bootstrap/bootstrap';
import './bootstrap/bootstrap.scss';
import './assets/css/style.scss'
import './assets/js/google-tag-manager'
import './assets/js/trackers'
import 'babel-core/register'
import 'babel-polyfill'
import './assets/css/mockcheckout.scss'

// vue libraries
import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'

// internal libraries
import './assets/js/utilities'
import {goToPage, ClickEvent, ScrollToEvent, StartAnalytics} from './assets/js/analytics'

// vue components
import Landing1 from './Landing1.vue'
import Landing2 from './Landing2.vue'
import Landing3 from './Landing3.vue'
import ContactUs from './ContactUs.vue'
import WereHiring from './Hiring.vue'
import AboutUs from './AboutUs.vue'
import Account from './account/Account.vue'
import Login from './Login.vue'
import CheckoutFlow from './checkout/CheckoutFlow.vue'
import Banks from './checkout/Banks.vue'
import Thanks from './checkout/Thanks.vue'
import Wrong from './checkout/Wrong.vue'
import UpdateAccount from './checkout/UpdateAccount.vue'
import MockCheckoutPage from "./checkout/MockCheckoutPage.vue";

Vue.use(VueResource);
Vue.use(VueObserveVisibility);
Vue.use(VueRouter);
Vue.use(VueHead);

let url = "https://api.ipstack.com/check?access_key=da3f35fa68c0f5060b145b58ce6dc5e7";
let version = 2;

Object.defineProperty(Vue.prototype, '$goToPage', { value: goToPage });
Object.defineProperty(Vue.prototype, '$clickEvent', { value: ClickEvent });
Object.defineProperty(Vue.prototype, '$scrollEvent', { value: ScrollToEvent });

StartAnalytics(Vue, null, null);
function load_page(is_page_visible) {
  if (document.getElementById("app")) {
    let component = Landing3;
    if (is_page_visible && version === 2) {
      component = Landing2;
    } else if (is_page_visible) {
      component = Landing1;
    }
    new Vue({
      el: '#app',
      render: h => h(component),
      created() {
      }
    })
  }
  else if (document.getElementById("contact-us-app")) {
    let component = Landing3;
    if (is_page_visible) component = ContactUs;
    new Vue({
      el: '#contact-us-app',
      render: h => h(component)
    });
  }
  else if (document.getElementById("were-hiring-app")) {
    let component = Landing3;
    if (is_page_visible) component = WereHiring;
    new Vue({
      el: '#were-hiring-app',
      render: h => h(component)
    });
  }
  else if (document.getElementById("about-us-app")) {
    let component = Landing3;
    if (is_page_visible) component = AboutUs;
    new Vue({
      el: '#about-us-app',
      render: h => h(component)
    });
  }
  else if (document.getElementById("update-card-app")) {
    new Vue({
      el: '#update-card-app',
      render: h => h(Account)
    });
  }
  else if (document.getElementById("login-app")) {
    new Vue({
      el: '#login-app',
      render: h => h(Login)
    });
  }
  else if (document.getElementById("checkout-app")) {
    const routes = [
      {
        path: '/',
        component: CheckoutFlow,
        children: [
          {
            path: 'cart',
            component: MockCheckoutPage,
            meta: {scrollToTop: true}
          },
          {
            path: 'banks',
            component: Banks,
            meta: {scrollToTop: true}
          },
          {
            path: 'thank-you',
            component: Thanks,
            meta: {scrollToTop: true}
          },
          {
            path: '500',
            component: Wrong,
            meta: {scrollToTop: true}
          },
          {
            path: '401',
            component: Wrong,
            meta: {scrollToTop: true}
          },
          {
            path: 'update_credentials',
            component: UpdateAccount,
            meta: {scrollToTop: true}
          }
        ]
      },
    ];

    const router = new VueRouter({
      routes: routes,
      scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
      }
    });

    new Vue({
      router,
      el: '#checkout-app',
    });
  }
}

if (version === 1) {
  load_page(true)
}
else {
  Vue.http
  .get(url, { timeout: 60000 })
  .then(response => response.json())
  .then(json => {
    const countries = [
      "canada", "japan", "israel", "hong kong", "indonesia", "malaysia", "philippines", "singapore", "taiwan", "thailand", "vietnam", "india", "andorra", "austria", "belgium", "bulgaria", "cyprus", "czechia", "denmark", "estonia", "finland", "france", "germany", "greece", "hungary", "iceland", "ireland", "italy", "latvia", "liechtenstein", "lithuania", "luxembourg", "malta", "monaco", "netherlands", "norway", "poland", "portugal", "romania", "slovakia", "spain", "sweden", "switzerland", "turkey", "united kingdom", "argentina", "bolivia", "brazil", "chile", "colombia", "costa rica", "dominican republic", "ecuador","el salvador", "guatemala", "honduras", "mexico", "nicaragua", "panama", "paraguay", "peru", "uruguay", "united states", "algeria", "egypt", "morocco", "south africa", "tunisia", "australia", "new zealand", "bahrain", "jordan", "kuwait", "lebanon", "oman", "palestine", "qatar"
    ];
    const is_page_visible = !countries.includes(json.country_name.toLowerCase());
    load_page(is_page_visible)
  }).catch((error) => {
  load_page(false)
});
}
