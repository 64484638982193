import {urlParam} from "./utilities";
import Cookies from './cookie'


export const isDev = location.hostname !== "chillabitio.com";

export function getPageLocation(location) {
    if (location.indexOf('.html') > -1 || location.indexOf('http') > -1) {
        return location;
    }
    if (location === 'checkout' || location === 'checkoutExisting') {
        let fingerprint = new Fingerprint();
        fingerprint.loadFingerprint();
        let param = fingerprint.serializerForUrlParam(location);
        if (isDev) {
            console.log(param);
            return 'http://beta.chillabit.io/check.html?' + param;
        }
        return 'https://chillabit.io/check.html?' + param;
    }

    if (location === 'account') {
        if (isDev) {
            return 'http://beta.chillabit.io/check.html';
        }
        return 'https://chillabit.io/check.html';
    }
    const blog_route = "https://love-music.gitbook.io";
    return {
        index: "index.html",
        indexFeatures : "index.html#features",
        indexHowItWorks : "index.html#how-it-works",
        about: "about.html",
        cart: "checkout.html#/cart",
        thankyou: "checkout.html#/thank-you",
        banks: "checkout.html#/banks",
        checkout500: "checkout.html#/500",
        checkout401: "checkout.html#/401",
        contact: "contact-us.html",
        hiring: "were-hiring.html",
        faq: blog_route,
        login: "login.html",
        account: "account.html",
        apk: blog_route + "/how-to-install-spotify-in-any-country-for-android/",
        iphone: blog_route + "/how-to-install-spotify-in-any-country-for-iphone-or-ipad/",
        video_us : "https://www.youtube.com/watch?v=Z_wMzG8_IOw",
        video_alexa : "https://www.youtube.com/watch?v=Ip9i4r73jrk",
        video_android: "https://www.youtube.com/watch?v=UlljcTvHjYM",
        video_google_home: "https://www.youtube.com/watch?v=sdLZjRu75z4&t=4s",
        reviews: "https://www.facebook.com/pg/chillabeat.spotifypremium/reviews/",
        android_tutorial: blog_route + "/how-to-install-spotify-in-any-country-for-android/",
        iphone_tutorial: blog_route + "/how-to-install-spotify-in-any-country-for-iphone-or-ipad/",
        desktop_tutorial: blog_route + "/how-to-install-spotify-in-any-country-for-mac-and-windows/",
        blog: blog_route,
    }[location]
}

export class Fingerprint {
    constructor() {
        this._country_code = "";
        this._region_name = "";
        this._name = "";
        this._city = "";
        this._country_name = "";
        this._utm_source = urlParam('utm_source');
        this._utm_campaign = urlParam('utm_campaign');
        this._utm_content = urlParam('utm_content');
        this._referer = document.referrer;
        this._auth_token = "";
        this._send_conversion_pixel = null;
        this._ip = "";
        this._expiry="";
        this._authorization_string="";
        this._destination="";
        this._amplitude="";
    }

    get amplitude() {
        if (this._amplitude === "") {
            let temp = Cookies.get('_amplitude');
            if (temp !== undefined && temp !== null) {
                this._amplitude = temp;
            }
        }
        return this._amplitude;
    }

    set amplitude(amplitude_id) {
        Cookies.set('_amplitude', amplitude_id);
        this._amplitude = amplitude_id;
    }

    isValidParamFingerprint() {
        if (this._authorization_string === 'MQXTTWYL€') {
            return true;
        }
        return false;
    }

    get destination() {
        return this._destination;
    }

    get expiry() {
        return this._expiry;
    }

    get authorization_string() {
        return this._authorization_string;
    }

    get auth_token() {
        if (this._auth_token === null || this._auth_token === undefined || this._auth_token === "" || this._auth_token === "null" || this._auth_token === "undefined") {
            return null;
        }
        return this._auth_token;
    }

    get ip() {
        return this._ip;
    }

    get send_conversion_pixel() {
        if (this._send_conversion_pixel === "" || this._send_conversion_pixel === "null" ||this._send_conversion_pixel === "undefined" ||
            this._send_conversion_pixel === null || this._send_conversion_pixel === undefined)
            return true;
        return false;
    }

    get country_code() {
        return this._country_code;
    }

    get name() {
        if (this._name === "" || this._name === "null" ||this._name === "undefined")
            return null;
        return this._name;
    }

    get country_name() {
        return this._country_name;
    }

    get region_name() {
        return this._region_name;
    }

    get city() {
        return this._city;
    }

    get referer() {
        if (this._referer === "" || this._referer === "null" ||this._referer === "undefined")
            return null;
        return this._referer;
    }

    get utm_source() {
        if (this._utm_source === "" || this._utm_source === "null" ||this._utm_source === "undefined")
            return null;
        return this._utm_source;
    }

    get utm_content() {
        if (this._utm_content === ""|| this._utm_content === "null" ||this._utm_content === "undefined")
            return null;

        return this._utm_content;
    }

    get utm_campaign() {
        if (this._utm_campaign === ""|| this._utm_campaign === "null" ||this._utm_campaign === "undefined")
            return null;

        return this._utm_campaign;
    }

    isExpired() {
        if (this.expiry !== "" && this.expiry !== undefined && this.expiry !== null) {
            if (this.expiry < Math.floor(Date.now() / 1000)) {
                return true
            }
        }
        else if(this.expiry === "" && this.expiry === undefined && this.expiry === null) {
            return true
        }
        return false
    }

    serializerForUrlParam(destination) {
        // expiry in seconds is one week from time of encoding
        this._destination = destination;
        this._authorization_string = 'MQXTTWYL€';
        this.saveFingerprint();

        this._expiry = Math.floor(Date.now() / 1000) + (60 * 60 * 24 * 7);
        let params = jQuery.param(this);
        let encoded_params = this.b64EncodeUnicode(params);
        console.log(encoded_params);
        return encoded_params;
    }

    loadFromUrlParam(param) {
        let decoded_params = this.b64DecodeUnicode(param);
        let obj = this.getObjectFromParams(decoded_params);

        if (obj === undefined || obj === null) {
            return false;
        }

        this.loadFromData(obj);
        return true;
    }

    b64EncodeUnicode(str) {
        return encodeURIComponent(btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode(parseInt(p1, 16))
        })))
    }

    b64DecodeUnicode(m) {
        m = decodeURIComponent(m)
        return decodeURIComponent(Array.prototype.map.call(atob(m), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }

    getObjectFromParams(str) {
        return str.split('&').reduce(function (params, param) {
            var paramSplit = param.split('=').map(function (value) {
                return decodeURIComponent(value.replace(/\+/g, ' '));
            });
            params[paramSplit[0]] = paramSplit[1];
            return params;
        }, {});
    }

    loadFromData(data) {
        if (data === undefined || data === null) {
            return;
        }

        Object.keys(data).forEach(element => {
            if (data[element] !== "" && data[element] !== undefined && data[element] !== null) {
                if (data[element] === "null") {
                    this[element] = null;
                }
                else {
                    this[element] = data[element];
                }
            }
        });
        this.saveFingerprint();
    }

    saveFingerprint() {
        Object.keys(this).forEach(element => {
            Cookies.set(element, this[element], {expires: 7});
        });
    }

    loadFingerprint() {
        Object.keys(this).forEach(element => {
            let old = Cookies.get(element, null);
            if (!this[element])
                this[element] = old;
        });
    }

    setCountryData(data) {
        this._country_code = data.country_code;
        this._country_name = data.country_name;
        this._region_name = data.region_name;
        this._city = data.city;
        this._ip = data.ip;
        this.saveFingerprint();
    }

    setName(name) {
        this._name = name;
    }

    setAuthToken(token) {
        this._auth_token = token;
        this.saveFingerprint();
    }

    setSendConversionPixel() {
        this._send_conversion_pixel = false;
        this.saveFingerprint();
    }

    getCountryDataFromIP() {
        $.getJSON('http://api.ipstack.com/check?access_key=da3f35fa68c0f5060b145b58ce6dc5e7', (data) => {
            setCountryData(data);
        });
    }
}

export function getEndpoint(endpoint) {
    if (!isDev)
        return endpoints[endpoint];
    return dev_endpoints[endpoint];
}

export function getStripeToken() {
    if (!isDev)
        return 'pk_live_LHgFQ6RpiQxnWWG2QT3b1djQ';
    return 'pk_test_YgHkU36pDk02BPTETrDdkqCc';
}

const endpoints = {
    stripe : "https://prod.chillabit.io/v1/pay/stripe/",
    account : "https://prod.chillabit.io/v1/user/account/",
    lead : "https://prod.chillabit.io/v1/lead/",
    register : "https://prod.chillabit.io/v1/auth/",
    subscription : "https://prod.chillabit.io/v1/pay/subscription_data/",
    update : "https://prod.chillabit.io/v1/pay/stripe_update/",
    login : "https://prod.chillabit.io/v1/auth/magic_link/",
    logout : "https://prod.chillabit.io/v1/user/logout/",
    banks : 'https://prod.chillabit.io/v1/user/bank/',
    submit_details : "https://prod.chillabit.io/v1/user/submit_details/",
    send_welcome_email : "https://prod.chillabit.io/v1/user/send_welcome_email/",
    change_email : "https://prod.chillabit.io/v1/user/change_email/"
};

const dev_endpoints = {
    stripe : "https://test.chillabit.io/v1/pay/stripe/",
    account : "https://test.chillabit.io/v1/user/account/",
    lead : "https://test.chillabit.io/v1/lead/",
    register : "https://test.chillabit.io/v1/auth/",
    subscription : "https://test.chillabit.io/v1/pay/subscription_data/",
    update : "https://test.chillabit.io/v1/pay/stripe_update/",
    login : "https://test.chillabit.io/v1/auth/magic_link/",
    logout : "https://test.chillabit.io/v1/user/logout/",
    banks : 'https://test.chillabit.io/v1/user/bank/',
    submit_details : "https://test.chillabit.io/v1/user/submit_details/",
    send_welcome_email : "https://test.chillabit.io/v1/user/send_welcome_email/",
    change_email: "https://test.chillabit.io/v1/user/change_email/"
};
