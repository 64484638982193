<template>
    <div id="beats" class="row base">
        <div class="col-lg-7 ml-lg-3 mr-lg-5">

            <!-- receipt for small screens -->
            <div class="mb-4 d-lg-none d-block mt-5">
                <receipt :isExisting="this.is_existing"></receipt>
            </div>

            <account-select :is_existing=this.is_existing @NEW_ACCOUNT_TYPE="setIsExisting(false)" @EXISTING_ACCOUNT_TYPE="setIsExisting(true)"></account-select>
            <lead-submission @LEAD_INCOMPLETE="clearLeadData()" @LEAD_COMPLETE="setLeadData($event)"></lead-submission>

            <base-card class="payment-card" :class="this.lead_data !== null ? 'active-card' : 'inactive-card'">
                <div v-if="this.lead_data == null" class="small-padding">
                    <h6>PAYMENT</h6>
                </div>
                <braintree-form ref="braintree_form" :server_errors="this.server_errors" :is_submitting="this.is_submitting" :checkout_button_text="this.checkout_button_text" @isSubmitting="setSubmitting()" @braintreeTokenEvent="braintreeTokenResult($event)" v-show="this.lead_data !== null">

                </braintree-form>
            </base-card>

            <p class="mt-3 mb-4 text-center" style="color: #aaa; font-weight: 300; font-size: 0.75rem;">
                By placing your order you agree to our
                    <a href="#">Terms and Conditions</a>
                    <a href="#" @click.prevent>Privacy Policy</a>
            </p>

            <!-- faq for small screens -->
            <div class="d-lg-none d-block">
                <faq :isExisting="this.is_existing"></faq>
            </div>

        </div>
        <div class="col-lg-4 d-none d-lg-block pr-0">
            <receipt :isExisting="this.is_existing"></receipt>
            <faq :isExisting="this.is_existing"></faq>
        </div>
    </div>
</template>

<script>
    import {urlParam} from '../assets/js/utilities'
    import {getPageLocation,getEndpoint, isDev} from '../assets/js/globals'

    import AccountSelect from './MockAccountSelect.vue'
    import LeadSubmission from './MockLeadSubmission.vue'
    import Receipt from './MockReceipt.vue'
    import Faq from './MockFaq.vue'
    import BaseCard from '../components/BaseCard.vue'
    import BraintreeForm from './MockCreditCardForm.vue'
    import SubmissionButton from '../components/forms/SubmissionButton.vue'

    export default {
        data() {
            return {
                // cc fields
                server_errors: "",
                lead_data: null,
                is_submitting: false,
                is_existing: false,
                plan_type: "monthly_international_cad",
                captcha : null,
            }
        },
        components: {
            LeadSubmission,
            AccountSelect,
            Receipt,
            Faq,
            BaseCard,
            BraintreeForm,
            SubmissionButton,
        },
        methods: {
            clearLeadData() {
                this.lead_data = null;
            },
            setLeadData(e) {
                this.lead_data = e;
            },
            setIsExisting(bool) {
              this.is_existing = bool;
            },
            braintreeTokenResult(e) {
                if (e['error'] === null && e['nonce'] !== null) {
                    this.server_errors = "";
                    grecaptcha.execute('6LcwVa8UAAAAAFU7Xap6xReoT8nkncKXvBJ69tvd').then((token) => {
                      this.captcha = token;
                      this.submitPayment(e['nonce'], e['type']);
                    });
                    return;
                }

                this.is_submitting = false;
                this.server_errors = e['error'];
            },
            setSubmitting() {
                this.is_submitting=true;
            },
            submitPayment(nonce, payment_method) {
                let data = {
                    "nonce": nonce,
                    "payment_method" : payment_method,
                    "plan_id" : this.plan_type,
                    "device_identifier": this.lead_data["device_identifier"],
                    "order_details" : {'hook_up_existing' : this.is_existing},
                    "captcha": this.captcha
                };

                $.ajax({
                    context: this,
                    url: getEndpoint('braintree'),
                    headers: { 'Authorization': 'Token ' + this.lead_data['token']},
                    data: JSON.stringify(data),
                    type: 'POST',
                    dataType: 'json',
                    contentType: "application/json"
                }).done(() => {
                    console.log("SUCCESS");


                    if (!isDev) {

                    }

                  setTimeout(function() {
                    let url = getPageLocation('thankyou');
                    window.location.href = getPageLocation('thankyou');
                  }, 500);

                }).fail((data) => {
                    this.server_errors = data.responseText.replace (/(^")|("$)/g, '');
                    let event = new SubscriptionErrorEvent(this.server_errors);
                    event.sendEvent();
                    this.is_submitting = false;
                });
            }
        },
        computed: {
            checkout_button_text() {
                if (this.is_submitting)
                    return "";

                if (this.is_existing) {
                    return "UPGRADE MY ACCOUNT";
                }
                return "GET MY ACCOUNT";
            },
        },

        created() {
            let param = urlParam('plan');
            if (param !== null && param === 'yearly') {
                this.plan_type = 'yearly_international_cad';
            }
            let param2 = urlParam('type');
            if (param2 !== null && param2 === 'existing') {
                this.is_existing = true;
            }
        },
    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors';

    .payment-card {
        max-height: 1000px;
        overflow: hidden;
        transition: max-height 500ms linear;
    }

    .receipt-small {
        margin-top: -5rem;
    }

    @media (min-width: 1000px) {
        .base {
            margin-right: -1.2rem !important;
        }
    }


</style>
