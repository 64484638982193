<template>
    <div>
        <div class="container p-4">
            <contact-us-navbar isLanding=false navbarClasses="navbar-light" logoImage="src/assets/images/logos/logo-black.svg"></contact-us-navbar>
        </div>

        <div class="container-fluid section contact-us-section">
            <div class="container">
                <div class="row pt-4 pb-1">
                    <div class="col text-center mt-5 mb-5">
                        <h1 class="inverse-color ">Contact Us</h1>
                        <h4 class="inverse-color">We're always happy to hear from you</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="container section">
            <div class="col-12 text-center">
                <h2>Get all the answers in one place</h2>
                <h4>If you have any questions, a good first stop is our FAQ</h4>
            </div>

            <div class="row mt-5">
                <div class="col text-center mt-3">
                    <button type="button" class="btn btn-secondary">See our FAQ</button>
                </div>
            </div>
        </div>

        <hr>

        <div class="container section">
            <div class="col-12 text-center">
                <h2>Reach the Chillabitio team</h2>
            </div>

            <div class="row mt-5">
                <div class="col-xl-6 col-lg-12 text-center info-col mb-sm-5 mb-xl-0">
                    <img class="img-fluid" style="max-width: 20rem"  src="./assets/images/chat.svg">
                    <p class="ml-3 mr-3">Start a chat with one of our customer support agents right now</p>
                    <button type="button" @click="openLiveChat()" class="mt-3 btn btn-primary">Live chat</button>
                </div>
                <div class="col-xl-6 col-lg-12 text-center info-col mt-sm-5 mt-xl-0" style="margin-bottom: 0px !important;">
                    <img class="img-fluid" style="max-width: 20rem"  src="./assets/images/email-1.svg">
                    <p class="ml-3 mr-3">Email us at (<a class="mail-link" href="mailto:support@chillabitio.com">support@chillabitio.com</a>) and we will get back to you within 24 hours</p>
                    <a href="mailto:support@chillabitio.com"><button type="button" class="mt-3 btn btn-primary">Send email</button></a>
                </div>
            </div>
        </div>

        <hr>

        <div class="container section">
            <div class="col-12 text-center">
                <h2>Looking for something else?</h2>
                <h4>Use these links to get in contact with the right person</h4>
            </div>
            <br>
            <div class="row mt-5">
                <div class="col-xl-6 col-lg-12 text-center info-col mb-sm-5 mb-xl-0">
                    <h5>Business development</h5>
                    <p class="ml-3 mr-3">Want to chat about partnerships, projects or collaboration? Email us at <a class="mail-link" href="mailto:support@chillabitio.com">business@chillabitio.com</a></p>
                </div>
                <div class="col-xl-6 col-lg-12 text-center info-col mt-sm-5 mt-xl-0">
                    <h5>Affiliate programs</h5>
                    <p class="ml-3 mr-3">Like our product and what to help spread the word? We offer an incentivized brand ambassador program. Email us at <a class="mail-link" href="mailto:support@chillabitio.com">affiliates@chillabitio.com</a></p>
                </div>
            </div>
            <div class="row mt-md-0 mt-lg-5">
                <div class="col-xl-6 col-lg-12 text-center info-col mb-sm-5 mb-xl-0">
                    <h5>Press</h5>
                    <p class="ml-3 mr-3">Working on a story? Believe that music shouldn't have borders? Email us at <a class="mail-link" href="mailto:support@chillabitio.com">press@chillabitio.com</a></p>
                </div>
                <div class="col-xl-6 col-lg-12 text-center info-col mt-sm-5 mt-xl-0">
                    <h5>Careers</h5>
                    <p class="ml-3 mr-3">Want to join our team of passionate music enthusiasts? Email us at <a class="mail-link" href="mailto:support@chillabitio.com">hiring@chillabitio.com</a></p>
                </div>
            </div>
        </div>

        <contact-us-footer></contact-us-footer>
    </div>
</template>

<script>
    import Navbar from './Navbar.vue'
    import Footer from './Footer.vue'
    import MixinHead from "./assets/js/mixin_head.js"

    export default {
        mixins: [MixinHead],
        components: {
            contactUsNavbar: Navbar,
            contactUsFooter: Footer
        },
        methods: {
            openLiveChat() {
                Beacon("open");
            },
        }
    }
</script>

<style scoped>
    .contact-us-section {
        padding-top: 2rem;
        background-size:cover;
        background-position-x: 40%;
        background-image: url('./assets/images/contactus-background.png');
        padding-bottom: 60px;
    }

    h1.inverse-color, h2.inverse-color, h3.inverse-color, h4.inverse-color, span.inverse-color, p.inverse-color, col.inverse-color {
        color: white !important;
    }

    .info-col > img {
        margin-bottom: 1.4rem;
    }

    .info-col > span, .info-col > p {
        color: #73757a;
        font-weight: 400;
        margin-bottom: 0.5rem;
    }


    .info-col {
        margin-bottom: 5rem;
    }

    .mail-link {
        color: #4a90e2;
    }
</style>