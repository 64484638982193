<template>
    <component-page>
        <!-- NAVBAR -->
        <div slot="navbar">
            <component-navbar description="SECURE SITE"></component-navbar>
        </div>

        <!-- CONTENT -->
        <div slot="content">

            <component-form :error_message="this.error_message" :success_message="this.success_message">
                <div slot="form">

                    <component-card>
                        <div slot="content">
                            <h3>LOGIN TO YOUR ACCOUNT</h3>

                            <p>Your login session has expired. Please enter your email and we will send you a link to login.</p>


                            <component-inline-field>
                                <div slot="field">
                                    <input v-model="email" placeholder="you@example.com" class="form-control placeholder field-outline"/>
                                </div>
                                <div slot="button">
                                    <submission-button @submissionWasClicked="submitEmailData()"
                                                       :is_submitting="this.is_submitting"
                                                       buttonText="Get Login Link"
                                                       classes="btn-sm btn-primary"
                                                       spinner_classes=""
                                                       :section="this.section"></submission-button>
                                </div>
                            </component-inline-field>

                        </div>
                    </component-card>

                </div>
            </component-form>


        </div>

    </component-page>
</template>

<script>
    import ComponentPage from './Page.vue'
    import ComponentCard from './components/Card.vue'
    import ComponentNavbar from './checkout/Navbar.vue'
    import ComponentInlineField from './forms/InlineField.vue'
    import ClickAnalytics from './analytics/Click.vue'
    import SubmissionButton from './forms/SubmissionButton.vue'
    import ComponentForm from './forms/Form.vue'
    import {getEndpoint, getPageLocation, Fingerprint} from './assets/js/globals'
    import {validateEmail} from './assets/js/utilities'
    import MixinHead from "./assets/js/mixin_head.js"

    export default {
        mixins: [MixinHead],
        data() {
          return {
              email: '',
              error_message: '',
              success_message: '',
              submit_button_text: 'Get Login Link',
              section: 'Login',
              is_submitting: false,
              fingerprint: null,
          }
        },
        components: {
            ComponentNavbar,
            ComponentCard,
            ComponentPage,
            ComponentInlineField,
            SubmissionButton,
            ComponentForm
        },
        methods: {
            submitEmailData() {
                if (!validateEmail(this.email)) {
                    this.error_message = 'Enter a valid email address.';
                    return;
                }

                this.is_submitting = true;
                $.ajax({
                    context: this,
                    url: getEndpoint('login'),
                    type: "POST",
                    contentType: "application/json",
                    data: JSON.stringify({
                        'email': this.email
                    })
                }).done(() => {
                    this.error_message = null;
                    this.success_message = "We've sent an email to " + this.email + " with your login link! Please check your email to login.";
                    this.email = ''
                }).
                fail((data, status, error) => {
                    console.log(data);
                    if (data['status'] === 404) {
                        this.error_message ="We couldn't find an account associated with that email address. If you forgot your email or are having problems please contract us by email or by live chat.";
                    } else {
                        this.error_message = data['responseText'].replace (/(^")|("$)/g, '');
                    }
                    this.success_message = null;
                }).
                always((data) => {
                    console.log("COMPLETE");
                    this.is_submitting = false;
                });
            }
        },
        computed : {
            is_page_loading() {
                if (this.api_status === 0 || this.stripe_is_loading) {
                    return true;
                }
                return false;
            },
        },
        mounted() {

        }
    }
</script>

<style scoped lang="scss">
    @import 'assets/css/colors';


</style>
