<template>
	<div class="update-account">
	<section class="container-fluid section header-section pt-0">
		<component-card class="update-account-card text-center">

			<!-- Update account card -->
			<div v-if="this.showLogin && !this.showGuide" slot="no-padding-content">
				<div class="card-header bg-white __header--padding">
					<img 
					:src="require('../assets/images/' + icon.spotify)" 
					class="__card-icon">
					<h5>Upgrade your Spotify account</h5>
					<p>Enter your Spotify credentials below and wait for our system to upgrade your account.</p>
				</div>
				<div class="__card-body --padding">
				<component-form>
					<div slot="form" class="__form-content--padding">
						<label for="username1" class="__form-label --position mb-2">Username:</label>
						<input id="username1" placeholder="Username / Email Address" autocomplete="off" autocorrect="off" spellcheck="false" autocapitalize="off" v-model.trim="updateForm.username" class="form-control">
						<span class="error-text" v-if="this.show_form_errors && this.username_errors !== ''">{{ username_errors }}</span>
						<label for="password1" class="__form-label --position mb-2">Password:</label>
						<input id="password1" placeholder="Password" autocomplete="off" autocorrect="off" spellcheck="false" autocapitalize="off" v-model.trim="updateForm.password" class="form-control">
						<span class="error-text" v-if="this.show_form_errors && this.password_errors !== ''">{{ password_errors }}</span>
						<div slot="button" class="__form-submit-btn--padding">
							<submission-button @submissionWasClicked="submitAccountDetails()" :buttonText="'UPGRADE MY ACCOUNT'" :classes="'btn-primary --sizing'" :is_submitting="is_submitting" section="Update-Account-Credentials"></submission-button>
							<p class="__sign-in-fb --position">Normally sign in using Facebook? <a @click="guideToggle">Click here</a></p>
						</div>
                        <div class="error-text pt-3" v-if="this.form_errors !== ''">{{ form_errors }}</div>
					</div>
				</component-form>
				</div>
			</div>

			<!-- Facebook tutorial -->
			<div v-if="this.showGuide && !this.showLogin" slot="no-padding-content">
				<div class="card-header bg-white __header--padding">
					<h5>Create email login for Spotify</h5>
					<p>Follow the steps below to create a separate login for Spotify using your email address.</p>
				</div>
				<div class="__card-body --padding">
					<template v-for="list in lists">
					<ul :key="list.id">
						<li><span class="--list-number">{{list.number}}</span><span v-html="list.text"></span></li>
					</ul>
					</template>
					<button type="button" class="btn btn-primary mt-2 mb-4 --sizing" @click.prevent="guideToggle">RETURN AND UPGRADE</button>
				</div>
			</div>

			<!-- Submission successful -->
			<div v-if="this.showSuccess" slot="no-padding-content">
				<div class="card-header bg-white __header--padding">
					<h5>Thank you, your credentials have been submitted</h5>
					<p>Your account will be upgraded within 24 hours <img class="party" src="/src/assets/images/party-emoji.png"/></p>
				</div>
				<div class="__card-body --padding">
					<h6 class="mb-4">Follow our installation guide:</h6>
					<guide-card-large class="text-left"/>
				</div>
			</div>

			<!-- Something went wrong -->
            <div v-if="show400" slot="content">
                <h2>Whoops! Something went wrong.</h2>
                <p>Email us at <a href="mailto:support@chillabitio.com">support@chillabitio.com</a> or message us through our live chat. We'll get this sorted out right away!</p>
                <div class="text-center">
                    <a href="mailto:support@chillabitio.com"><button class="btn btn-primary pt-2 mr-1 mt-3">&nbsp;Email Us&nbsp;</button></a>
                    <button @click="openLiveChat()" class="slaask-open-widget btn btn-primary pt-2 ml-1 mt-3">Live Chat</button>
                </div>
                <div class="error-text pt-3" v-if="this.form_errors !== ''">{{ form_errors }}</div>
            </div>
		</component-card>
	</section>
	</div>
</template>

<script>
import Navbar from './Navbar.vue'
import ComponentCard from '../components/Card.vue'
import ComponentForm from '../forms/Form.vue'
import SubmissionButton from '../forms/SubmissionButton.vue'
import GuideCardLarge from './GuideCard2.vue'
import {urlParam} from '../assets/js/utilities'
import {isDev, getEndpoint, getPageLocation, Fingerprint} from '../assets/js/globals'
import Cookies from '../assets/js/cookie'

export default {
	components: {
		CheckoutNavbar: Navbar,
		ComponentCard,
		ComponentForm,
		SubmissionButton,
		GuideCardLarge
	},
	data() {
		return {
			form_errors: '',
			icon: {
				spotify: 'spotify.svg'
			},
			updateForm: {
				username: '',
				password: ''
			},
			fontStyle: {
				color: 'red'
			},
			lists: [
				{
					number: 1,
					text: 'Head to <a href="https://spotify.com/login">Spotify</a> in your web browser and sign into your account.'
				},
				{
					number: 2,
					text: `Scroll to the bottom of your account page and press:   <span style="color:white;background:black;padding:0.3rem 2rem;
					       border-radius:30px;font-size:0.5em;font-weight:600;line-height:3rem;margin-left:0.5rem;white-space:nowrap">SET PASSWORD</span>`
				},
				{
					number: 3,
					text: 'Locate your email address or username under the <strong>Profile</strong> section.'
				},
				{
					number: 4,
					text: 'Well done! You can now submit your credentials to Chillabitio.'
				}
			],
			showLogin: true,
			showGuide: false,
			showSuccess: false,
			show400: false,
			show_form_errors: false,
			cardBlock: false,
			is_submitting: false
		}
	},
	methods: {
		openLiveChat() {
			Beacon("open");
		},
		validateFields() {
			if(this.updateForm.username === '' || this.updateForm.password === '') {
				return false;
			}
			return true;
		},
		guideToggle() {
			this.showLogin = !this.showLogin;
			this.showGuide = !this.showGuide;
			this.show_form_errors = false
		},
		submitAccountDetails() {
			this.show_form_errors = true;

			if(!this.validateFields())
				return;

			this.is_submitting = true;
			let url = getEndpoint('submit_details');
			let data = {
				'spotify_username' : this.updateForm.username,
				'spotify_password' : this.updateForm.password,
			};
			console.log(this.fingerprint)

			$.ajax({
				context: this,
				url: url,
				headers : {'Authorization': 'Token ' + this.fingerprint.auth_token},
				data: JSON.stringify(data),
				type: "POST",
				dataType: "json",
				contentType: "application/json"
			}).done(() => {
				this.showSuccess = true;
			}).fail(function (data) {
				console.log(data['status']);
				if (data['status'] === 401) {
					window.location.href = getPageLocation('checkout401');
				}
				else if (data['status'] === 400) {
					this.form_errors = data.responseText.replace (/(^")|("$)/g, '')
					this.show400 = true;
					console.log(data)
				}
				else if (data['status'] === 200) {
					this.showSuccess = true;
				}
				else {
					console.log(data)
					window.location.href = getPageLocation('checkout500');
				}

			}).always(data => {
				this.is_submitting = false;
				this.showLogin = false;
				this.showGuide = false;
				this.showSuccess = true;
			});
		},
	},
	computed: {
		username_errors() {
			if(this.updateForm.username === '') {
				return '* Enter the username of the Spotify account you would like upgraded'
			}
			return '';
		},
		password_errors() {
			if(this.updateForm.password === '') {
				return '* Enter the password of the Spotify account you would like upgraded'
			}
			return '';
		}
	},
	created() {
            this.fingerprint = new Fingerprint();
            this.fingerprint.loadFingerprint();
            // load from url param if it exists
            let token = urlParam('token');
            if (token !== null) {
                console.log("Found login token");
                this.fingerprint.setAuthToken(token);
                // make sure to remove the url param
                let url = window.location.href;
                // find location of params
                let index = url.indexOf('?');
                if (index === -1) {
                    index = url.indexOf('#');
                }
                //remove params and redirect
                if (index !== -1) {
                    this.is_redirecting = true;
                    console.log("redirecting");
                    window.location.href = url.substring(0, index);
                }
                return;
            }

            // otherwise load from fingerprint if exists
            token = this.fingerprint.auth_token;
            if (token !== null) {
                console.log("Found saved cookie")
                return;
            }
		}
};
</script>


<style lang="scss" scoped>
@import '../assets/css/colors';
//header
.update-account .__card-icon {
	display: block;
	width: 32px;
	height: 32px;
	margin: auto;
}

.__header--padding {
	padding: auto 4rem;
	>*{
		margin-top: 1rem;
	}
}

//body
.__card-body.--padding {
	padding: 1.5rem 4rem;
}

.__sign-in-fb.--position {
	margin-top: 1.5rem;
	>a {
		cursor: pointer;
		color: $button-background;
		text-decoration: underline;
	}
}

//form
.__form-label.--position {
	display: block;
	text-align: left;
	margin-top: 1rem;
}

.__form-content--padding >* {
	margin-bottom: 0.5rem;
}

.__form-submit-btn--padding {
	margin-top: 2rem;
	&>* {
		margin-bottom: 0.5rem;
	}
}

.update-account label {
	font-weight: 500;
}

//unordered list 
.__card-body {
	
	ul {
		margin: 0 0 1.5em;
		padding: 0;
		counter-reset: item;
		text-align: left;
	}

	ul > li {
		margin: 0 0 1rem 0;
		padding: 1em 1em 1em 3em;
		list-style-type: none;
		text-indent: -1.6rem;
		background: #F3F3F3;
		border-radius: 30px;
	}

	li > .--list-number{
		font-weight: 600;
		margin-right: 1rem;
	}
}

.spotify-button {
	color: red;
}
//submission success
.party {
	height: 1.1rem;
	margin-bottom: 5px;
	margin-left: 2px;
}

@media (max-width: 426px) {
	.__card-body.--padding {
		padding: 1.5rem
	}

	ul {
		text-align: center;

		> li {
			margin: 0 0 1rem 0;
			padding: 1em !important;
			text-indent: 0 !important;
		}

		li .--list-number {
			display: block;
			text-align: center;
			margin: 0;
		}
	}

	.--sizing {
		padding: 0.6rem 1.5rem;
		font-size: 0.8rem;
	}     
}
</style>
