<template>
    <click-analytics :class="this.disabled ? 'cursor-normal' : '' " :text="!this.disabled ? this.buttonText + ' (Button)' : this.buttonText + ' (Disabled Button)'" :section="this.section">
        <button style="outline: none !important" @click="submitted()" :disabled="disabled"  class="position-relative" :class="this.classes + ' btn'">
            <div class="position-static">
                <div :class="is_submitting ? 'invisible no-height' : ''">
                    {{ buttonText }}
                </div>
                <loading-spinner :class="this.spinner_classes" v-if="is_submitting" size="20px" ringColor="#fff" spinnerColor="#ccc"></loading-spinner>
            </div>
         </button>
    </click-analytics>
</template>

<script>
    import ClickAnalytics from '../analytics/Click.vue'
    import LoadingSpinner from '../components/Spinner.vue'

    export default {
        components: {
            ClickAnalytics,
            LoadingSpinner
        },
        methods: {
            submitted() {
                if (!this.disabled)
                    this.$emit("submissionWasClicked", {})
                else
                    console.log("disabled")
            }
        },
        props: {
            buttonText : {
                required: true,
                type: String
            },
            section : {
                required: true,
                type: String
            },
            is_submitting : {
                required: true,
                type: Boolean
            },
            classes : {
                required: false,
                default: "mb-4 btn-success w-100 pb-3 pt-3 mt-4"
            },
            spinner_classes : {
                required: false,
                default: "mt-1"
            },
            disabled : {
                required: false,
                default: false,
                type: Boolean,
            }
        },
    }
</script>

<style scoped scoped lang="scss">
    @import '../assets/css/colors';

    .no-height {
        height: 0;
        overflow: hidden;
    }
    .btn-success {
        background-color: $button-success !important;
        border-color: $button-success !important;
    }

    .btn-small {
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 5px;
        font-size: 1rem;
    }

    .btn-success.disabled {
        background-color: $button-success-disabled !important;
        border-color: $button-success-disabled !important;
        color: $button-disabled-text;
        cursor: default;
    }

    .btn-tertiary, .btn-secondary:not([disabled]):not(.disabled):active {
        background-color: #d9d9d9;
        border: 4px solid #d9d9d9;
        color: #222;
    }

    .btn-tertiary:hover {
        background-color: #d0d0d0;
        border-color: #d0d0d0;
        color: #333;
    }

    .cursor-normal button {
        cursor: default;
    }

    .btn-tertiary:focus {
        background-color: #d9d9d9;
        border-color: #d9d9d9;
        box-shadow: none;
    }

    .btn-tertiary:active {
        border-color: #d9d9d9;
        background-color: #d9d9d9;
    }

    .btn-small.btn-text {
        cursor: pointer !important;
        color: #00bf5c;
        opacity: 1;
        font-size: 0.85rem;
        outline: none !important;
        -webkit-appearance:none;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }

    button:focus {outline:0 !important;
        -webkit-appearance:none;}

    @media (max-width: 426px) {  
        .--sizing {
            padding: 0.6rem 1.5rem;
            font-size: 0.8rem;
        }      
    }
</style>