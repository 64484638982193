<template>
    <div class="form">
        <slot name="errors">
            <span class="banner-message mt-3 mt-md-0 badge badge-danger" v-if="error_message" role="alert">{{ error_message }}</span>
        </slot>
        <slot name="success">
            <span class="banner-message mt-3 mt-md-0 badge badge-success" v-if="success_message" role="alert">{{ success_message }}</span>

        </slot>
        <slot name="form"></slot>
        <slot name="button"></slot>
    </div>
</template>

<script>
    export default {
        data() { return {}; },
        props: {
            error_message: {
                required: false,
                default: null,
            },
            success_message: {
                required: false,
                default: null,
            }
        }
    }
</script>

<style lang="scss">
    @import '../assets/css/colors';

    .badge-success {
        background-color: $button-success !important;
    }

    .form h3 {
        color: $footer;
        font-weight: 500;
        padding-bottom: 1rem;
        font-size: 1.2rem;
    }

    .form h4 {
        font-size: 1.1rem;
        font-weight: 400;
        color: #646464;
    }

    .form .field-outline {
        border: 1px solid #DDDDDD;
        font-size: 1.2rem;
        height: 2.8rem !important;
        margin-bottom: 0.5rem;
    }

    .form .element-holder {
        height: 2.8rem !important;
        padding-top: 0.7rem;
        padding-left: 0.5rem;
        border-radius: 5px;
    }

    .form .banner-message {
        font-size: 0.9rem;
        padding: 0.5rem;
        width: 100%;
        display: inline-block;
        white-space: normal;
        line-height: 1rem;
    }

    .btn-sm {
        font-size: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 2.8rem !important;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        transition: background-color 1000ms linear, border-color 1000ms linear;
        text-align: center;
    }

    .error-text {
        font-weight: 400;
        padding: 3px;
        color: $color-error;
        font-size: 0.9rem;
    }

    .success-text {
        font-weight: 400;
        padding: 3px;
        color: $button-success;
        font-size: 0.9rem;
    }


</style>