<template>
    <component-page>

        <!-- NAVBAR -->
        <div v-if="!is_redirecting" slot="navbar">
            <component-navbar description="YOUR ACCOUNT" icon="person">
                <div @click="logout" slot="links" class="d-inline-block nav-link ml-2" style="color: white ">
                    | &nbsp;Logout
                </div>
            </component-navbar>
        </div>

        <!-- LOADING -->
        <div slot="loading" v-if="is_page_loading">
            <div  class="p-5">
                <loading class="m-5 pt-5 pb-2 text-center"></loading>
            </div>
        </div>

        <!-- CONTENT -->
        <div slot="content">

            <!-- SUBSCRIPTION DETAILS -->
            <component-card class="mb-3" v-show="api_status == 200 && !is_page_loading">
                <div slot="content">
                    <h3>CURRENT SUBSCRIPTION</h3>
                    <subscription-details ref="subscription" @pulledSubscriptionDetails="setStatus"></subscription-details>
                </div>
            </component-card>

            <!-- STRIPE FORM -->
            <component-form :error_message="this.error_message" :success_message="this.success_message" v-show="api_status == 200 && !is_page_loading && has_active_subscription">
                <div slot="form">
                    <component-card>
                        <div slot="content">
                            <stripe-form ref="stripe_form" @stripeComponentsReady="stripe_is_loading=false" @stripeTokenEvent="processStripeResult">
                                <div slot="trust-signals"></div>
                            </stripe-form>
                        </div>
                    </component-card>

                </div>

                <div slot="button">
                    <submission-button @submissionWasClicked="submitCardData()" :is_submitting="this.is_submitting" buttonText="Update Your Card" :section="this.section"></submission-button>
                    <div class="note-text text-right">*Your card will not be charged at this time, but will be set as the default for the next billing cycle.</div>
                </div>
            </component-form>
        </div>

        <div slot="footer" >
            <component-footer v-if="!is_redirecting"></component-footer>
        </div>

    </component-page>
</template>

<script>
    import ComponentPage from '../Page.vue'
    import ComponentNavbar from '../checkout/Navbar.vue'

    import ComponentForm from '../forms/Form.vue'
    import ComponentCard from '../components/Card.vue'
    import {urlParam} from '../assets/js/utilities'
    import Loading from '../components/Spinner.vue'
    import StripeForm from '../forms/CreditCardForm.vue'
    import SubmissionButton from '../forms/SubmissionButton.vue'
    import SubscriptionDetails from '../account/SubscriptionDetails.vue'
    import ComponentFooter from '../Footer.vue';
    import {getEndpoint, getPageLocation, Fingerprint} from '../assets/js/globals'


    export default {
        data() {
          return {
              section: 'Account',
              token : null,
              api_status: 0,
              stripe_is_loading: true,
              is_submitting: false,
              error_message: null,
              success_message: null,
              is_redirecting: false,
              has_active_subscription: false,
          }
        },
        components: {
            ComponentNavbar,
            ComponentForm,
            Loading,
            StripeForm,
            ComponentCard,
            SubmissionButton,
            SubscriptionDetails,
            ComponentPage,
            ComponentFooter
        },
        methods: {
            setStatus(data) {
                this.api_status = data['status'];
                if (this.api_status === 401 || this.api_status === 403 ) {
                    window.location.href = getPageLocation('login');
                }
                this.has_active_subscription = data['active_subscription']
            },
            submitCardData() {
                this.is_submitting = true;
                this.$refs.stripe_form.retrieveToken();
            },
            processStripeResult(data) {

                // if we got a stripe payment token process the update through the backend
                if (data['error'] === null && data['token'] !== null) {
                    this.updateCardData(data['token']);
                // otherwise stop the submission and show errors
                } else {
                    this.is_submitting = false;
                    this.error_message = data['error'];
                    this.success_message = null;
                }
            },
            updateCardData(stripe_token) {
                $.ajax({
                    context: this,
                    url: getEndpoint('update'),
                    type: "POST",
                    contentType: "application/json",
                    headers: {
                        'Authorization': 'Token ' + this.token
                    },
                    data: JSON.stringify({
                        'token': stripe_token.id
                    })}).
                    done(() => {
                        this.error_message = null;
                        this.success_message = "Successfully updated your payment method.";
                        this.$refs.stripe_form.clearForm();
                        this.$refs.subscription.pullSubscriptionDetails(this.token);
                    }).
                    fail((data, status, error) => {
                        console.log(status);
                        console.log(data);
                        this.error_message = data['responseText'].replace (/(^")|("$)/g, '');
                        this.success_message = null;
                }).
                    always((data) => {
                        this.is_submitting = false;
                    });
            },
            logout() {
                this.api_status = 0;

                $.ajax({
                    context: this,
                    url: getEndpoint('logout'),
                    type: "GET",
                    contentType: "application/json",
                    headers: {
                        'Authorization': 'Token ' + this.token
                    }}).
                    always((data) => {
                        window.location.href = getPageLocation('index');
                    });
            }
        },
        computed : {
            is_page_loading() {
                if (this.api_status === 0 || this.stripe_is_loading) {
                    return true;
                }
                return false;
            },
        },
        created() {
            let fingerprint = new Fingerprint();
            fingerprint.loadFingerprint();
            this.token = urlParam('login');

            // load the auth token from the url or cookies
            if (this.token !== null) {
                fingerprint.setAuthToken(this.token);
                // make sure to remove the url param
                let url = window.location.href;

                // find location of params
                let index = url.indexOf('?');
                if(index === -1){
                    index = url.indexOf('#');
                }

                //remove params and redirect
                if(index !== -1){
                    this.is_redirecting = true;
                    window.location.href = url.substring(0, index);
                }
            }
            else {
                this.token = fingerprint.auth_token;
            }
        },
        mounted() {
            this.$refs.subscription.pullSubscriptionDetails(this.token);
        }
    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors';

    .note-text {
        color: #aaa;
        font-weight: 300
    }

</style>
