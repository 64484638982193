<template>
    <div>
        <img class="combined-shape" src="src/assets/images/bubbles.svg">
        <base-card style="border-top: none; margin-top: -23px;">
            <div class="rectangle-2">
                <div class="row h-100 text-right">
                    <div class="col my-auto pr-5 pt-2">
                    </div>
                </div>

            </div>
            <div class="small-padding">
                <span class="amount-large">$9.99</span><span class="receipt-text"> / USD monthly</span>
                <p class="receipt-subtext mt-4 mb-4">{{ this.receiptDescription }}</p>

                <hr>

                <div class="row">
                    <div class="col"><span class="receipt-text2">Subtotal</span></div>
                    <div class="col"><span class="receipt-text2">$9.99 USD</span></div>
                </div>

                <div class="row">
                    <div class="col"><span class="receipt-text2">Taxes</span></div>
                    <div class="col"><span class="receipt-text2">$0.00</span></div>

                </div>

                <div class="row mt-3">
                    <div class="col"><span class="receipt-text3">TOTAL TO PAY</span></div>
                    <div class="col"><span class="receipt-text3">$9.99 USD</span></div>
                </div>
            </div>

        </base-card>
    </div>
</template>

<script>
    import BaseCard from '../components/BaseCard.vue'
    export default {
        props: {
            isExisting: {required: false, default: false},
        },
        computed: {
            receiptDescription() {
                if (this.isExisting) {
                    return "Upgrade your existing account."
                }
                return "A music discovery account created just for you."
            },
        },
        data() {
            return {};
        },
        components: {
            BaseCard
        }
    }

</script>

<style scoped lang="scss">
    @import '../assets/css/colors';

    .amount-large {
        font-size: 2rem;
        font-weight: 600;
    }

    .receipt-subtext {
        font-size: 0.75rem;
    }

    .receipt-text2 {
        font-size: 0.825rem;
    }

    .receipt-text3 {
        font-size: 0.825rem;
        font-weight: bold;
    }

    .rectangle-2 {
        height: 48px;
        border-top-right-radius: 5px;
        background-color: #7933ff;
    }

    .combined-shape {
        margin-top: -44px;
        margin-left: -9px;
    }


</style>
