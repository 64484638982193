<template>
    <div class="form">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        data() { return {}; },
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/css/colors';


    .form .field-outline {
        border: 1px solid #DDDDDD;
        font-size: 0.75rem !important;
        height: 2rem !important;
        margin-bottom: 0.5rem;
        box-shadow: none !important;
    }


    h6 {
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    label {
        font-size: 0.75rem;
        color: $grey-600;
        font-weight: 600;
        text-transform: uppercase;
    }


    ::-webkit-input-placeholder {
        color: #bebebe !important;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: #bebebe !important;
    }

    ::-moz-placeholder {  /* Firefox 19+ */
        color: #bebebe !important;
    }

    :-ms-input-placeholder {
        color: #bebebe !important;
    }


</style>
