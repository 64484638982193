import {isDev, Fingerprint} from './globals'

const fingerprint = new Fingerprint;

// Set up facebook analytics
(function(f,b,e,v,n,t,s)
{
    if(f.fbq)return;n=f.fbq=function(){
        n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)})(window,
    document,'script','https://connect.facebook.net/en_US/fbevents.js');

(function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)})(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');


export function fbtag(...params) {
    fbq(params);
}

// Set up google analytics
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

export function send_conversion_event() {
    if(!isDev) {
        gtag('event', 'conversion', {
            'send_to': 'AW-619477728/fsM8CMaP5dcBEOD1sacC',
            'transaction_id': String(fingerprint.amplitude),
            'session_id': String(fingerprint.amplitude)
        });
        console.log(String(fingerprint.amplitude))
        console.log("Sent Conversion Event")
    }
}

if(!isDev) {
    gtag('js', new Date());
    gtag('config', 'AW-619477728', {
        'linker': {
            'domains': ['chillabitio.com', 'chillabit.io']
        }
    });
    gtag('config', 'UA-101052076-1');

    // track facebook pageview
    fbtag('init', '910930645735322');
    fbtag('track', 'PageView');

    // Configure hot jar
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:656517,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}
