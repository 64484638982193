<template>
    <div>
    <component-page>
        <!-- NAVBAR -->
        <div slot="navbar">
            <component-navbar description="SECURE CHECKOUT"></component-navbar>
        </div>

        <!-- CONTENT -->
        <div slot="content">
            <router-view></router-view>
        </div>

    </component-page>
    </div>
</template>

<script>
    import ComponentPage from '../Page.vue'
    import ComponentNavbar from '../checkout/Navbar.vue'
    import CountryValidator from '../assets/js/mixin_head.js'

    export default {
        mixins: [CountryValidator],
        data() {
          return {
          }
        },
        components: {
            ComponentNavbar,
            ComponentPage,
        },
        created() {

        }
    }
</script>

<style scoped lang="scss">


</style>
