<template>
    <div>
        <div class="container-fluid section footer-section">
                <div class="row mb-5">
                    <div class="col-lg-8 col-sm-8"><h3>Questions? We’re live 10am - 6pm (EST), message us on our <a>live chat</a></h3></div>
                    <div @click="openLiveChat()" class="col-lg-4 col-sm-4"><img class="support-team d-flex" src="./assets/images/support-team.png"></div>
                </div>
                <div class="row">
                    <div class="col-lg-3 mb-5">
                        <div class="mb-3"><b class="footer-header">COMPANY</b></div>

                        <template v-for="link in links['section1']">
                            <a @click.prevent="toLink(link)" href="#"><div class="mb-2"><span class="footer-link">{{ link.text }}</span></div></a>
                        </template>

                    </div>

                    <div class="col-lg-3  mb-5">
                        <div class="mb-3"><b class="footer-header">HELP CENTER</b></div>
                        <template v-for="link in links['section2']">
                            <a @click.prevent="toLink(link)" href="#"><div class="mb-2"><span class="footer-link">{{ link.text }}</span></div></a>
                        </template>
                    </div>

                    <div class="col-lg-3  mb-5">
                        <div class="mb-3"><b class="footer-header">LEARN MORE</b></div>
                        <template v-for="link in links['section3']">
                            <a @click.prevent="toLink(link)" href="#"><div class="mb-2"><span class="footer-link">{{ link.text }}</span></div></a>
                        </template>
                    </div>

                    <div class="col-lg-3 mb-5">
                        <div class="mb-3"><b class="footer-header">RESOURCES</b></div>
                        <template v-for="link in links['section4']">
                            <a @click.prevent="toLink(link)" href="#"><div class="mb-2"><span class="footer-link">{{ link.text }}</span></div></a>
                        </template>
                    </div>
                </div>
                <a v-observe-visibility="visibilityChanged"></a>

                <hr>

                <div class="row mb-4">
                    <div class="col">
                        <div style="font-size: 0.9rem; color: #73757a">At Chillabitio we love Spotify. We believe that everyone
                            should have access to the music they want to listen to regardless of what country they live in. We're happy to have you on our mission
                            of bringing music and Spotify to every country in the world.</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div style="font-size: 0.9rem; color: #73757a">We care about your information and privacy.</div>
                        <div style="font-size: 0.9rem; color: #73757a">Copyright © {{ getCurrentYear }} chillabitio.com support@chillabitio.com</div>
                    </div>
                    <div class="col text-right">
                        <img style="max-width: 2.8rem"  src="./assets/images/cards/031-visa.svg">
                        <img style="max-width: 2.8rem"  src="./assets/images/cards/031-american-express.svg">
                        <img style="max-width: 2.8rem"  src="./assets/images/cards/031-master-card.svg">
                        <img style="max-width: 2.8rem"  src="./assets/images/cards/031-paypal.svg">
                    </div>
                </div>

            </div>
        </div>
</template>

<script>
    import {getPageLocation} from './assets/js/globals'

    class Link {
        constructor(text, destination) {
            this.text = text;
            this.destination = destination;
        }

        description() {
            return this.text + " (Link)"
        }
    }

    export default {
        data() {
            return {
                links: {
                    section1: [
                        new Link("About Chillabitio", 'about'),
                        new Link("Media", 'contact'),
                        new Link("Careers", 'hiring'),
                        new Link("Pricing (Monthly)", 'checkout'),
                        new Link("My Account", 'login'),
                    ],
                    section2: [
                        new Link("Video Tour", 'video_us'),
                        new Link("Our Reviews", 'reviews'),
                        new Link("FAQ", 'faq'),
                        new Link("Support", 'contact'),
                    ],
                    section3: [
                        new Link("Ambassador Program", 'hiring'),
                        new Link("Manifesto", 'about'),
                        new Link("Refer a Friend", 'hiring'),
                        new Link("Press Area", 'contact'),
                    ],
                    section4: [
                        new Link("Install Spotify on Android", 'video_android'),
                        new Link("Install Spotify on Alexa", 'video_alexa'),
                        new Link("Install Spotify Google Home", 'video_google_home'),
                        new Link("Android SDK", 'apk'),
                        new Link("iPhone Tutorial", 'iphone'),
                    ],
                },
                sectionName : "Footer",
                seenSection : false
            }
        },
        methods: {
            openLiveChat() {
                Beacon("open");
            },
            toLink(link) {
                if (link.destination === null) {
                    let event = new this.$clickEvent(link.description(), null, this.sectionName);
                    event.sendEvent();
                }
                else {
                    let url = getPageLocation(link.destination);
                    this.$goToPage(link.description(), url, this.sectionName);
                }
            },
            visibilityChanged(isVisible, entry) {
                if (entry['isIntersecting'] && !this.seenSection) {
                    console.log("IN FOOTER");

                    this.seenSection = true;
                    let event = new this.$scrollEvent(this.sectionName);
                    event.sendEvent();
                }

            }
        },
        computed: {
            getCurrentYear() {
                return new Date().getFullYear();
            }
        }


    }
</script>

<style lang="scss">
    @import 'assets/css/colors.scss';

    .footer-section {
        background-color: $footer;
    }
    .footer-header {
        color: #fefefe;
        font-weight: 500;
    }

    .footer-link {
        color: #fbfbfb;
        font-weight: 400;
        margin-bottom: 20px;
        cursor: pointer;
        font-size: 1rem;
    }

    .footer-link:hover {
        color: #00c1cf;
        -webkit-transition: all .25s ease-out;
        transition: all .25s ease-out;
    }
    .support-team {
        height: 2.8rem;
    }

    h3 {
        font-size: 1.6rem;
    }

    @media (max-width: 520px)  {

        h3 {
            font-size: 1.2rem;
            margin-bottom:1rem;
        }

        .support-team {
            margin: auto;
        }
    }
</style>
