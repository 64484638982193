<template>
  <div class="lead-submission">
    <base-card>
      <div class="small-padding">
        <component-form>
          <div :class="fields_are_set ? 'mb-4' : 'mb-2'">
            <h6 class="mb-3">YOUR DETAILS</h6>

            <!-- COUNTRY -->
            <setting-field
              :class="fields_are_set ? '' : 'mb-3'"
              :show_error="is_country_invalid"
              :show_success="country_code != ''"
              :in_display_mode="fields_are_set"
              error_text="* Please select your country"
              :display_text="this.country_name"
              display_header="Country:"
            >
              <div slot="input" class="row pr-2">
                <img
                  class="flag img-fluid d-inline-flex mb-2"
                  style="margin-left: 0.02rem"
                  :src="current_flag"
                />
                <div class="col my-auto pr-0">
                  <select v-model="country_code" class="field-outline form-control placeholder">
                    <option class="dropdown-style" value>Select your country</option>
                    <template v-for="country in sorted_countries">
                      <option class="dropdown-style" :value="country.code">{{ country.name }}</option>
                    </template>
                  </select>
                </div>
              </div>
            </setting-field>

            <!-- FULL NAME -->
            <setting-field
              :class="fields_are_set ? '' : 'mb-3'"
              :show_error="is_name_invalid"
              :in_display_mode="fields_are_set"
              error_text="* Please enter your full name"
              :display_text="full_name"
              display_header="Name:"
            >
              <div slot="input" class="row pr-2" style="flex-wrap:unset;">
                <input
                  id="name"
                  type="name"
                  placeholder="First Name"
                  v-model="first_name"
                  class="field-outline form-control placeholder mr-2"
                />
                <input
                  id="name"
                  type="name"
                  placeholder="Last Name"
                  v-model="last_name"
                  class="field-outline form-control placeholder ml-2"
                />
              </div>
            </setting-field>

            <!-- EMAIL ADDRESS -->
            <setting-field
              :class="fields_are_set ? '' : 'mb-3'"
              :show_error="is_email_invalid"
              :in_display_mode="fields_are_set"
              error_text="* Your email address is invalid. Please check it is spelt correctly."
              :display_text="this.email"
              display_header="Email Address:"
            >
              <div slot="input" class="row pr-2">
                <input
                  placeholder="Email Address"
                  type="email"
                  v-model="email"
                  class="field-outline form-control placeholder"
                />
              </div>
            </setting-field>

            <!-- PHONE NUMBER -->
            <setting-field
              v-if="phone_experiment"
              class="mb-4"
              :show_error="is_phone_number_invalid"
              :in_display_mode="fields_are_set"
              error_text="* Your phone number is not valid"
              :display_text="this.phone_display"
              display_header="Phone Number:"
            >
              <div slot="input" class="row">
                <div class="d-inline-flex">
                  <input
                    placeholder="+1"
                    v-model="phone_country_code"
                    class="field-outline form-control placeholder"
                    style="max-width: 5.8rem"
                  />
                </div>
                <div class="col pr-2">
                  <input
                    style="margin-bottom: 0"
                    placeholder="Phone Number (eg. 234-567-8901)"
                    v-model="phone"
                    class="form-control placeholder field-outline w-100"
                  />
                </div>
              </div>
            </setting-field>
          </div>

            <submission-button
              @submissionWasClicked="submitLead()"
              :button_text="this.lead_button_text"
              :is_submitting="is_submitting"
              :classes="fields_are_set ? 'btn-info w-100' : 'btn-info w-100'"
            ></submission-button>

          <div v-if="lead_server_errors !== ''" class="error-text mt-1">{{this.lead_server_errors}}</div>
        </component-form>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import ComponentInlineField from "../components/forms/InlineField.vue";
import SubmissionButton from "../components/forms/SubmissionButton.vue";
import ComponentForm from "../components/forms/Form.vue";
import SettingField from "../components/SettingField.vue";

import { validateEmail, validatePhone } from "../assets/js/utilities.js";
import { getEndpoint, Fingerprint } from "../assets/js/globals.js";
import { countries } from "../assets/js/data.js";

export default {
  data() {
    return {
      email: "",
      phone: "",
			first_name: "",
			last_name: "",
      phone_country_code: "",
      phone_experiment: false,
      country_code: "",
      attempted_submissions: 0,
      fields_are_set: false,
      is_submitting: false,
      lead_server_errors: "",
      fingerprint: null,
      country_code_map: {}
    };
  },
  components: {
    BaseCard,
    ComponentInlineField,
    SubmissionButton,
    ComponentForm,
    SettingField,
  },
  watch: {
    country_code() {
      this.phone_country_code = this.country_code_map[this.country_code];
    }
  },
  computed: {
		full_name() {
			return `${this.first_name} ${this.last_name}`;
		},
    sorted_countries() {
      let temp_countries = Object.keys(countries).map(key => {
        return countries[key];
      });
      temp_countries.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      return temp_countries;
    },
    phone_display() {
      let phone_number = this.phone;
      if (this.phone_country_code !== "")
        phone_number = "(" + this.phone_country_code + ")" + " " + this.phone;
      return phone_number;
    },
    current_flag() {
      let default_image = "src/assets/images/round_flags/none.png";

      let current = this.sorted_countries.filter(e => {
        return e.code === this.country_code;
      });

      if (current.length > 0) return current[0].rounded_flag;
      return default_image;
    },
    complete_phone() {
      if (this.phone_experiment === true) {
        let complete = this.phone_country_code + this.phone;
        complete = complete.replace(/\D/g, "");
        return complete;
      }
      return "";
    },
    country_name() {
      if (this.country_code === "") return "";

      let current = this.sorted_countries.filter(e => {
        return e.code === this.country_code;
      });

      if (current.length > 0) return current[0].name;
      return "no";
    },
    is_email_invalid() {
      if (this.attempted_submissions && !validateEmail(this.email)) return true;
      return false;
    },
    is_name_invalid() {
      if (!this.attempted_submissions) return false;
      if (
        this.full_name.length === 0 ||
        this.full_name.split(" ").length < 2 ||
        this.full_name.split(" ")[1].length === 0
      )
        return true;
      return false;
    },
    is_country_invalid() {
      if (this.attempted_submissions && this.country_code == "") {
        return true;
      }
      return false;
    },
    is_phone_number_invalid() {
      if (
        this.phone_experiment === true &&
        this.attempted_submissions &&
        !validatePhone(this.complete_phone)
      ) {
        return true;
      }
      return false;
    },
    lead_button_text() {
      return this.fields_are_set ? "CHANGE" : "CONFIRM";
    }
  },
  methods: {
    submitLead() {
      // VALIDATE
      if (this.is_submitting) {
        return;
      }

      if (this.fields_are_set) {
        this.fields_are_set = false;
        this.$emit("LEAD_INCOMPLETE");
        return;
      }

      this.attempted_submissions += 1;
      if (
        this.is_email_invalid ||
        this.is_country_invalid ||
        this.is_phone_number_invalid ||
        this.is_name_invalid
      ) {
        return;
      }

      // SUBMIT DATA
      this.is_submitting = true;
      this.lead_server_errors = "";

      let lead_data = {
        country: this.fingerprint.country_code
          ? this.fingerprint.country_code
          : "",
        region: this.fingerprint.region_name
          ? this.fingerprint.region_name
          : "",
        city: this.fingerprint.city ? this.fingerprint.city : "",
        email: this.email,
        "link-referer": this.fingerprint.referer
          ? this.fingerprint.referer
          : "",
        utm_source: this.fingerprint.utm_source
          ? this.fingerprint.utm_source
          : "",
        utm_campaign: this.fingerprint.utm_campaign
          ? this.fingerprint.utm_campaign
          : "",
        utm_content: this.fingerprint.utm_content
          ? this.fingerprint.utm_content
          : "",
        phone_number: this.complete_phone,
        country_code: this.country_code,
        ip: this.fingerprint.ip ? this.fingerprint.ip : "",
        name: this.full_name,
        device_identifier: this.fingerprint.amplitude
      };

      let user_data = {
        email: lead_data["email"],
        name: lead_data["name"],
        country: lead_data["country_code"],
        device_identifier: this.fingerprint.amplitude
      };

      if (lead_data["link-referer"] !== "") {
        user_data["link-referer"] = lead_data["link-referer"];
      }

      if (lead_data["utm_source"] !== "") {
        user_data["utm_source"] = lead_data["utm_source"];
      }

      if (lead_data["utm_campaign"] !== "") {
        user_data["utm_campaign"] = lead_data["utm_campaign"];
      }

      if (lead_data["utm_content"] !== "") {
        user_data["utm_content"] = lead_data["utm_content"];
      }

      if (lead_data["country"] !== "") {
        user_data["ip_country"] = lead_data["country"];
      }

      if (lead_data["city"] !== "") {
        user_data["ip_city"] = lead_data["city"];
      }

      if (lead_data["region"] !== "") {
        user_data["ip_region"] = lead_data["region"];
      }

      let result = JSON.stringify(user_data);

      $.ajax({
        context: this,
        url: getEndpoint("register"),
        data: result,
        type: "POST",
        dataType: "json",
        contentType: "application/json"
      })
        .done(data => {
          this.lead_server_errors = "";
          this.fields_are_set = true;
          this.fingerprint.setAuthToken(data["token"]);
          this.fingerprint.setName(this.full_name);
          lead_data["token"] = data["token"];
          this.$emit("LEAD_COMPLETE", lead_data);
          this.is_submitting = false;
        })
        .fail(data => {
          this.is_submitting = false;
        });
    }
  },
  created() {
    this.fingerprint = new Fingerprint();
    this.fingerprint.loadFingerprint();
    this.country_code = this.fingerprint.country_code;
    this.country_code_map = {};
    Object.keys(countries).forEach(key => {
      this.country_code_map[countries[key].code] = countries[key].extension;
    });
  },
  mounted() {
    setTimeout(() => {
      var version = document.head.querySelector("[name='version']");
    }, 100);
  }
};
</script>

<style scoped lang="scss">
span {
  font-size: 0.825rem;
}

.flag {
  border: 2px solid #ddd;
  border-radius: 1000px;
  width: 2.6rem;
  height: 2.6rem;
  margin-left: -4px;
}

.field-outline {
  border: 1px solid #dddddd;
  font-size: 0.75rem !important;
  height: 2rem !important;
  margin-bottom: 0.2rem;
  box-shadow: none !important;
}
</style>
