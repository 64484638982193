<template>
    <div class="d-inline-block">
        <template v-if="!is_editing">
            <div style="display:inline-block;"><b>{{this.email}} </b> <div class="p-1" style="display:inline-block; text-decoration: underline; cursor: pointer" @click="is_editing=true">Edit</div></div>
        </template>
        <template v-else>
            <div slot="form" style="display:inline-block;">
                <input name="test" autocomplete="off" autocorrect="off" spellcheck="false" id="adjustable-input" :style="{ width: email_width }" v-model="child_email" class="underline" type="text"/>
                <div style="display:inline-block;"><submission-button @submissionWasClicked="submitEmail()" buttonText="Save" section="" :is_submitting="is_submitting" classes="btn-small btn-text"></submission-button></div>
            </div>
        </template>
    </div>
</template>

<script>
    import SubmissionButton from '../forms/SubmissionButton.vue'
    import {getEndpoint} from '../assets/js/globals'

    export default {
        data() {
            return {
                is_editing: false,
                is_submitting: false,
                child_email: ""
            }
        },
        props: {
          email: {
              required: true,
              type: String
          },
            token: {
                required: true,
                type: String
            },
        },
        computed: {
            email_width() {
                return (this.child_email.length * 9) + 'px';
            }
        },
        components: {
            SubmissionButton
        },
        methods: {
            submitEmail() {
                this.is_submitting = true;
                $.ajax({
                    context: this,
                    url: getEndpoint('change_email'),
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json",
                    data: JSON.stringify({'email' : this.child_email}),
                    headers: {
                        'Authorization': 'Token ' + this.token
                    },
                    success: function (data, status, xhr) {
                        this.resendEmail();
                    },
                    error: function (data) {
                        this.result(data);
                    }});
            },
            resendEmail() {
                $.ajax({
                    context: this,
                    url: getEndpoint('send_welcome_email'),
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json",
                    headers: {
                        'Authorization': 'Token ' + this.token
                    },
                    success: function (data, status, xhr) {
                        this.result(data);
                    },
                    error: function (data) {
                        this.result(data);
                    }});
            },
            result(data) {
                console.log(data);
                this.is_submitting = false;
                this.is_editing = false;
                this.$emit('updatedEmail', {'status': data['status'], 'email': this.child_email});
            }
        },
        created() {
            this.child_email = this.email;
        },
        mounted() {},
    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors';

    .underline {
        border: 0;
        outline: 0;
        background: transparent;
        border-bottom: 1px solid black;
        width: 20px;
        background-image: none !important;
    }


</style>
