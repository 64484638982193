<template>

        <form>
            <div class="row ml-0 mr-0">
                    <div @click="tabClicked(true)" class="col-6 large-radio" style="height: 80px; padding-top:5px;" :class="this.credit_card_selected ? 'selected-radio' : 'not-selected-radio'">
                        <div class="d-flex justify-content-between h-100">
                            <div class="d-flex align-items-center">
                                <div class="radio-icon d-inline-block">
                                    <span class="oi oi-circle-check" style="margin-bottom: 4px"></span>
                                    <div class="dot"></div>
                                </div>
                                <div class="d-inline-block text-center pl-3 pr-3">
                                    <h6 style="margin-bottom: 5px;">CARD</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-2 justify-content-end" style="white-space: nowrap;">
                                <img class="ccard super-small-screen" src="src/assets/images/payment_cards/031-master-card.svg">
                                <img class="ccard d-none d-sm-inline-block" src="src/assets/images/payment_cards/031-visa.svg">
                                <img class="ccard d-none d-md-inline-block" src="src/assets/images/payment_cards/031-discover.svg">
                                <img class="ccard d-none d-md-inline-block" src="src/assets/images/payment_cards/031-american-express.svg">
                            </div>
                        </div>
                    </div>
                    <div @click="tabClicked(false)" class="col-6 large-radio" style="height: 80px; padding-top:5px;" :class="!this.credit_card_selected ? 'selected-radio' : 'not-selected-radio'">
                        <div class="d-flex justify-content-between h-100">
                            <div class="d-flex align-items-center">
                                <div class="radio-icon d-inline-block">
                                    <span class="oi oi-circle-check"  style="margin-bottom: 4px"></span>
                                    <div class="dot"></div>
                                </div>
                                <div class="d-inline-block text-center pl-3 pr-3">
                                    <h6 style="margin-bottom: 5px;">PAYPAL</h6>
                                </div>
                            </div>

                            <div class="d-flex align-items-center mb-2 justify-content-end" style="white-space: nowrap;">
                                <img class="ccard paypal-super-small-screen" src="src/assets/images/payment_cards/031-paypal.svg">

                                <img class="paypal-other" src="src/assets/images/paypal.svg">
                            </div>
                        </div>
                    </div>
            </div>

            <div class="small-padding form-container">

                <!-- PAYPAL SECTION -->
                <div v-show="!credit_card_selected" class="text-center w-100 h-100 pt-5 pb-5">

                    <div v-if="is_submitting">
                        <loading-spinner></loading-spinner>
                        <div class="mt-4 mb-md-2 paypal-subtext"><span class="oi oi-circle-check mr-2 mt-3"></span><span>Please wait. Do not refresh the page.</span></div>
                    </div>
                    <div v-show="!is_submitting">
                        <div id="paypal-button" class="mb-4" style="display: inline-block"></div>
                        <span class="mt-3 mt-md-0 d-inline-block w-100 text-center error-text" style="white-space: normal; line-height: 1rem; font-size: 0.75rem;" v-if="server_errors && show_errors">*{{ server_errors }}</span>
                        <span class="mt-3 mt-md-0 d-inline-block w-100 text-center success-text" style="white-space: normal; line-height: 1rem; font-size: 0.75rem;" v-if="server_success">*{{ server_success }}</span>

                        <slot name="paypal_extra">
                        <div class="mb-4 mb-md-2 paypal-subtext"><span class="oi oi-circle-check mr-2 mt-3"></span><span>You're 100% backed by our 30-day money-back guarantee.</span></div>
                        <div class="paypal-subtext"><span class="oi oi-lock-locked mr-2"></span><span>You will be redirected to PayPal to complete your purchase securely.</span></div>
                        </slot>
                    </div>


                </div>

                <!-- CREDIT CARD SECTION -->
                <div v-show="credit_card_selected">

                <div class="form-group">
                    <div class="d-flex align-items-baseline justify-content-between"><label>Card Number</label><div class="d-inline-block"><span class="lock-text mr-1">Secure Form</span><img style="max-width: 13px; margin-bottom: 3px;" src="src/assets/images/lock-light.svg"></div></div>
                    <div id="card-number" placeholder="1234 1234 1234 1234" class="form-control placeholder field-outline card-holder"/>
                    <div class="d-flex justify-content-between">
                        <div><div v-if="card_error" class="error-text">{{ card_error }}</div></div>
                    </div>

                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group d-inline-block mr-2 mb-0">
                            <label>Expiry date</label>
                            <div id="card-expiry" placeholder="MM/YY" class="form-control placeholder field-outline mb-0"/>
                            <div  v-if="this.expiry_error || this.cvc_error" class="error-text">{{ expiry_error }}&nbsp;</div>
                            <span class="lock-text">&nbsp;</span>
                        </div>

                        <div class="form-group d-inline-block mr-2 mb-0">
                            <label>CVC</label>
                            <div id="card-cvc" placeholder="000" class="form-control placeholder field-outline card-cvc mb-0"/>
                            <span class="lock-text">3 digits on back</span>
                            <div v-if="this.expiry_error || this.cvc_error" class="error-text">{{ cvc_error }}&nbsp;</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-md-6 d-inline-block my-auto">
                        <div>
                            <span class="mt-3 mt-md-0 d-inline-block w-100 text-left error-text" style="white-space: normal; line-height: 1rem; font-size: 0.75rem;" v-if="server_errors && show_errors">*{{ server_errors }}</span>
                            <span class="mt-3 mt-md-0 d-inline-block w-100 success-text" style="white-space: normal; line-height: 1rem; font-size: 0.75rem;" v-if="server_success">*{{ server_success }}</span>


                        </div>

                    </div>

                    <div class="col-sm-12 col-md-6 text-right">
                        <slot name="trust-signals">
                            <img src="../assets/images/verisign.png" style="max-width: 130px;"/>
                        </slot>
                    </div>
                </div>

                    <div class="mt-4 mb-4">
                      <slot name="checkout_button">
                          <submission-button @submissionWasClicked="processCard()" :button_text="this.checkout_button_text" :is_submitting="is_submitting" :classes="'btn-success w-100'" style="padding-top: 1rem; padding-bottom: 1rem;"></submission-button>
                      </slot>
                        <slot name="extra">
                            <div class="mb-4 mb-md-2 paypal-subtext"><span class="oi oi-circle-check mr-2 mt-3"></span><span>You're 100% backed by our 30-day money-back guarantee.</span></div>
                            <div class="paypal-subtext"><span class="oi oi-lock-locked mr-2"></span><span>Secured by <b style="text-decoration: underline">Braintree</b> payment processing</span></div>
                        </slot>
                    </div>

                </div>



            </div>
        </form>

</template>

<script>
    import {isDev} from '../assets/js/globals'
    import BaseCard from '../components/BaseCard.vue'
    import LoadingSpinner from '../components/Spinner.vue'
    import SubmissionButton from '../components/forms/SubmissionButton.vue'

    export default {
        data() {
            return {
                elements_loaded: false,
                card_error: null,
                expiry_error: null,
                cvc_error: null,
                card_id: '#card-number',
                card_ready: false,
                expiry_id: '#card-expiry',
                expiry_ready: false,
                cvc_id: '#card-cvc',
                cvc_ready: false,
                in_error: false,
                hosted_fields: null,
                credit_card_selected: true,
                show_errors: true,
                base_error: 'Something went wrong processing your payment method. Please contact us through email or through our live chat for help!',
                base_error2: 'We had trouble processing your payment method. Please contact us through email or through our live chat for help!',
            }
        },
        props: {
          checkout_button_text: {required: true},
          is_submitting: {required: true},
          server_errors: {required: true},
          server_success: {required: false, default: ''},
        },
        components: {
            BaseCard,
            LoadingSpinner,
            SubmissionButton
        },
        computed: {
        },
        methods: {
            clearForm() {
                if (this.hosted_fields !== null) {
                    this.hosted_fields.clear('number');
                    this.hosted_fields.clear('cvv');
                    this.hosted_fields.clear('expirationDate')
                }
            },
            tabClicked(is_credit_card) {
                if (this.is_submitting === true)
                    return;
                this.credit_card_selected=is_credit_card;
                this.show_errors=false;
            },
            processCard() {
                if (this.is_submitting)
                    return;

                this.$emit("isSubmitting");
                this.retrieveToken();
            },
            setUpBraintree() {
                let token = 'sandbox_bm9hx695_rkphb3rhff9yh4w3';
                if (!isDev) {
                    token = 'production_43vvvwtx_f5vjdn4cr79zp992';
                }
                console.log(token);
                braintree.client.create({authorization: token}, this.clientDidCreate)
            },
            clientDidCreate(err, client) {
                if (err) {
                    console.error(err);
                    return;
                }
                console.log("success");
                braintree.paypalCheckout.create({client: client}, this.paypalDidCreate);
                braintree.hostedFields.create({
                    client: client,
                    styles: {
                        'input': {
                            'font-size': '0.75rem',
                        },
                        '.invalid': {
                            'color': '#dc3545'
                        },
                        '::-webkit-input-placeholder': {
                            'color': '#bebebe'
                        },
                        ':-moz-placeholder': {
                            'color': '#bebebe'
                        },
                        '::-moz-placeholder': {
                            'color': '#bebebe'
                        },
                        ':-ms-input-placeholder': {
                            'color': '#bebebe'
                        }
                    },
                    fields: {
                        number: {
                            selector: this.card_id,
                            placeholder: '**** **** **** ****',
                        },
                        cvv: {
                            selector: this.cvc_id,
                            placeholder: '000'
                        },
                        expirationDate: {
                            selector: this.expiry_id,
                            placeholder: 'MM/YY'
                        }
                    }
                }, this.hostFieldsDidCreate);
            },
            paypalDidCreate(err, paypalCheckoutInstance) {
                if (err) {
                    console.error(err);
                    return;
                }

                let env = 'sandbox';
                if (!isDev) {
                    env = 'production';
                }

                paypal.Button.render({
                    env: env,

                    style: {
                        label: 'checkout',
                        size:  'responsive',    // small | medium | large | responsive
                        shape: 'rect',     // pill | rect
                        color: 'gold',      // gold | blue | silver | black
                        tagline: false
                    },

                    payment: () => {
                        this.$emit("isSubmitting");
                        let event = new this.$clickEvent('Paypal Checkout (Button)', null, 'Checkout');
                        event.sendEvent();
                        return paypalCheckoutInstance.createPayment({
                            // Your PayPal options here. For available options, see
                            // http://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
                            'flow' : 'vault',
                            'landingPageType' : 'login',
                            'billingAgreementDescription' : 'Subscription payment - 9.99 USD Monthly',
                            'displayName' : 'Chillabitio'
                        });
                    },

                    onAuthorize: (data, actions) => {
                        return paypalCheckoutInstance.tokenizePayment(data,  (err, payload) => {
                            // Submit `payload.nonce` to your server.
                            console.log('Submitting  paypal nonce');
                            console.log(payload.nonce);
                            this.$emit("braintreeTokenEvent", {'nonce' : payload.nonce, 'error' : err, 'type' : 'PAYPAL'});
                        });
                    },

                    onCancel: (data) => {
                        console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
                        this.sendBraintreeTokenEvent(null, '');

                    },

                    onError: (err) => {
                        console.error('checkout.js error', err);
                        this.sendBraintreeTokenEvent(null, err.message);
                        this.show_errors=true;
                    }
                }, '#paypal-button').then(() => {
                    // The PayPal button will be rendered in an html element with the id
                    // `paypal-button`. This function will be called when the PayPal button
                    // is set up and ready to be used.
                });

            },
            hostFieldsDidCreate(err, hosted_fields) {
                if (err) {
                    console.error(err);
                    return;
                }
                else {
                    this.$emit("braintreeElementsLoaded");
                    this.elements_loaded = true;
                    this.hosted_fields = hosted_fields;
                    this.hosted_fields.on('validityChange', (event) => {
                        var field = event.fields[event.emittedBy];

                        if (field.isValid || field.isPotentiallyValid) {
                            if (event.emittedBy === 'expirationDate') {
                                this.expiry_error = "";
                            } else if (event.emittedBy === 'number') {
                                this.card_error = "";
                            } else if (event.emittedBy === 'cvv') {
                                this.cvc_error = ""
                            }
                            // Remove any previously applied error or warning classes
                            $(field.container).parents('.form-group').removeClass('has-warning');
                            $(field.container).parents('.form-group').removeClass('has-success');
                            // Apply styling for a valid field
                            $(field.container).parents('.form-group').addClass('has-success');
                        } else {
                            // Add styling to invalid fields
                            $(field.container).parents('.form-group').addClass('has-warning');
                            // Add helper text for an invalid card number
                            if (event.emittedBy === 'expirationDate') {
                                this.expiry_error = "*invalid expiry";
                            } else if (event.emittedBy === 'number') {
                                this.card_error = "*invalid card number";
                            } else if (event.emittedBy === 'cvv') {
                                this.cvc_error = "*invalid cvc"
                            }
                        }
                    });

                    console.log("successfully created hosted fields");
                }
            },
            sendBraintreeTokenEvent(nonce, error) {
                this.$emit("braintreeTokenEvent", {'nonce' : nonce, 'error' : error, 'type' : 'CARD'});
                this.show_errors=true;
            },
            retrieveToken() {
                this.hosted_fields.tokenize((tokenizeErr, result) => {
                    if (tokenizeErr) {
                        switch (tokenizeErr.code) {
                            case 'HOSTED_FIELDS_FIELDS_EMPTY':
                                this.cvc_error = "*required";
                                this.card_error = "*required";
                                this.expiry_error = "*required";
                                this.sendBraintreeTokenEvent(null, '');
                                break;
                            case 'HOSTED_FIELDS_FIELDS_INVALID':
                                // occurs when certain fields do not pass client side validation
                                // you can also programtically access the field containers for the invalid fields
                                tokenizeErr.details.invalidFieldKeys.forEach((key) => {
                                    if (key === 'expirationDate') {
                                        this.expiry_error = "*invalid expiry";
                                    } else if (key === 'number') {
                                        this.card_error = "*invalid card number";
                                    } else if (key === 'cvv') {
                                        this.cvc_error = "*invalid cvc"
                                    }
                                });
                                this.sendBraintreeTokenEvent(null, '');
                                break;
                            case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
                                // occurs when:
                                //   * the client token used for client authorization was generated
                                //     with a customer ID and the fail on duplicate payment method
                                //     option is set to true
                                //   * the card being tokenized has previously been vaulted (with any customer)
                                // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.fail_on_duplicate_payment_method
                                this.sendBraintreeTokenEvent(null, this.base_error);
                                break;
                            case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
                                // occurs when:
                                //   * the client token used for client authorization was generated
                                //     with a customer ID and the verify card option is set to true
                                //     and you have credit card verification turned on in the Braintree
                                //     control panel
                                //   * the cvv does not pass verfication (https://developers.braintreepayments.com/reference/general/testing/#avs-and-cvv/cid-responses)
                                // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.verify_card
                                this.cvc_error = "*invalid cvc";
                                this.sendBraintreeTokenEvent(null, '');
                                break;
                            case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
                                this.cvc_error = "*invalid card number";
                                this.sendBraintreeTokenEvent(null, '');
                                break;
                            case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
                                // occurs when the Braintree gateway cannot be contacted
                                this.sendBraintreeTokenEvent(null, 'We are having trouble accessing our payment service provider. Please try again later.')
                                break;
                            default:
                                this.sendBraintreeTokenEvent(null, this.base_error2);
                        }
                    } else {
                        this.sendBraintreeTokenEvent(result.nonce, null)
                    }
                });
            }
        },
        mounted() {
            this.setUpBraintree();
        }
    }
</script>

<style scoped  lang="scss">
    @import '../assets/css/colors';

    .ccard {
        margin-right: 2px;
        max-width: 1.8rem
    }

    .card-cvc {
        //background-image: url('~src/assets/images/lock-light.svg') !important;
        background-size: 1rem;
        background-repeat: no-repeat;
        width: 100px;
        padding-right: 2rem;
        -webkit-appearance: none;
        background-position-x: 95%;
        background-position-y: 50%;
    }

    .lock {
        max-height: 1.1rem;
    }

    .lock-text {
        margin-top: -5px;
        font-size: 0.75rem;
        font-weight: 400;
        color: #ccc;
    }

    .error-text {
        font-size: 0.75rem;
        font-weight: 400;
    }

    .card-holder {
        //background-image: url('~src/assets/images/credit-card.svg') !important;
        background-size: 1.25rem;
        background-repeat: no-repeat;
        min-width: 230px;
        padding-right: 1.5rem;
        background-position-x: 99%;
        background-position-y: 50%;
    }

    .large-radio .oi-circle-check {
        font-size: 1.5rem;
        color: $dark-blue;
        display: none;
    }

    .selected-radio .oi-circle-check {
        display: block;
    }

    .dot {
        height: 1.5rem;
        width: 1.5rem;
        background-color: #f3f3f3;
        border-radius: 50%;
        border: 2px solid $light-grey;
        display: inline-block;
    }

    .selected-radio .dot {
        display: none;

    }

    .large-radio:hover {
        color: $dark-blue;
    }

    .large-radio {
        cursor: pointer;
    }

    .selected-radio.large-radio:hover {
        color: $dark;
    }

    .selected-radio {
        background-color: white;
        height: 80px;

    }

    .not-selected-radio {
        background-color: #f3f3f3;

    }

    .super-small-screen, .paypal-super-small-screen, .paypal-other {
        display: none;
    }

    .paypal-subtext {
        font-size: 0.825rem;
        color: #111;
    }



    #paypal-button {
        width: 200px;
    }

    @media (min-width: 350px) {
        .super-small-screen {
            display: inline-block;
        }

        .paypal-super-small-screen {
            display: inline-block;
        }
    }

    @media (min-width: 450px) {
        .paypal-other {
            display: inline-block;
        }

        .paypal-super-small-screen {
            display: none;
        }

        #paypal-button {
            width: 300px;
        }

    }

    @media (min-width: 768px) {


        #paypal-button {
            width: 350px;
        }

    }

</style>
