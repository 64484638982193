export default {
  head: {
    meta: [
      { name:"keywords", content:"Spotify Premium in Pakistan, Spotify Premium in Bangladesh, Spotify Premium in Egypt, Spotify in Bangladesh, Spotify in Pakistan, Spotify in Egypt" },
      { name:"description", content:"Get a Spotify Premium account that works in in any country with Chillabitio." },
      { property:"og:url", content:"https://chillabitio.com" },
      { property:"og:title", content:"Spotify Premium, In Your Country" },
      { property:"og:description", content:"Get a Spotify Premium account that works in in any country with Chillabitio." },
      { property:"og:image", content:"https://chillabeat.io/fb-image.png" },
    ],
    link: [
      { rel: "icon", href: require("../images/favicon.png"), sizes:"32x32" },
      { rel: "icon", href: require("../images/favicon-192.png"), sizes:"192x192" }
    ],
  },
  created() {
    !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
    window.Beacon('init', 'da9bf5b3-4ef3-45d0-b068-035cd6f1f1c9')
  }
}