<template>
    <div>
        <div v-if="in_display_mode" class="row">
            <div class="col-md pr-2 my-auto">
                <span v-if="display_header" class="display-header">{{ display_header }}</span><span class="ml-1 set-field">{{ display_text }}</span>
            </div>
        </div>
        <div v-else class="ml-3 mr-3">
            <slot class="row" name="input"></slot>
        </div>
        <div v-if="!in_display_mode">
        <div class="row" v-if="show_error">
            <div class="col">
                <span class="error-text">{{ this.error_text }}</span>
            </div>
        </div>
        <div class="row" v-if="show_success">
            <div class="col">
                <slot name="success">
                    <span class="success-text">{{ this.success_text }}</span>
                </slot>
            </div>
        </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            show_error: {
                required: false,
                type: Boolean,
                default: false
            },
            show_success: {
                required: false,
                type: Boolean,
                default: false
            },
            in_display_mode: {
                required: true,
                type: Boolean,
            },
            display_text: {
                required: true,
            },
            display_header: {
                required: false,
                type: String,
                default: null
            },
            error_text: {
                required: false,
                type: String,
                default: ''
            },
            success_text: {
                required: false,
                type: String,
                default: ''
            }
        }
    }
</script>

<style scoped lang="scss">

.display-header {
    font-weight: bold;
    margin-right: 2rem;
}
</style>