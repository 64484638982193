<template>
    <div class="account-select">
        <base-card>
            <div class="">
                <div class="row m-0">
                    <template v-for="option, i in this.options">
                        <div @click="setSelected(i)" class="col-lg-6 col-md-12 large-radio" :class="option.selected ? 'selected-radio' : ''">
                            <div class="radio-icon">
                                <span class="oi oi-circle-check"></span>
                                <div class="dot"></div>
                            </div>
                            <div class="text-center pl-5 pr-5 ml-2 mr-2 pb-4">
                                <h6>{{ option.header }}</h6>
                                <p style="line-height: 1.2rem; font-size: 0.75rem">{{ option.description }}</p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </base-card>
    </div>
</template>

<script>
    import BaseCard from '../components/BaseCard.vue'

    export default {
        data() {
            return {
                options: [
                    {
                        header: "New Account",
                        description: "I want a brand new account",
                        selected: true
                    },
                    {
                        header: "Existing Account",
                        description: "I already have an account",
                        selected: false
                    },
                ]
            }
        },
        components: {
            BaseCard,
        },
        props: {
          is_existing: {
              required: true,
              type: Boolean
          }
        },
        methods: {
            setSelected(i) {
                if (i === 0) {
                    this.options[0].selected = true;
                    this.options[1].selected = false;
                    this.$emit('NEW_ACCOUNT_TYPE');
                }
                else {
                    this.options[0].selected = false;
                    this.options[1].selected = true;
                    this.$emit('EXISTING_ACCOUNT_TYPE');
                }
            }
        },
        created() {
            this.options[0].selected = !this.is_existing;
            this.options[1].selected = this.is_existing;
        }
    }
</script>


<style scoped lang="scss">
    @import '../assets/css/colors';

    span {
        font-size: 0.825rem;
    }
    .oi-circle-check {
        font-size: 1.5rem;
        color: $dark-blue;
        display: none;
    }

    .selected-radio .oi-circle-check {
        display: block;
    }

    .radio-icon {
        margin-top: 1rem;
    }

    .dot {
        height: 1.5rem;
        width: 1.5rem;
        background-color: white;
        border-radius: 50%;
        border: 2px solid $light-grey;
        display: inline-block;
        margin-bottom: -0.40rem;
    }

    .selected-radio .dot {
        display: none;

    }

    .large-radio:hover {
        color: $dark-blue;
    }

    .large-radio {
        cursor: pointer;
    }

    .selected-radio.large-radio:hover {
        color: $dark;
    }

    .selected-radio {
        background-color: $selected-radio;

    }

</style>
