<template>
    <div class="page">
        <slot name="navbar">
            <component-navbar></component-navbar>
        </slot>

        <div class="container content">
            <slot name="loading"></slot>
            <slot name="content"></slot>
        </div>

        <slot name="footer">
            <component-footer></component-footer>
        </slot>

    </div>
</template>

<script>
    import componentNavbar from './Navbar.vue';
    import componentFooter from './Footer.vue';

    export default {
        components: {
            componentNavbar,
            componentFooter
        }
    }
</script>

<style scoped lang="scss">
    @import 'assets/css/colors';

    .page {

        background-color: $page-background;

    }

</style>
