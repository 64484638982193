<template>
    <card-component class="less-padding faq-body">
        <div slot="content">
            <slot name="pre-items"></slot>
            <slot name="faq-items" :class="'faq-body'">
                <template v-for="item in items">
                    <h5>{{ item.header }}</h5>
                    <span>{{ item.body }}</span>
                </template>
            </slot>
            <slot name="post-items"></slot>
        </div>
    </card-component>
</template>

<script>
    import CardComponent from '../components/Card.vue'

    export class FAQItem {
        constructor(header, body) {
            this.header = header;
            this.body = body;
        }
    }

    export default {
        data() {
            return {
            }
        },
        props: {

            items: {
                required: false,
                default: function () { return [] }
            }
        },
        components: {
            'CardComponent' : CardComponent
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    .faq-body span {
        font-size: 0.82rem;
    }
    .faq-body ol {
        margin-top: 1rem;
        padding-left: 1rem;
    }

    .faq-body li {
        margin-bottom: 0.5rem;
        padding-left: 1rem;
    }

    .faq-body h5 {
        font-size: 0.99rem;
    }

    .faq-body h5:not(:first-child) {
        margin-top: 1.5rem;
    }
</style>