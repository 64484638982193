<template>
    <!-- nav -->
    <div class="top-bar">
        <div class="nav-section container">
            <div class="row">
                <div class="col-8 my-auto">
                    <a href="index.html">
                        <img  class="img-fluid" style="max-width: 230px; min-width:100px;"  src="../assets/images/logos/logo-white.svg">
                    </a>
                </div>

                <div class="text-right col-4 justify-content-center my-auto d-inline-block">
                    <h5 class="d-inline-block mb-0 mt-1">{{ this.description }}</h5><h5 :class="'oi-' + icon" class="ml-2 oi oi-lock-locked d-none d-md-inline-block"/>
                    <slot name="links">

                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            description: {
                required: false,
                default: "SECURE CHECKOUT"
            },
            icon: {
                required: false,
                default: 'lock-locked'
            }
        },
    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors.scss';

    .top-bar {
        background-color: $footer;
    }

    .top-bar h5 {
        color: white;
    }

    .nav-section {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .top-bar .lock {
        max-height: 1.2rem;
        margin-top: 5px;
    }

    h5 {
        font-size: 1rem;
    }

    .nav-link {
        cursor: pointer;
    }

</style>