<template>
    <div>
        <div>

            <div class="container p-4">
                <contact-us-navbar isLanding=false navbarClasses="navbar-light" logoImage="src/assets/images/logos/logo-black.svg"></contact-us-navbar>
            </div>

            <!-------------------------------------->
            <!-------------- PANEL 1 --------------->
            <!-------------------------------------->
            <div class="container-fluid section about-us-section">
                <div class="container">
                    <div class="row pt-4 pb-1">
                        <div class="col text-center mt-5 mb-5">
                            <h1 class="inverse-color ">About Us</h1>
                            <h4 class="inverse-color">Would you like to get to know us better?</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container section">
                <div class="col-12 text-center">
                    <h2>Our Story</h2>
                    <p>Founded in August of 2016, Chillabitio is a trusted community marketplace for people around the
                        world to sign up for a Spotify Premium subscription. We started Chillabitio because we were fed
                        up with the restrictions of the music industry. It isn’t fair that some people get the freedom to
                        choose while others do not based entirely on where they live. Thanks to our amazing community of
                        users, we’ve gotten a ton of support which has helped us build the company we have today help more
                        people discover the easiest way to access Spotify from anywhere in the world.</p>
                </div>
            </div>

            <hr>

            <div class="container section">
                <div class="col-12 text-center" style="margin-bottom: 4rem">
                    <h2>Reach the Chillabitio team</h2>
                </div>

                <div class="row mt-5">
                    <div class="col-xl-6 col-lg-12 text-center info-col mb-sm-5 mb-xl-0">
                        <p class="text-picture mb-0" style="color: #f64f64">5000+</p>
                        <h5>Users</h5>
                        <p>Growing every day thanks to an amazing community of music lovers around the globe!</p>
                    </div>
                    <div class="col-xl-6 col-lg-12 text-center info-col mt-sm-5 mt-xl-0">
                        <p class="text-picture mb-0" style="color: #7d61bb">20+</p>
                        <h5>Countries</h5>
                        <p>From Egypt to Russia, we're proud to be in more than 20 countries.</p>
                    </div>
                </div>
                <div class="row mt-xl-5">
                    <div class="col-xl-6 col-lg-12 text-center info-col mb-sm-5 mb-xl-0">
                        <p class="text-picture mb-0" style="color: #00c1cf">20,000+</p>
                        <h5>New playlists discovered</h5>
                        <p>People are discovering new music every day in every genre you can imagine.</p>
                    </div>
                    <div class="col-xl-6 col-lg-12 text-center info-col mt-sm-5 mt-xl-0">
                        <img class="img-fluid" style="margin-top: 1.5rem; max-height: 4rem"  src="./assets/images/infinity-sign.svg">
                        <h5>Minutes saved downloading music</h5>
                        <p>Alright maybe not infinity but it certainly feels like it when you're sitting there waiting for a song to download and sync!</p>
                    </div>
                </div>
            </div>

            <hr>

            <div class="container section text-center">
                <div class="col-12 text-center" style="margin-bottom: 4rem">
                    <h2>Our core values</h2>
                    <h3>These are some of the values we live by as a company. We work by them too! We're building a product we believe in -
                        knowing there is real value to be gained from helping people, wherever they are, and making a positive impact in their lives.</h3>
                </div>

                <div class="row mt-5">
                    <div class="col-lg-6 col-md-12">
                        <h2 class="d-inline">
                            <img class="img-fluid mr-4" style="max-width: 3rem"  src="./assets/images/emoticon-empathy.svg">
                            Empathy
                        </h2>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-4 mt-lg-0">
                        <h2 class="d-inline">
                            <img class="img-fluid mr-4" style="max-width: 3rem"  src="./assets/images/emoticon-service.svg">
                            Service
                        </h2>
                    </div>

                </div>
                <div class="row mt-lg-5">
                    <div class="col-lg-6 col-md-12 mt-4 mt-lg-0">
                        <h2 class="d-inline">
                            <img class="img-fluid mr-4" style="max-width: 3rem"  src="./assets/images/emoticon-shining.svg">
                            Quality
                        </h2>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-4 mt-lg-0">
                        <h2 class="d-inline">
                            <img class="img-fluid mr-4" style="max-width: 3rem"  src="./assets/images/emoticon-chat.svg">
                            Communication
                        </h2>
                    </div>
                </div>
                <div class="row mt-lg-5 ">
                    <div class="col-lg-6 col-md-12 mt-4 mt-lg-0">
                        <h2 class="d-inline">
                            <img class="img-fluid mr-4" style="max-width: 3rem"  src="./assets/images/emoticon-goofy.svg">
                            Playfulness
                        </h2>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-4 mt-lg-0">
                        <h2 class="d-inline">
                            <img class="img-fluid mr-24" style="max-width: 3rem"  src="./assets/images/emoticon-raise-hand.svg">
                            Solidarity
                        </h2>
                    </div>
                </div>
            </div>
            <contact-us-footer></contact-us-footer>
        </div>
    </div>

</template>

<script>
    import Navbar from './Navbar.vue'
    import Footer from './Footer.vue'
    import MixinHead from "./assets/js/mixin_head.js"

    export default {
        mixins: [MixinHead],
        components: {
            contactUsNavbar: Navbar,
            contactUsFooter: Footer
        }
    }
</script>

<style scoped>
    .about-us-section {
        padding-top: 2rem;
        background-size:cover;
        background-position-x: 40%;
        background-image: url('./assets/images/aboutus-background.png');
        padding-bottom: 60px;
    }

    h1.inverse-color, h2.inverse-color, h3.inverse-color, h4.inverse-color, span.inverse-color, p.inverse-color, col.inverse-color {
        color: white !important;
    }

    .info-col > img {
        margin-bottom: 1.4rem;
    }

    .info-col > span, .info-col > p {
        color: #73757a;
        font-weight: 400;
        margin-bottom: 0.5rem;
    }


    .info-col {
        margin-bottom: 5rem;
    }

    .text-picture {
        font-family: Heebo;
        font-size: 4rem;
        font-weight: 800 !important;
    }
</style>