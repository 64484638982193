<template>
  <div>
    <div>
      <!-- HEADER SECTION -->
      <div>
        <div>
          <div class="container-fluid section header-section pt-5">
            <div class="container-fluid pr-7">
              <landing-navbar></landing-navbar>
              <div class="row">
                <div id="header-section" class="col-lg-8 order-lg-1 my-auto" style="opacity:100;">
                  <div class="row">
                    <div class="col custom-align">
                      <h1 v-if="!is_existing_account_experiment" id="landing-header" class="size-1 mt-5 mb-4">
                        Get Spotify Premium<br>In <span id="current_country">{{ currentCountry }}</span>
                      </h1>
                      <h1 v-else id="landing-header" class="size-1 mt-5 mb-4">
                        Spotify Premium that works anywhere in the world
                      </h1>
                    </div>
                  </div>

                  <div id="custom-spacing1" class="row mb-5">
                    <div class="col-xl-9 col-md-12 col-sm-12 custom-align">
                      <h4 class="fade-in size-2 inverse-color">
                        <template v-if="!is_existing_account_experiment">No VPNs. No giftcards. Join a plan for just $9.99 a month.</template>
                        <!-- existing account ads experiment -->
                        <template v-else>Upgrade your existing account to Spotify Premium in just a few clicks.</template>
                      </h4>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div id="button1" class="col custom-align">
                      <landing-click-analytics v-if="!is_existing_account_experiment" :text="header.buttonText1 + ' (Button)'" :section="header.sectionName" destination="checkout">
                        <button type="button" class="btn btn-primary mb-4" @click.prevent>{{ header.buttonText1 }}</button>
                      </landing-click-analytics>
                      <!-- existing account ads experiment -->
                      <landing-click-analytics v-else :text="header.buttonText2 + ' (Button)'" :section="header.sectionName" destination="checkoutExisting">
                        <button type="button" class="btn btn-primary mb-4" @click.prevent>{{ header.buttonText2 }}</button>
                      </landing-click-analytics>
                      <div class="row">
                        <div class="col mb-4 custom-align" id="guarantee1">
                          <span class="oi oi-circle-check"></span>
                          <h6 class="d-inline">30-DAY MONEY-BACK GUARANTEE</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TOGGLE SECTION -->

      <div id="middle"></div>
      <div id="toggle-section">
        <div class="btn-group special" role="group">
          <landing-click-analytics class="custom-style" :text="'OUR PLAN ' + '(Button)'">
            <button
              :class="current_section === 1 ? 'btn btn-light active' : 'btn btn-light'"
              @click="current_section = 1"
            >
              <img class="custom-radioIcon" src="./assets/images/spotify-icon.svg">
              <div class="toggle-caption mt-3">
                <span class="text-abbr">Our</span>
                <span class="toggle-text">Plan</span>
              </div>
            </button>
          </landing-click-analytics>
          <landing-click-analytics class="custom-style" :text="'INSTALL THE APP ' + '(Button)'">
            <button
              :class="current_section === 2 ? 'btn btn-light active' : 'btn btn-light'"
              @click="current_section = 2"
            >
              <img class="custom-radioIcon" src="./assets/images/spotify-devices-icon.png">
              <div class="toggle-caption mt-3">
                <span class="text-abbr">Install</span>
                <span class="toggle-text">The App</span>
              </div>
            </button>
          </landing-click-analytics>
          <landing-click-analytics class="custom-style" :text="'OUR REVIEWS ' + '(Button)'">
            <button
              :class="current_section === 3 ? 'btn btn-light active' : 'btn btn-light'"
              @click="current_section = 3"
            >
              <img class="custom-radioIcon" src="./assets/images/facebook-icon.svg">
              <div class="toggle-caption mt-3">
                <span class="text-abbr">Our</span>
                <span class="toggle-text">Reviews</span>
              </div>
            </button>
          </landing-click-analytics>
        </div>
      </div>

      <!-- FEATURES SECTION -->
      <div v-if="current_section === 1">
        <div class="container-fluid section">
          <div class="row">
            <div class="col-xl-6 col-lg-4">
              <div class="row no-gutters">
                <div class="col-sm-12 custom-align">
                  <h3
                    class="mb-4"
                  >Chillabitio is a Canadian company offering Spotify Premium plans that will work anywhere in the world.</h3>
                  <div id="iphone-drake" class="col-md-10 col-sm-12 pl-0 d-lg-block d-none">
                    <img style="width:90%; min-width:15rem" src="./assets/images/iphone-drake.jpg">
                  </div>
                </div>
              </div>
            </div>

            <div id="card-padding" class="col-xl-6 col-lg-8 text-center">
              <div class="card custom-card text-white bg-dark d-inline-block" style>
                <div class="card-header">
                  <img class="brand d-flex" src="./assets/images/logos/logo-white.svg">
                  <div class="custom-divider"></div>
                </div>
                <div>
                  <div class="card-body text-left pt-0">
                    <div class="text-center">
                      <div class="card-text d-inline-block">
                        <h5 class="card-title mb-4 text-left">Your Subscription Includes:</h5>
                        <ul class="list-group list-group-flush custom-bullet text-left">
                          <li class="list-group-item">Spotify Premium on a North American plan</li>
                          <li class="list-group-item">Installation guides for any device</li>
                          <li class="list-group-item">Never need to use a VPN</li>
                          <li class="list-group-item">Simple and hassle free auto-monthly bill</li>
                        </ul>
                        <hr>
                        <h5
                          class="card-title mb-0 text-left"
                          style="max-width: 24rem"
                        >Choose to upgrade your existing account, or we can make a new one for you:</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <landing-click-analytics
                      :text="'Use Existing Account ' + '(Button)'"
                      :section="features.sectionName"
                      destination="checkoutExisting"
                    >
                      <button
                        type="button"
                        class="btn btn-primary custom-button d-flex mb-3"
                      >Use Existing Account</button>
                    </landing-click-analytics>
                    <landing-click-analytics
                      :text="'New Spotify Account ' + '(Button)'"
                      :section="features.sectionName"
                      destination="checkout"
                    >
                      <button
                        type="button"
                        id="new-account-button"
                        class="btn btn-secondary custom-button d-flex"
                      >New Spotify Account</button>
                    </landing-click-analytics>
                    <p id="proudly-canadian1">Proudly Canadian — 🇨🇦</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- INSTALL THE APP SECTION -->

      <div v-else-if="current_section === 2">
        <div class="container-fluid section">
          <div class="row no-gutters">
            <div id="install-section" class="col-xl-6 col-lg-7 custom-align">
              <div class="col-lg-12 col-md-12">
                <h3>
                  Our custom tutorials will show you exactly how to install Spotify
                  <span style="font-weight:100">— even if it isn’t available on your app store.</span>
                </h3>
              </div>
              <div class="col-xl-8 col-lg-12">
                <div class="row">
                  <div class="align-button">
                    <div class="col-xl-12 col-lg-10">
                      <landing-click-analytics v-if="!is_existing_account_experiment" :text="install.buttonText1 + ' (Button)'" :section="install.sectionName" destination="checkout">
                        <button class="btn btn-primary" @click.prevent>{{install.buttonText1}}</button>
                      </landing-click-analytics>
                      <!-- existing account ads experiment -->
                      <landing-click-analytics v-else :text="install.buttonText2 + ' (Button)'" :section="install.sectionName" destination="checkoutExisting">
                        <button class="btn btn-primary" @click.prevent>{{install.buttonText2}}</button>
                      </landing-click-analytics>
                    </div>
                    <div class="col-12 text-center">
                      <img
                        class="d-inline-block"
                        id="device-icons1"
                        src="./assets/images/app-available-icons.svg"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-5">
              <div class="col text-center">
                <landing-click-analytics
                  :text="'DEVICE SCREENSHOT' + ' (Image)'"
                  :section="install.sectionName"
                  destination="checkout"
                >
                  <img
                    class="device-screenshots d-inline-block"
                    src="./assets/images/device-screenshots.jpg"
                  >
                </landing-click-analytics>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- OUR REVIEWS SECTION -->
      <div v-else>
        <div class="container-fluid section">
          <div class="row no-gutters">
            <div id="review-section" class="col-xl-6 col-lg-6 custom-align">
              <div class="col-12">
                <h3>
                  We love our community, and it looks like they love us too
                  <span style="font-weight:100">— but you can be the judge of that.</span>
                </h3>
              </div>
              <div class="col-12 custom-align d-inline-block">
                <div class="row">
                  <div class="align-button">
                    <div class="col-xl-12 col-lg-10">
                      <landing-click-analytics
                        :text="reviews.buttonText + ' (Button)'"
                        :section="reviews.sectionName"
                        destination="reviews"
                        :newtab="true"
                      >
                        <button
                          id="fb-button"
                          class="btn btn-primary"
                          @click.prevent
                        >{{reviews.buttonText}}</button>
                      </landing-click-analytics>
                    </div>
                    <div id="proudly-canadian2" class="col-12 text-center">
                      <p class="d-inline-block">Proudly Canadian — 🇨🇦</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="col-lg-12 text-center">
                <landing-click-analytics
                  :text="'FACEBOOK REVIEWS IMAGE ' + '(Image)'"
                  :section="reviews.sectionName"
                  destination="reviews"
                  :newtab="true"
                >
                  <img
                    class="fb-reviews d-inline-block"
                    src="./assets/images/fb-reviews.jpg"
                    @click="goToPage('reviews', 'Facebook Reviews Image', 'reviews' )"
                  >
                </landing-click-analytics>
              </div>
            </div>
          </div>
        </div>
      </div>

      <landing-footer></landing-footer>
    </div>
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import Navbar from "./Navbar.vue";
import ClickAnalytics from "./analytics/Click.vue";
import { Fingerprint, getPageLocation } from "./assets/js/globals";
import { countries } from "./assets/js/data.js";
import MixinHead from "./assets/js/mixin_head.js"
import { urlParam } from "./assets/js/utilities";

export default {
  mixins: [MixinHead],
  data() {
    return {
      header: {
        buttonText1: "Join a Spotify plan",
        buttonText2: "Upgrade my existing account",
        sectionName: "Header"
      },
      toggle: {
        buttonText1: "Our Plan",
        buttonText2: "Install the App",
        buttonText3: "Our Reviews",
        sectionName: "toggle"
      },
      features: {
        buttonText: "Use My Existing Account",
        secondaryButtonText: "Start a New Spotify",
        sectionName: "Features",
        seenSection: false
      },
      install: {
        buttonText1: "Join a Spotify Plan",
        buttonText2: "Upgrade my existing account",
        seenSection: false
      },
      reviews: {
        buttonText: "See Facebook Reviews",
        seenSection: false
      },

      current_section: 1,

      countries: countries,
      defaultCountry: "Your Country",
      currentCountry: "Your Country",
      show: true,
      location: null
    };
  },
  computed: {
    is_existing_account_experiment() {
      if (
        urlParam('utm_source') === 'google',
        urlParam('utm_campaign') === 'high-intent',
        urlParam('utm_content') === 'existing'
      ) return true;
      else return false;
    },
    countryList() {
      let names = Object.keys(this.countries).map(
        key => this.countries[key].name
      );
      names = names.filter(ele => this.defaultCountry !== ele);

      if (
        this.defaultCountry.replace(/\s/g, "").toLowerCase() in this.countries
      ) {
        names.unshift(this.defaultCountry);
      }

      names = names.slice(0, 4);
      names.push("Your Country");
      return names;
    }
  },
  methods: {
    visibilityChanged(isVisible, entry, section) {
      if (entry["isIntersecting"] && !section.seenSection) {
        section.seenSection = true;
        let event = new this.$scrollEvent(section.sectionName);
        event.sendEvent();
      }
    },

    goToPage(destination, buttonText, sectionName) {
      let url = getPageLocation(destination);
      this.$goToPage(buttonText, url, sectionName);
    }
  },
  components: {
    landingFooter: Footer,
    landingNavbar: Navbar,
    landingClickAnalytics: ClickAnalytics
  },
  created() {
    let url =
      "https://api.ipstack.com/check?access_key=da3f35fa68c0f5060b145b58ce6dc5e7";
    this.$http
      .get(url)
      .then(response => response.json())
      .then(json => {
        let fingerprint = new Fingerprint();
        fingerprint.loadFingerprint();
        fingerprint.setCountryData(json);
        this.defaultCountry = json.country_name;
        fingerprint.serializerForUrlParam("checkout");

        let i = 0;
        let ele = $("#current_country");
        setInterval(() => {
          let index = i % this.countryList.length;

          ele.removeClass("in");
          ele.addClass("out");
          ele.css("opacity", "0");

          setTimeout(() => {
            i++;
            this.show = true;
            this.currentCountry = this.countryList[index];
            ele.removeClass("out");
            ele.addClass("in");
            ele.css("opacity", "1");
          }, 600);
        }, 3000);
      });
  }
};
</script>

<style scoped>
#current_country.in {
  transition: opacity 0.4s ease-in;
}

#current_country.out {
  transition: opacity 0.4s ease-out;
}

.header-link {
  color: #58f4ff !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.section {
  background-color: #181818 !important;
}

.header-section {
  color: white;
  background: linear-gradient(
      96deg,
      #181818 1%,
      rgba(24, 24, 24, 0.8) 25%,
      rgba(24, 24, 24, 0.04) 100%
    ),
    url("./assets/images/header-background2.jpg") #181818 no-repeat;
  background-size: cover;
}

.oi-circle-check {
  color: #55becd;
  font-size: 1rem;
}

#toggle-section {
  background-color: #181818;
}

.custom-radioIcon {
  height: 3rem;
}

.toggle-caption {
  display: block;
}

.toggle-text,
.text-abbr {
  font-size: 1.1rem;
  margin: 1rem 0 0 0;
}

#new-account-button {
  background-color: transparent;
}

.btn-light span {
  color: white;
}

.btn-group.special {
  display: flex;
}

#iphone-drake {
  margin-top: 6rem;
}

#proudly-canadian1 {
  padding-top: 0.8rem;
  text-align: center;
}

#proudly-canadian2 {
  padding-top: 0.8rem;
  color: white;
}

.info-col > img {
  margin-bottom: 1.4rem;
}

.info-col > span,
.info-col > p {
  color: #73757a;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

#card-padding {
  padding: 0;
}

.custom-card h5 {
  font-size: 1rem;
}

.custom-card {
  background-color: #2a2a2a !important;
  font-size: 1rem;
  margin-right: 1rem;
  padding: 0.2rem 3rem;
  height: 100%;
  max-width: 100%;
  min-width: 70%;
  margin-left: 2rem;
  border-radius: 10px;
}
.card-header {
  border: 0px;
  background-color: #2a2a2a !important;
}
.brand {
  margin: auto;
  padding: 0.5rem;
}
.custom-divider {
  height: 0.5rem;
  width: 4rem;
  background-color: #262626;
  border-radius: 30px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.card-body {
  margin: auto;
}
.custom-style {
  width: 100%;
}
.custom-button {
  margin: auto;
}

.custom-bullet .list-group-item {
  background-color: transparent;
  border: 0;
  font-weight: 100;
}

.custom-bullet li {
  display: block;
  padding-top: 0.2rem;
  padding-bottom: 0.4rem;
}

.custom-bullet li:before {
  content: url("./assets/images/white-tick.svg");
  float: left;
  margin-top: 2px;
  margin-right: 1.2rem;
}

#device-icons1 {
  height: 1.8rem;
  margin: auto;
}

#install-section div {
  padding-bottom: 1.2rem;
}

#review-section h3 {
  padding-bottom: 1.2rem;
}

#fb-button {
  margin-bottom: 0.5rem;
  background-color: #4267b2;
  border-color: #4267b2;
}

.fb-reviews {
  width: 26rem;
  cursor: pointer !important;
}

.device-screenshots {
  width: 26rem;
}

h3 {
  font-size: 1.4rem;
}

@media (max-width: 1360px) {
  .custom-card {
    padding: 0.2rem 0.5rem;
  }
}

@media (max-width: 991px) {
  .header-section {
    color: white;
    background: linear-gradient(
        0deg,
        #181818 20%,
        rgba(24, 24, 24, 0.8) 60%,
        rgba(24, 24, 24, 0.04) 100%
      ),
      url("./assets/images/header-background2.jpg") #181818 no-repeat;
    background-size: cover;
  }

  .custom-align {
    text-align: center;
  }

  .container-fluid h3 {
    text-align: center;
  }

  .align-button {
    text-align: center;
    width: 100%;
    padding-bottom: 0 !important;
  }

  .fb-reviews {
    margin-top: 2rem;
  }
}

@media (max-width: 602px) {
  .custom-card {
    padding: 0.2rem 1rem;
    min-width: 90%;
    margin-left: 1rem;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .text-abbr {
    display: none;
  }

  .custom-radioIcon {
    height: 2.5rem;
  }

  .btn-light {
    height: 8rem;
  }

  .container-fluid h3 {
    font-size: 1.2rem;
    text-align: center;
    padding: 0rem 1rem;
  }

  .device-screenshots {
    max-width: 80%;
    min-width: 16.5rem;
  }

  .fb-reviews {
    max-width: 80%;
    min-width: 16.5rem;
  }

  .custom-card {
    margin: 0rem 1rem !important;
    padding: 0.2rem 1rem;
    max-width: 95%;
  }
}

@media (max-width: 440px) {
  .custom-card {
    margin: 0rem 1rem;
  }

  .custom-card .list-group-item {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

@media (max-width: 420px) {
  .custom-button {
    padding: 0.6rem 1rem;
  }

  h1 {
    font-size: 2rem;
  }
}

@media (max-width: 375px) {
}

@media (max-width: 360px) {
  .container-fluid h3 {
    font-size: 1.1rem;
    text-align: center;
    padding: 0rem 0rem;
  }

  .custom-card {
    margin: 0rem 1rem;
    padding: 0.2rem 0.5rem;
    max-width: 90%;
  }

  #fb-button {
    padding: 0.6rem 1.3rem;
  }

  h1 {
    font-size: 1.5rem;
  }
}
</style>
