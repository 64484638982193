<template>
    <div>

        <div class="container p-4">
            <contact-us-navbar isLanding=false navbarClasses="navbar-light" logoImage="src/assets/images/logos/logo-black.svg"></contact-us-navbar>
        </div>

        <!-------------------------------------->
        <!-------------- PANEL 1 --------------->
        <!-------------------------------------->
        <div class="container-fluid section hiring-section">
            <div class="container">
                <div class="row pt-4 pb-1">
                    <div class="col text-center mt-5 mb-5">
                        <h1 class="inverse-color ">We're Hiring</h1>
                        <h4 class="inverse-color">Join our passionate team of music enthusiasts</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="container section">
            <div class="col-12 text-center" style="margin-bottom: 7rem">
                <h2>Join our mission</h2>
                <h4>We are on a mission to bring music to every country in the world</h4>
            </div>

            <div class="row mb-md-5 ">
                <div class="col-lg-4 text-center info-col ">
                    <img class="img-fluid" style="max-width: 15rem"  src="./assets/images/internet.svg">
                    <h5>Impact</h5>
                    <p class="ml-lg-1 mr-lg-1">We belive that everyone should have access to the music they want to listen to, regardless
                        of what country they live in.</p>
                </div>
                <div class="col-lg-4 text-center info-col">
                    <img class="img-fluid" style="max-width: 15rem"  src="./assets/images/pencil.svg">
                    <h5>Create</h5>
                    <p class="ml-lg-1 mr-lg-1">Use your talents to help build the best possible experiences for all our users</p>
                </div>
                <div class="col-lg-4 text-center info-col">
                    <img class="img-fluid" style="max-width: 15rem"  src="./assets/images/trophy.svg">
                    <h5>Challenge</h5>
                    <p class="ml-lg-1 mr-lg-1">We challenge ourselves everyday to do better today then we did yesterday</p>
                </div>
            </div>
        </div>

        <hr>

        <div class="container section text-center">
            <div class="col-12 text-center" style="margin-bottom: 2rem">
                <h2>Working and thriving</h2>
                <h4>We want people to thrive at Chillabitio; we believe you do your best work when you feel your best.
                    Here are a just a few benefits we’re proud to provide our team*</h4>
            </div>

            <br>
            <div class="row mt-5">
                <div class="col-xl-6 col-lg-12 text-center info-col mb-sm-5 mb-xl-0">
                    <h5>Learning & Development</h5>
                    <p class="ml-3 mr-3">Receive $500 annually (or equivalent) towards a personal development
                        opportunity of your choice, and $2000 annually (or equivalent) for professional development.</p>
                </div>
                <div class="col-xl-6 col-lg-12 text-center info-col mt-sm-5 mt-xl-0">
                    <h5>Health & Wellness</h5>
                    <p class="ml-3 mr-3">Full health coverage for you and your family (Chillabitio pays 100% of your
                        premiums), and a $150 monthly fitness and wellness reimbursement.</p>
                </div>
            </div>
            <div class="row mt-md-0 mt-lg-5">
                <div class="col-xl-6 col-lg-12 text-center info-col mb-sm-5 mb-xl-0">
                    <h5>Flexible Work Hours</h5>
                    <p class="ml-3 mr-3">Our flexible hours company policy outlines our provisions for employees who
                        want to change their working hours, days or weeks.</p>
                </div>
                <div class="col-xl-6 col-lg-12 text-center info-col mt-sm-5 mt-xl-0">
                    <h5>Parental Leave</h5>
                    <p class="ml-3 mr-3">A lot of us have families, of all shapes and sizes and types, and we welcome
                        each new addition with generous parental and new child bonding leave.</p>
                </div>
            </div>

            <div class="row">
                <div class="col text-center mt-5 mb-5">
                    <p style="font-size:0.2rem; color: #888">* These are the benefits for Canada-based employees at this time. We will have equivalent benefits at all our offices, wherever they are.</p>
                </div>
            </div>
        </div>

        <hr>

        <div class="container section text-center">
            <div class="col-12 text-center" style="margin-bottom: 4rem">
                <h2>Open Positions</h2>
            </div>

            <div class="col-12 text-center" style="margin-bottom: 4rem">
                <div class="row career-box p-4 mt-3">
                    <div class="col text-left"><span class="position">University Ambassador Bangladesh</span></div>
                    <div class="col text-right"><a href="mailto:support@chillabitio.com"><span class="apply">Apply</span></a></div>
                </div>
                <div class="row career-box p-4 mt-3">
                    <div class="col text-left"><span class="position">University Ambassador Pakistan</span></div>
                    <div class="col text-right"><a href="mailto:support@chillabitio.com"><span class="apply">Apply</span></a></div>
                </div>
                <div class="row career-box p-4 mt-3">
                    <div class="col text-left"><span class="position">University Ambassador Egypt</span></div>
                    <div class="col text-right"><a href="mailto:support@chillabitio.com"><span class="apply">Apply</span></a></div>
                </div>
                <div class="row career-box p-4 mt-3">
                    <div class="col text-left"><span class="position">University Ambassador Thailand</span></div>
                    <div class="col text-right"><a href="mailto:support@chillabitio.com"><span class="apply">Apply</span></a></div>
                </div>
                <div class="row career-box p-4 mt-3">
                    <div class="col text-left"><span class="position">University Ambassador India</span></div>
                    <div class="col text-right"><a href="mailto:support@chillabitio.com"><span class="apply">Apply</span></a></div>
                </div>
            </div>
        </div>

        <contact-us-footer></contact-us-footer>
    </div>
</template>

<script>
    import Navbar from './Navbar.vue'
    import Footer from './Footer.vue'
    import MixinHead from "./assets/js/mixin_head.js"

    export default {
      mixins: [MixinHead],
        components: {
            contactUsNavbar: Navbar,
            contactUsFooter: Footer
        }
    }
</script>

<style scoped>

    .hiring-section {
        padding-top: 2rem;
        background-size:cover;
        background-position-x: 40%;
        background-image: url('./assets/images/hiring-background.png');
        padding-bottom: 60px;
    }

    h1.inverse-color, h2.inverse-color, h3.inverse-color, h4.inverse-color, span.inverse-color, p.inverse-color, col.inverse-color {
        color: white !important;
    }

    .info-col > img {
        margin-bottom: 1.4rem;
    }

    .info-col > span, .info-col > p {
        color: #73757a;
        font-weight: 400;
        margin-bottom: 0.5rem;
    }


    .info-col {
        margin-bottom: 5rem;
    }

    .career-box {
        border: 1px solid #333;
        border-radius: 2px;
    }

    .position {
        color: #73757a;
    }

    .apply {
        color: #00c1cf;
    }

</style>