import Cookies from './cookie'
import {isDev, Fingerprint} from './globals'
import {urlParam} from "./utilities";

(function() {
    (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
        ;r.type="text/javascript";r.async=true
        ;r.src="https://d24n15hnbwhuhn.cloudfront.net/libs/amplitude-3.7.0-min.gz.js"
        ;r.onload=function(){if(e.amplitude.runQueuedFunctions){
            e.amplitude.runQueuedFunctions()}else{
            console.log("[Amplitude] Error: could not load SDK")}}
        ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
        ;function s(e,t){e.prototype[t]=function(){
            this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
            var o=function(){this._q=[];return this}
            ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
            ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
                ;return this}
            ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
            ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
            ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","logEventWithTimestamp","logEventWithGroups","setSessionId"]
            ;function v(e){function t(t){e[t]=function(){
                e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
                for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
                e=(!e||e.length===0?"$default_instance":e).toLowerCase()
                ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
            ;e.amplitude=n}
    )(window,document);
})()

function _amplitudeOnPageLoad(instance, ...args) {

    let versions = [
        '0 - error',
        '1 - initial',
        '2 - trustpilot reviews',
        '3 - trustpilot reviews (AB Test)',
        '4 - checkout changes (AB Test)',
        '5 - initial (AB test)',
        '6 - new checkout',
        '7 - paypal (AB test)',
        '8 - paypal (Control)',
        '9 - mobile (AB test)',
        '10 - mobile (Control)',
        '11 - existing accounts (AB test)',
        '12 - existing accounts (Control)',
        '13 - phone number (AB test)',
        '14 - phone number (Control)',
        '15 - new landing page test (Old Version)',
        '16 - new landing page test (New Version)',
        '17 - new landing page'
    ];

    let experiments = [versions[17]];

    // convert amplitude ID to number
    let user_id = instance.options.deviceId;
    let fingerprint = new Fingerprint();
    fingerprint.amplitude = instance.options.deviceId;
    let sum = user_id.split("").reduce(function(sum, ele) { return sum + ele.charCodeAt(0); }, 0);

    // get the correct experiment for that number
    let index = sum % experiments.length;

    let experiment = experiments[index];

    let version = document.head.querySelector("[name='version']")
    if (version === null) {
        $('meta[name=version]').remove();
        $('head').append('<meta name="version" content="' + experiment + '">');
    }

    setInitialUserProperties();
    let event = new LoadPageEvent();
    event.sendEvent();
}

function _getPageName() {
    let path = window.location.href;
    // remove html extension and #
    path = path.replace('.html', '');
    path = path.toLowerCase();
    path = path.replace(/\/$/g, '')
    // remove query string
    path = path.split('?')[0];
    // get everything after the last slash
    let parts = path.split('\/')
    path = parts[parts.length-1]

    // remove everything after the hastag
    path = path.split('#')[0];

    // remove .'s
    path = path.replace('.io', '')
    parts = path.split('.')
    path = parts[parts.length-1]

    if (path === '' || path === 'chillabitio' || path === 'index') {
        path = 'landing';
    }
    console.log(path);

    return path;
}

function _getReferer() {
    let referer = Cookies.get('_referer') ? Cookies.get('_referer')  : null;
    if (referer)
        referer = document.referrer;
    return referer ? referer : null;
}

function _getVersion() {
    let version = document.head.querySelector("[name='version']");
    return version ? version.content : null;
}

function _getUTMCampaign() {
    let param = urlParam('utm_campaign')
    if (param != null)
        return param;
    return Cookies.get('_utm_campaign') ? Cookies.get('_utm_campaign')  : null;
}

function _getUTMContent() {
    let param = urlParam('utm_content')
    if (param != null)
        return param;
    return Cookies.get('_utm_content') ? Cookies.get('_utm_content')  : null;
}

function _getQueryString() {
    let arr = document.location.href.split('?');
    if (arr.length === 2) {
        return arr.pop();
    }
    return null;
}

function _getUTMSource() {
    let param = urlParam('utm_source')
    if (param != null)
        return param;
    return Cookies.get('_utm_source') ? Cookies.get('_utm_source')  : null;
}

function _getGlobalEventProperties() {
    return {
        'PAGE' : _getPageName(),
        'VERSION' : _getVersion(),
        'QUERY-STRING' : _getQueryString(),
        'UTM-CAMPAIGN' : _getUTMCampaign(),
        'UTM-CONTENT' : _getUTMContent(),
        'UTM-SOURCE' : _getUTMSource()
    }
}

class UserProperty {
    constructor(set_once, value) {
        this.set_once = set_once;
        this.value = value;
    }
}


class UserProperties {

    constructor() {
        let version = _getVersion();
        let page = _getPageName();
        let utm_campaign = _getUTMCampaign();
        let utm_content = _getUTMContent();
        let utm_source = _getUTMSource();
        let referer = _getReferer();

        this.properties = {
            first_seen_page: new UserProperty(true, page),
            first_seen_version: new UserProperty(true, version),
            first_seen_utm_campaign: new UserProperty(true, utm_campaign),
            first_seen_utm_content: new UserProperty(true, utm_content),
            first_seen_utm_source: new UserProperty(true, utm_source),
            first_link_referer: new UserProperty(true, referer),
            page: new UserProperty(false, page),
            version: new UserProperty(false, version),
            utm_campaign: new UserProperty(false, utm_campaign),
            utm_content: new UserProperty(false, utm_content),
            utm_source: new UserProperty(false, utm_source),
            link_referer: new UserProperty(false, referer),
            query_string: new UserProperty(false, _getQueryString()),
        };

        if (version !== undefined && version !== null) {
            let version_string = 'Seen-Version-' + version.split(' ')[0];
            this.properties[version_string] = new UserProperty(false, true);
        }

    }
    sendUserProperties(identity) {
        for (let key in this.properties) {

            let name = key.replace(/_/g, '-').toUpperCase();
            let value = this.properties[key].value;

            if (this.properties[key].set_once) {
                identity.setOnce(name, value);
            }
            else {
                identity.set(name, value);
            }
        }
    };
}

export const getDeviceID = function getDeviceID() {
    return amplitude.options.deviceId;
}

class _Event {
    constructor() {
        this.properties = {};
    }

    sendEvent() {
        let properties = Object.assign({}, this.properties, _getGlobalEventProperties());
        amplitude.getInstance().logEvent(this.name, properties);
    }
}

export const goToPage = function goToPage(buttonText, destination, section) {
    console.log("in go to page")
    console.log(destination)
    let event = new ClickEvent(buttonText, destination, section);
    event.sendEvent();

    //TODO: Is there a more elegant way of doing this?
    setTimeout(() => window.location.href = destination, 500)
};



export const ClickEvent = class ClickEvent extends _Event {
    constructor(text, destination, section) {
        super();
        this.name = "click";
        this.properties = {
            'ITEM-DESCRIPTION': text,
            'LINK-DESTINATION': destination,
            'SECTION': section
        };
    }
};

class LoadPageEvent extends _Event {
    constructor() {
        super();
        this.name = "load-page";
    }
}

export const ScrollToEvent = class ScrollToEvent extends _Event {
    constructor(section) {
        super();
        this.name = "scroll-to";
        this.properties = {
            'SECTION_NAME' : section
        };
    }
}

export class SubscriptionErrorEvent extends _Event {
    constructor(error) {
        super();
        this.name = "subscription-error";
        this.properties = {
            'ERROR-TEXT' : error
        };
    }
}

export const LeadErrorEvent = class LeadErrorEvent extends _Event {
    constructor(error) {
        super();
        this.name = "lead-error";
        this.properties = {
            'ERROR-TEXT': error
        };
    }
}


// Initializes amplitude SDK
function _initializeAmplitude(callbacks) {
    var test_key = "f19d864d9dc715bc614a5c6b19f903fb";
    var prod_key = "1d540bfa79db237798f14cc92f2565e7";
    var config = {
        batchEvents: false,
        includeReferrer: true,
        includeUtm: true
    };
    amplitude.getInstance().init(isDev ? test_key : prod_key, null, config, callbacks);
}

// Sets the user properties
function setInitialUserProperties() {
    let identity = new amplitude.Identify();
    let user = new UserProperties();
    user.sendUserProperties(identity);
    amplitude.identify(identity);
}

export function StartAnalytics(...args) {
    let callback = (instance) => {
        _amplitudeOnPageLoad(instance, args);
    };
    _initializeAmplitude(callback);
}

// Set user id
export function IdentifyUser(email) {
    _identifyUser(email);
}

function _identifyUser(email) {
    amplitude.getInstance().setUserId(email.toLowerCase());
}


