<template>
    <click-analytics :text="'SEE GUIDE' + ' (Button)'" :section="this.title" :destination="this.get_tutorial_url" :newtab="true">
        <component-card class="no-padding">
            <div slot="content">
                <div class="d-flex justify-content-between align-self-center --responsive">
                    <div class="spotify-img">
                        <span style="vertical-align:middle; height: 100%; display: inline-block;" class="helper"></span>
                        <img style="vertical-align: middle; height: 3.5rem;" src="/src/assets/images/spotify-green.svg"/>
                    </div>

                    <div style="padding: 1rem" class="align-self-center">
                        <h5>{{ this.title }}</h5>
                        <div class="description d-none d-md-block">{{ this.subtext }}</div>
                    </div>
                    <div class="align-self-center">
                        <div class="see-guide">SEE GUIDE</div>
                    </div>
                </div>
            </div>
        </component-card>
    </click-analytics>
</template>
<script>
    import ComponentCard from '../components/Card.vue'
    import ClickAnalytics from '../analytics/Click.vue'
    import isMobile from '../assets/js/is_mobile'

    export default {
        data() {
            return {
                title: "Install the official Spotify app",
                subtext: "Head to our installation guide where we show you how to get Spotify on any of your devices",
                get_android_url: 'android_tutorial',
                get_iphone_url: 'iphone_tutorial',
                get_desktop_url: 'blog',
            }
        },
        computed: {
            get_tutorial_url() {
                if (isMobile.Android()) {
                    return this.get_android_url
                }
                else if (isMobile.iOS()) {
                    return this.get_iphone_url
                }
                return this.get_desktop_url
            }
        },
        components: {
            ComponentCard,
            ClickAnalytics,
        },
        methods: {},
        created() {},
        mounted() {},

    }
</script>

<style scoped lang="scss">
    @import '../assets/css/colors';

    .see-guide {
        width: 5rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        white-space: -moz-pre-wrap; /* Firefox */
        white-space: -o-pre-wrap; /* Opera */
        white-space: pre-wrap; /* Chrome */
        word-wrap: break-word; /* IE */
        color: #00bf5c;
        font-weight: bold;
        font-size: 0.85rem;
    }

    .spotify-img {
        background-color: black;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        white-space: nowrap;

    }

    h5 {
        font-size: 0.9rem;
        margin-bottom: 2px;
    }

    .description {
        color: #9b9b9b;
        font-size: 0.8rem;
        line-height: 1.2rem;
        margin-top: 0.2rem;
        height: fit-content;
    }

    @media (max-width: 425px) {
        .--responsive {
            flex-direction: column;
            text-align: center;
        }
        .spotify-img{
            padding: 1rem 0.5rem;
        }
        .see-guide {
            padding-bottom: 1rem;
        }
    }

</style>
