<template>
    <button style="outline: none !important" @click.native="submitted()" :disabled="disabled"  class="position-relative" :class="this.classes">
        <div class="position-static">
            <div :class="is_submitting ? 'invisible no-height' : ''">
                {{ button_text }}
            </div>
            <loading-spinner :class="this.spinner_classes" v-if="is_submitting" size="20px" ringColor="#fff" spinnerColor="#ccc"></loading-spinner>
        </div>
    </button>
</template>

<script>
    import LoadingSpinner from '../../components/Spinner.vue'

    export default {
        components: {
            LoadingSpinner
        },
        methods: {
            submitted() {
                if (this.is_submitting) {
                    return;
                }

                if (!this.disabled) {
                    console.log("in submit");
                    this.$emit("submissionWasClicked", {})
                }

            }
        },
        props: {
            button_text : {
                required: true,
                type: String
            },
            is_submitting : {
                required: true,
                type: Boolean
            },
            classes : {
                required: false,
                default: "btn-success w-100"
            },
            spinner_classes : {
                required: false,
                default: "mt-1"
            },
            disabled : {
                required: false,
                default: false,
                type: Boolean,
            }
        },
    }
</script>

<style scoped scoped lang="scss">
    @import '../../assets/css/colors';

    .no-height {
        height: 0;
        overflow: hidden;
    }


</style>
