<template>
    <div class="faq">
        <base-card>
            <div class="small-padding">
                <template v-if="isExisting">
                    <h6>What happens after I sign up?</h6>
                    <p>Once you sign up, we will ask for your account login credentials. Your account will be upgraded within 24 hours.
                </p>

                </template>
                <template v-else>
                    <h6>How do I get access to my account?</h6>
                    <p>
                    Once you sign up, you'll receive a username and password for your new account. This account is yours to keep!
                </p>
                </template>
                <h6>Can I cancel at any time?</h6>
                <p>You can cancel your account at any time by emailing <a href="mailto:support@chillabitio.com">support@chillabitio.com</a> or using our live chat.</p>
                <h6>You guys are pretty new, why should I trust you?</h6>
                <p>We're music lovers just like you! Great music shouldn't be limited by borders.
                    . We also offer a <b>full refund</b> within 30 days.</p>
            </div>
        </base-card>
    </div>
</template>

<script>
    import BaseCard from '../components/BaseCard.vue'

    export default {
        data() {
            return  {}
        },
        props: {
            isExisting: {required: false, default: false},
        },
        components: {
            BaseCard,
        },
        methods: {
        },
    }

</script>

<style scoped lang="scss">

    @import '../assets/css/colors';

    .faq {
        font-size: 0.825rem;
    }

    .faq h6 {
        font-size: 0.825rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }



</style>
